<template>
  <div class ="folder">
    <div class="flex-center" style="height: 80px;" @click="open">
      <img src="../../assets/images/folder-system.png" alt="" class="img-forder" v-if="folder.type === 'system'">
      <img src="../../assets/images/folder-user.png" alt="" class="img-forder" v-else>
    </div>
    <div class="flex-row-center" style="height:50px;border-top: 1px solid #f5f5f5;">
      <div class="f-grow flex-column" style="height: 100%;justify-content: center;padding-left: 10px;overflow: hidden">
        <strong class="single-line-ellipsis">{{ folder.name }}</strong>
        <span class="single-line-ellipsis" style="font-size: 11px;color: #0092FF;">{{ folder.label }}</span>
      </div>
      <el-tooltip :content="$t('rename')" v-if="folder.type !== 'system'">
        <i class="el-icon-edit color-blue mr10" @click="openEditDialog"></i>
      </el-tooltip>
      <el-tooltip :content="$t('remove')" v-if="folder.type !== 'system'">
        <i class="el-icon-delete color-blue mr10" @click="deleteMaterialType"></i>
      </el-tooltip>
      <!-- <el-popover placement="top" trigger="hover">
        <div class="flex-column-center">
          <el-button type="text" style="padding: 5px;margin: 0;" @click="openEditDialog">{{$t('rename')}}</el-button>
          <el-button type="text" style="padding: 5px;margin: 0;" @click="deleteMaterialType">{{$t('remove')}}</el-button>
        </div>
        <div slot="reference" style="padding: 10px;">...</div>
      </el-popover> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    folder: Object
  },
  methods: {
    open() {
      this.$emit('open-folder', this.folder);
    },
    openEditDialog(){
      this.$emit('edit-folder', this.folder);
    },
    deleteMaterialType(){
      this.$emit('delete-folder', this.folder);
    },
  }
};
</script>
<style scoped>
.folder{
  width: 180px;
  height: 130px;
  border: 1px solid #f5f5f5;
  background-color: white;
  border-radius: 5px;
  margin: 0 10px 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.folder:hover{
  box-shadow: 0 0 10px #ccc
}
.img-forder{
  width: 54px;
  height: 45px;
}

</style>
