<template>
  <div class="login">
    <lang-select class="set-language" />
    <div class="login-main">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
        <el-row>
          <el-col :span="8">
            <h3 class="title"
              :style="loginForm.loginType !== 'username' ? 'color:gray;':'color:black;display: inline-block;border-bottom: 3px solid #3985F4;'"
              @click="changeLoginType('username')">
              {{ $t('login.accountLogin') }}
            </h3>
          </el-col>
          <!-- <el-col :span="8">
            <h3 class="title"
              :style="loginForm.loginType !== 'email'  ? 'color:gray':'color:black;display: inline-block;border-bottom: 3px solid #3985F4;'"
              @click="changeLoginType('email')">
              {{ $t('login.emailLogin') }}
            </h3>
          </el-col> -->
          <el-col :span="8">
            <h3 class="title"
              :style="loginForm.loginType !== 'phone' ? 'color:gray;':'color:black;display: inline-block;border-bottom: 3px solid #3985F4;'"
              @click="changeLoginType('phone')">
              {{ $t('login.mobileLogin') }}
            </h3>
          </el-col>
        </el-row>
        <div v-if="loginForm.loginType === 'username'">
          <el-form-item prop="username" label-width="0">
            <el-input
              :name="usernameInputName"
              :autocomplete="autocompleteOff"
              style="border-radius:2px;width: 100%"
              v-model="loginForm.username"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.username')"
            >
              <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon"/>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              :name="passwordInputName"
              :autocomplete="autocompleteOff"
              v-model="loginForm.password"
              type="password"
              auto-complete="off"
              show-password
              :placeholder="$t('login.password')"
              @keyup.enter.native="handleLogin"
            >
              <svg-icon
                slot="prefix"
                icon-class="password"
                class="el-input__icon input-icon"
              />
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaEnabled">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              :placeholder="$t('login.code')"
              style="width: 63%"
              @keyup.enter.native="handleLogin"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
            <div class="login-code">
              <img :src="codeUrl" @click="getCode" class="login-code-img"/>
            </div>
          </el-form-item>
          <!-- <el-checkbox v-model="loginForm.rM" style="margin: 0px 0px 25px 0px">
            {{$t('login.rememberMe')}}
          </el-checkbox> -->
        </div>
        <div v-else-if="loginForm.loginType === 'email'">
          <el-form-item prop="email" label-width="0">
            <el-input
              style="border-radius:2px;width: 100%"
              v-model="loginForm.email"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.email')"
            >
              <svg-icon slot="prefix" icon-class="email" class="el-input__icon input-icon"/>
            </el-input>
          </el-form-item>
          <el-form-item prop="imgCode">
            <el-input
              v-model="loginForm.imgCode"
              auto-complete="off"
              :placeholder="$t('login.imageVerificationCode')"
              style="width: 63%;float:left"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
            <div class="register-code" style="cursor : pointer;float:left;margin-left: 10px">
              <img :src="codeUrl" @click="getCode" style="height: 38px;"/>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              :placeholder="$t('login.verificationCode')"
              style="width: 63%;"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
            <el-button :loading="emailLoading" style="margin-left: 10px" class="btn round" @click="sendEmail()"
                       v-if="!isDisabled">{{ content }}
            </el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ content }}</el-button>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item prop="phone" label-width="0">
            <el-input
              style="border-radius:2px;width: 100%"
              v-model="loginForm.phone"
              type="text"
              auto-complete="off"
              :placeholder="$t('login.mobile')"
            >
              <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon"/>
            </el-input>
          </el-form-item>
          <el-form-item prop="imgCode">
            <el-input
              v-model="loginForm.imgCode"
              auto-complete="off"
              :placeholder="$t('login.imageVerificationCode')"
              style="width: 63%;float:left"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
            <div class="register-code" style="cursor : pointer;float:left;margin-left: 10px">
              <img :src="codeUrl" @click="getCode" style="height: 38px;"/>
            </div>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="loginForm.code"
              auto-complete="off"
              :placeholder="$t('login.verificationCode')"
              style="width: 63%;"
            >
              <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              />
            </el-input>
            <el-button :loading="phoneLoading" style="margin-left: 10px" class="btn round" @click="sendPhone()"
                       v-if="!isDisabled">{{ phoneContent }}
            </el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ phoneContent }}</el-button>
          </el-form-item>
        </div>

        <el-form-item prop="agreed">
          <div style="display: flex; align-items: center; flex-wrap: wrap;">
            <input type="checkbox" v-model="loginForm.agreed" />
            <span style="margin-left: 8px;">
              {{$t('agreed.readAndAgree')}}
              <el-link type="primary" :underline="false" style="vertical-align: middle;" href="/service" target="_blank">{{$t('agreed.userAgreement')}}</el-link>
              {{$t('agreed.and')}}
              <el-link type="primary" :underline="false" style="vertical-align: middle;" href="/privacy" target="_blank">{{$t('agreed.privacyPolicy')}}</el-link>
            </span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
            round
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin"
          >
            <span v-if="!loading">{{ $t('login.logIn') }}</span>
            <span v-else>{{ $t('login.logIng') }}</span>
          </el-button>
        </el-form-item>

        <!-- <div v-if="register" style="text-align: center">
          <span style="color: #0087FC;cursor: pointer" @click="quickRegister">{{ $t('login.quickSignUp') }}</span>
        </div> -->
        <div class="flex-row-center f-between">
          <router-link v-if="loginForm.loginType === 'username'" class="link-type" :to="'/forgotPassword'">{{ $t('forget.forgotPassword') }}</router-link>
          <div v-else></div>
          <div v-if="register" style="text-align: center">{{ $t('login.noAccount') }}
            <span style="color: #0087FC;cursor: pointer" @click="gotoRegister">{{ $t('login.signUp') }}</span>
          </div>
        </div>
      </el-form>
    </div>
    <div class="bk" style="float:right;"></div>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
  </div>
</template>

<script>
  import LangSelect from '@/components/LangSelect'
  import {getCodeImg} from "@/api/login";
  import Cookies from "js-cookie";
  import {decrypt, encrypt} from "@/utils/jsencrypt";
  import {sendEmilCode, sendMyPhone} from "@/api/base/resource";
  import { createFreeAccount } from '../api/system/user';

  export default {
    name: "Login",
    components: { LangSelect },
    data() {
      return {
        isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
        content: this.$t('login.getEmailCode'),
        phoneContent: this.$t('login.getSMSCode'),
        codeUrl: "",
        sendSmsForm: {
          imgCode: "",
          uuid: ""
        },
        loginForm: {
          loginType: "username",
          email: '',
          username: "",
          password: "",
          rM: true,
          agreed:false,
          code: '',
          uuid: "",
        },
        loginRules: {
          agreed: [
            {
              validator: (rule, value, callback) => {
                if (!value) {
                  callback(new Error(this.$t('agreed.prompt')));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }
          ],
          email: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipEmail')},
            {
              pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: this.$t('login.tipCorrectEmail'),
              trigger: ['blur', 'change']
            },
          ],
          phone: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipPhone')},
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: this.$t('login.tipCorrectPhone'),
              trigger: ['blur', 'change']
            }
          ],
          username: [{required: true, trigger: ['blur', 'change'], message: this.$t('login.tipAccount')}],
          password: [{required: true, trigger: ['blur', 'change'], message: this.$t('login.tipPassword')}],
          code: [{required: true, trigger: ['blur', 'change'], message: this.$t('login.tipCode')}],
          imgCode: [{required: true, trigger: ['blur', 'change'], message: this.$t('login.tipCode')}],
        },
        loading: false,
        emailLoading: false,
        phoneLoading: false,
        // 验证码开关
        captchaEnabled: true,
        // 注册开关
        register: true,
        redirect: undefined,
        usernameInputName: 'usernameInput',
        passwordInputName: 'passwordInput',
        autocompleteOff: 'new-password'
      };
    },
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect;
        },
        immediate: true,
      },
       //切换登录类型时，清空必填项提示
       'loginForm.loginType': function(newType) {
        // 更新输入字段名称以避免浏览器自动填充
        this.usernameInputName = newType === 'username' ? 'usernameInput' : `usernameInput-${newType}`;
        this.passwordInputName = newType === 'username' ? 'passwordInput' : `passwordInput-${newType}`;
        this.$nextTick(() => {
          this.$refs.loginForm.clearValidate();
        });
      }
    },
    created() {
       // 判断是否为手机浏览器
      if (this.isMobile()) {
        // 如果是手机浏览器，跳转到跨域地址
        this.redirectToMobileSite();
      }else{
        this.getCode();
        this.getCookie();
      }
    },
    methods: {
      isMobile() {
        const userAgent = navigator.userAgent;
        // 判断是否包含手机浏览器的标志字符串
        return /iPhone|iPad|iPod|Android/i.test(userAgent);
      },
      redirectToMobileSite() {
        // 这里可以替换为你的跨域网址
        window.location.href = process.env.VUE_APP_PHONE_ADDRESS;  // 替换为目标跨域地址
      },
      //快速注册
      quickRegister(){
        createFreeAccount().then(res => {
          this.$confirm(this.$t('quickSignUp.rememberCredentials')
                        +this.$t('quickSignUp.accountLabel')+res.data.username
                        +this.$t('quickSignUp.passwordLabel')+res.data.password,
            this.$t('quickSignUp.registrationSuccess'), {
            confirmButtonText: this.$t('quickSignUp.autoFill'),
            cancelButtonText: this.$t('cancel'),
            type: 'success',
            dangerouslyUseHTMLString: true // 启用 HTML 渲染
          }).then(() => {
           this.loginForm.loginType = 'username'
           this.loginForm.username = res.data.username
           this.loginForm.password = res.data.password
          })
        })
      },
      // 发送邮箱验证码
      sendEmail() {
        if (this.loginForm.email === '') {
          this.$refs.loginForm.validateField('email')
          return
        } else {
          if (!/^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.loginForm.email)) {
            this.$refs.loginForm.validateField('email')
            return
          } else if (this.loginForm.imgCode == null) {
            this.$refs.loginForm.validateField('imgCode')
            return
          }
        }
        let vm = this
        vm.content = ''
        this.emailLoading = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendEmilCode({
          email: vm.loginForm.email,
          uuid: vm.loginForm.uuid,
          code: vm.loginForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.emailLoading = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.content = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.content = this.$t('login.getEmailCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.emailLoading = false
          vm.content = this.$t('login.getEmailCode')
          this.getCode()
        })
      },
      // 发送手机验证码
      sendPhone() {
        if (this.loginForm.phone === '') {
          this.$refs.loginForm.validateField('phone')
          return
        }
        if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.loginForm.phone)) {
          this.$refs.loginForm.validateField('phone')
          return
        }
        if (this.loginForm.imgCode == null) {
          this.$refs.loginForm.validateField('imgCode')
          return
        }
        let vm = this
        vm.phoneContent = ''
        this.phoneLoading = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendMyPhone({
          phone: vm.loginForm.phone,
          uuid: vm.loginForm.uuid,
          code: vm.loginForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.phoneLoading = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.phoneContent = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.phoneContent = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.phoneContent = this.$t('login.getSMSCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.phoneLoading = false
          vm.phoneContent = this.$t('login.getSMSCode')
          this.getCode()
        })
      },
      changeLoginType(type) {
        this.loginForm.loginType = type
      },
      getCode() {
        getCodeImg().then((res) => {
          this.captchaEnabled =
            res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled;
          if (this.captchaEnabled) {
            this.codeUrl = "data:image/gif;base64," + res.data.img;
            this.loginForm.uuid = res.data.uuid;
          }
        });
      },
      getCookie() {
        const username = Cookies.get("username");
        const password = Cookies.get("password");
        const rM = Cookies.get("rM");
        this.loginForm = {
          loginType: "username",
          username: username === undefined ? this.loginForm.username : username,
          password: password === undefined ? this.loginForm.password : decrypt(password),
          rM: rM === undefined ? false : Boolean(rM),
        };
      },
      gotoRegister() {
        this.$router.push("/register");
      },
      handleLogin() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            if (this.loginForm.rM) {
              Cookies.set("username", this.loginForm.username, {expires: 30});
              Cookies.set("password", encrypt(this.loginForm.password), {expires: 30});
              Cookies.set("rM", this.loginForm.rM, {expires: 30});
            } else {
              Cookies.remove("username");
              Cookies.remove("password");
              Cookies.remove("rM");
            }
            let type;
            if (this.loginForm.loginType === "username") type = "Login"
            else if (this.loginForm.loginType === "email") type = "EmailLogin"
            else if (this.loginForm.loginType === "phone") type = "smsLogin"
            this.$store.dispatch(type, this.loginForm)
              .then(() => {
                this.$router.push({path: this.redirect || "/"}).catch(() => {
                });
              })
              .catch(() => {
                this.loading = false;
                if (this.captchaEnabled) {
                  this.getCode();
                }
              });
          }
        });
      },
    },
  };
</script>

<style rel="stylesheet/scss" lang="scss">
  .bk {
    background-image: url("../assets/images/loginBackground.png");
    //background-size: 100% 100%;
    background-size: cover; /* 覆盖整个元素，可能裁剪部分图片 */
    background-position: center; /* 图片在元素中央显示 */
    width: 60%;
    height: 100vh; /* 100% 屏幕高度 */
  }

  .login {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #F7F8FA;
    // background-size: cover;
  }

  .login-main {
    width: 50%;
    //float: left;
    background-color: #F7F8FA;
  }

  .set-language{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    left: 35px;
    font-size: 18px;
  }

  .title {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: #1D2129;
    line-height: 48px;
    margin: 0 20px 30px auto;
  }

  .login-form {
    //border-radius: 6px;
    width: 100%;
    padding: 0 10%;

    .el-input {
      height: 38px;

      input {
        height: 38px;
      }
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code-img {
    height: 38px;
  }
</style>
