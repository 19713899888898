var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          staticClass: "form-top",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c("div", { staticClass: "goods-title" }, [
            _vm._v(_vm._s(_vm.$t("mall.baseInfo"))),
          ]),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("mall.productCategory"),
                prop: "goodsType",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.goodsType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "goodsType", $$v)
                    },
                    expression: "form.goodsType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "material" } }, [
                    _vm._v(_vm._s(_vm.$t("mall.material"))),
                  ]),
                  _c("el-radio", { attrs: { label: "account" } }, [
                    _vm._v(_vm._s(_vm.$t("mall.account"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.goodsType === "account"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mall.accountType"),
                        prop: "goodsTypeV2",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.goodsTypeV2,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "goodsTypeV2", $$v)
                            },
                            expression: "form.goodsTypeV2",
                          },
                        },
                        _vm._l(_vm.dict.type.social_platform, function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.value, attrs: { label: dict.value } },
                            [_vm._v(" " + _vm._s(dict.label) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mall.productName"),
                        prop: "title",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("tip.input") },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mall.businessDirection"),
                        prop: "businessType",
                      },
                    },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "show-all-levels": false,
                          props: {
                            value: "name",
                            label: "name",
                            emitPath: false,
                          },
                          options: _vm.industryCategoryOptions,
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.businessType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessType", $$v)
                          },
                          expression: "form.businessType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("sort"), prop: "goodsNo" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-row-center f-between w-100",
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(_vm.$t("sort")))]),
                                  _c(
                                    "span",
                                    { staticClass: "goods-tip ml10" },
                                    [_vm._v(_vm._s(_vm.$t("mall.sortOrder")))]
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: _vm.$t("tip.input"),
                        },
                        model: {
                          value: _vm.form.goodsNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goodsNo", $$v)
                          },
                          expression: "form.goodsNo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.goodsType === "material"
            ? _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("mall.productPrice"),
                            prop: "price",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: _vm.$t("tip.input"),
                            },
                            model: {
                              value: _vm.form.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price",
                            },
                          }),
                          _c("span", { staticClass: "goods-tip" }, [
                            _vm._v(_vm._s(_vm.$t("mall.salePrice"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("mall.strikethroughPrice"),
                            prop: "originalPrice",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: _vm.$t("tip.input"),
                            },
                            model: {
                              value: _vm.form.originalPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "originalPrice", $$v)
                              },
                              expression: "form.originalPrice",
                            },
                          }),
                          _c("span", { staticClass: "goods-tip" }, [
                            _vm._v(
                              _vm._s(_vm.$t("mall.strikethroughPriceTip"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("mall.stock"),
                            prop: "quantity",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              max: 999,
                              placeholder: _vm.$t("tip.input"),
                            },
                            on: { input: _vm.quantityInput },
                            model: {
                              value: _vm.form.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "quantity", $$v)
                              },
                              expression: "form.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: { prop: "description" },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex-row-center f-between w-100" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("mall.productDescription"))),
                          ]),
                          _c("span", { staticClass: "goods-tip ml10" }, [
                            _vm._v(_vm._s(_vm.$t("mall.pointInfo"))),
                          ]),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 3,
                  placeholder: _vm.$t("tip.input"),
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mall.productCover"), prop: "coverUrl" } },
            [
              _c("imageUpload", {
                attrs: { limit: 10 },
                model: {
                  value: _vm.form.coverUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "coverUrl", $$v)
                  },
                  expression: "form.coverUrl",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "goods-title" }, [
            _vm._v(_vm._s(_vm.$t("mall.productInfo"))),
          ]),
          _vm.form.goodsType === "account"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mall.account"),
                        prop: "accountList",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row-center mb5" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                icon: "iconfont icon-wenanmoban",
                                size: "mini",
                              },
                              on: { click: _vm.downTemplate },
                            },
                            [_vm._v("下载模板")]
                          ),
                          _vm.form.goodsType === "account"
                            ? _c(
                                "el-upload",
                                {
                                  staticStyle: {
                                    width: "80px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    "show-file-list": false,
                                    action:
                                      "https://jsonplaceholder.typicode.com/posts/",
                                    accept: ".xlsx",
                                    "http-request": function (params) {
                                      return _vm.uploadFile(params)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        icon: "iconfont icon-a-dagoubeifen21",
                                        size: "mini",
                                      },
                                    },
                                    [_vm._v("导入Excel")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.form.accountList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex-row-center mb5" },
                          [
                            _c(
                              "el-descriptions",
                              { attrs: { column: 4, border: "" } },
                              [
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "账号" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: _vm.isDisabled(item) },
                                      model: {
                                        value: item.email,
                                        callback: function ($$v) {
                                          _vm.$set(item, "email", $$v)
                                        },
                                        expression: "item.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "密码" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "password",
                                        disabled: _vm.isDisabled(item),
                                      },
                                      model: {
                                        value: item.password,
                                        callback: function ($$v) {
                                          _vm.$set(item, "password", $$v)
                                        },
                                        expression: "item.password",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "年限" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled: _vm.isDisabled(item),
                                        },
                                        model: {
                                          value: item.year,
                                          callback: function ($$v) {
                                            _vm.$set(item, "year", $$v)
                                          },
                                          expression: "item.year",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.goods_year,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "粉丝" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled: _vm.isDisabled(item),
                                        },
                                        model: {
                                          value: item.fans,
                                          callback: function ($$v) {
                                            _vm.$set(item, "fans", $$v)
                                          },
                                          expression: "item.fans",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.goods_fans,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "地域" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled: _vm.isDisabled(item),
                                        },
                                        model: {
                                          value: item.country,
                                          callback: function ($$v) {
                                            _vm.$set(item, "country", $$v)
                                          },
                                          expression: "item.country",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.goods_country,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "语言" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          disabled: _vm.isDisabled(item),
                                        },
                                        model: {
                                          value: item.language,
                                          callback: function ($$v) {
                                            _vm.$set(item, "language", $$v)
                                          },
                                          expression: "item.language",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dict.type.language,
                                        function (dict) {
                                          return _c("el-option", {
                                            key: dict.value,
                                            attrs: {
                                              label: dict.label,
                                              value: dict.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "￥价格" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        disabled: _vm.isDisabled(item),
                                      },
                                      model: {
                                        value: item.money,
                                        callback: function ($$v) {
                                          _vm.$set(item, "money", $$v)
                                        },
                                        expression: "item.money",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-descriptions-item",
                                  { attrs: { label: "是否卖出" } },
                                  [
                                    _c("dict-tag", {
                                      attrs: {
                                        options:
                                          _vm.dict.type.goods_sell_status,
                                        value: item.sellStatus || 0,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.isDisabled(item)
                              ? _c("i", {
                                  staticClass:
                                    "iconfont icon-delete color-red f-shrink ml10",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAccount(index, item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.goodsType === "material"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("mall.material"),
                        prop: "fileList",
                      },
                    },
                    [
                      _c("videoUpload", {
                        attrs: { limit: 1 },
                        model: {
                          value: _vm.form.fileList,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "fileList", $$v)
                          },
                          expression: "form.fileList",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mall.productDetails"), prop: "detail" } },
            [
              _c("editor", {
                attrs: { "min-height": 192 },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
              _c("span", { staticClass: "goods-tip" }, [
                _vm._v(_vm._s(_vm.$t("mall.productDescriptionTip"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("mall.onSale"), prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v(_vm._s(_vm.$t("mall.saleNow"))),
                  ]),
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v(_vm._s(_vm.$t("mall.saleLater"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-center" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.$t("back"))),
          ]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }