var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "register" }, [
    _c(
      "div",
      { staticClass: "register-main" },
      [
        _c("h3", { staticClass: "flex-center title mt20" }, [
          _vm._v(_vm._s(_vm.$t("register.register"))),
        ]),
        _c(
          "el-form",
          {
            ref: "registerForm",
            staticClass: "register-form",
            attrs: {
              model: _vm.registerForm,
              rules: _vm.registerRules,
              "label-position": "right",
              "label-width": "140px",
            },
          },
          [
            _vm.registerForm.registerType === "email"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("newRegister.account"),
                      prop: "account",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        placeholder: _vm.$t("newRegister.account"),
                      },
                      model: {
                        value: _vm.registerForm.account,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "account", $$v)
                        },
                        expression: "registerForm.account",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              {
                attrs: { label: _vm.$t("register.password"), prop: "password" },
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      type: "password",
                      "show-password": "",
                      autocomplete: "off",
                      placeholder: _vm.$t("register.passwordHint"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleRegister($event)
                      },
                    },
                    model: {
                      value: _vm.registerForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "password", $$v)
                      },
                      expression: "registerForm.password",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "el-input__icon input-icon",
                      attrs: { slot: "prefix", "icon-class": "password" },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("newRegister.againPassword"),
                  prop: "againPassword",
                },
              },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      type: "password",
                      "show-password": "",
                      autocomplete: "off",
                      placeholder: _vm.$t("newRegister.againPassword"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleRegister($event)
                      },
                    },
                    model: {
                      value: _vm.registerForm.againPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "againPassword", $$v)
                      },
                      expression: "registerForm.againPassword",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "el-input__icon input-icon",
                      attrs: { slot: "prefix", "icon-class": "password" },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.registerForm.registerType === "email"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("newRegister.emailOrPhone"),
                      prop: "email",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        placeholder: _vm.$t("newRegister.emailOrPhone"),
                      },
                      model: {
                        value: _vm.registerForm.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "email", $$v)
                        },
                        expression: "registerForm.email",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("login.mobile"), prop: "phone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: "text",
                          autocomplete: "off",
                          placeholder: _vm.$t("login.mobile"),
                        },
                        model: {
                          value: _vm.registerForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.registerForm, "phone", $$v)
                          },
                          expression: "registerForm.phone",
                        },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "el-input__icon input-icon",
                          attrs: { slot: "prefix", "icon-class": "phone" },
                          slot: "prefix",
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("register.imageCaptcha"),
                  prop: "imgCode",
                },
              },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "60%" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: _vm.$t("login.imageVerificationCode"),
                    },
                    model: {
                      value: _vm.registerForm.imgCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "imgCode", $$v)
                      },
                      expression: "registerForm.imgCode",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "el-input__icon input-icon",
                      attrs: { slot: "prefix", "icon-class": "validCode" },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "register-code",
                    staticStyle: { cursor: "pointer" },
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "38px" },
                      attrs: { src: _vm.codeUrl },
                      on: { click: _vm.getCode },
                    }),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("newRegister.verificationCode"),
                  prop: "code",
                },
              },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "63%" },
                    attrs: {
                      autocomplete: "off",
                      placeholder: _vm.$t("newRegister.verificationCode"),
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleRegister($event)
                      },
                    },
                    model: {
                      value: _vm.registerForm.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerForm, "code", $$v)
                      },
                      expression: "registerForm.code",
                    },
                  },
                  [
                    _c("svg-icon", {
                      staticClass: "el-input__icon input-icon",
                      attrs: { slot: "prefix", "icon-class": "validCode" },
                      slot: "prefix",
                    }),
                  ],
                  1
                ),
                !_vm.isDisabled
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn round",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { loading: _vm.codeLoding },
                        on: {
                          click: function ($event) {
                            return _vm.getCodeClick()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.content) + " ")]
                    )
                  : _vm._e(),
                _vm.isDisabled
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn round wait",
                        staticStyle: { "margin-left": "10px" },
                      },
                      [_vm._v(_vm._s(_vm.content))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "article" } },
              [
                _c("el-checkbox", {
                  model: {
                    value: _vm.registerForm.article,
                    callback: function ($$v) {
                      _vm.$set(_vm.registerForm, "article", $$v)
                    },
                    expression: "registerForm.article",
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$t("agreed.readAndAgree")) + " "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#00afff" },
                    attrs: { href: "/service", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("agreed.userAgreement")))]
                ),
                _vm._v(" " + _vm._s(_vm.$t("agreed.and")) + " "),
                _c(
                  "a",
                  {
                    staticStyle: { color: "#00afff" },
                    attrs: { href: "/privacy", target: "_blank" },
                  },
                  [_vm._v(_vm._s(_vm.$t("agreed.privacyPolicy")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-center" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "60%" },
                    attrs: {
                      disabled: !_vm.registerForm.article,
                      loading: _vm.loading,
                      size: "medium",
                      type: "primary",
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleRegister($event)
                      },
                    },
                  },
                  [
                    !_vm.loading
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("register.submitRegister"))),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("register.registering"))),
                        ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-center mt10" },
              [
                _c(
                  "router-link",
                  { staticClass: "link-type", attrs: { to: "/login" } },
                  [_vm._v(_vm._s(_vm.$t("register.useExistingAccount")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }