var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("lang-select", { staticClass: "set-language" }),
      _c(
        "div",
        { staticClass: "login-main" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: { model: _vm.loginForm, rules: _vm.loginRules },
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        style:
                          _vm.loginForm.loginType !== "username"
                            ? "color:gray;"
                            : "color:black;display: inline-block;border-bottom: 3px solid #3985F4;",
                        on: {
                          click: function ($event) {
                            return _vm.changeLoginType("username")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("login.accountLogin")) + " ")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        style:
                          _vm.loginForm.loginType !== "phone"
                            ? "color:gray;"
                            : "color:black;display: inline-block;border-bottom: 3px solid #3985F4;",
                        on: {
                          click: function ($event) {
                            return _vm.changeLoginType("phone")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("login.mobileLogin")) + " ")]
                    ),
                  ]),
                ],
                1
              ),
              _vm.loginForm.loginType === "username"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "username", "label-width": "0" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: {
                                "border-radius": "2px",
                                width: "100%",
                              },
                              attrs: {
                                name: _vm.usernameInputName,
                                autocomplete: _vm.autocompleteOff,
                                type: "text",
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.username"),
                              },
                              model: {
                                value: _vm.loginForm.username,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "username", $$v)
                                },
                                expression: "loginForm.username",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: { slot: "prefix", "icon-class": "user" },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                name: _vm.passwordInputName,
                                autocomplete: _vm.autocompleteOff,
                                type: "password",
                                "auto-complete": "off",
                                "show-password": "",
                                placeholder: _vm.$t("login.password"),
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              },
                              model: {
                                value: _vm.loginForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "password", $$v)
                                },
                                expression: "loginForm.password",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "password",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.captchaEnabled
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "code" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticStyle: { width: "63%" },
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: _vm.$t("login.code"),
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.loginForm.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginForm, "code", $$v)
                                    },
                                    expression: "loginForm.code",
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    staticClass: "el-input__icon input-icon",
                                    attrs: {
                                      slot: "prefix",
                                      "icon-class": "validCode",
                                    },
                                    slot: "prefix",
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "login-code" }, [
                                _c("img", {
                                  staticClass: "login-code-img",
                                  attrs: { src: _vm.codeUrl },
                                  on: { click: _vm.getCode },
                                }),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.loginForm.loginType === "email"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "email", "label-width": "0" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: {
                                "border-radius": "2px",
                                width: "100%",
                              },
                              attrs: {
                                type: "text",
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.email"),
                              },
                              model: {
                                value: _vm.loginForm.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "email", $$v)
                                },
                                expression: "loginForm.email",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "email",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "imgCode" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "63%", float: "left" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t(
                                  "login.imageVerificationCode"
                                ),
                              },
                              model: {
                                value: _vm.loginForm.imgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "imgCode", $$v)
                                },
                                expression: "loginForm.imgCode",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "register-code",
                              staticStyle: {
                                cursor: "pointer",
                                float: "left",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "38px" },
                                attrs: { src: _vm.codeUrl },
                                on: { click: _vm.getCode },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "63%" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.verificationCode"),
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          !_vm.isDisabled
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn round",
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { loading: _vm.emailLoading },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendEmail()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.content) + " ")]
                              )
                            : _vm._e(),
                          _vm.isDisabled
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn round wait",
                                  staticStyle: { "margin-left": "10px" },
                                },
                                [_vm._v(_vm._s(_vm.content))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone", "label-width": "0" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: {
                                "border-radius": "2px",
                                width: "100%",
                              },
                              attrs: {
                                type: "text",
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.mobile"),
                              },
                              model: {
                                value: _vm.loginForm.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "phone", $$v)
                                },
                                expression: "loginForm.phone",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "phone",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "imgCode" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "63%", float: "left" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t(
                                  "login.imageVerificationCode"
                                ),
                              },
                              model: {
                                value: _vm.loginForm.imgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "imgCode", $$v)
                                },
                                expression: "loginForm.imgCode",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "register-code",
                              staticStyle: {
                                cursor: "pointer",
                                float: "left",
                                "margin-left": "10px",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { height: "38px" },
                                attrs: { src: _vm.codeUrl },
                                on: { click: _vm.getCode },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "63%" },
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.verificationCode"),
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "validCode",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          !_vm.isDisabled
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn round",
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { loading: _vm.phoneLoading },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendPhone()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.phoneContent) + " ")]
                              )
                            : _vm._e(),
                          _vm.isDisabled
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "btn round wait",
                                  staticStyle: { "margin-left": "10px" },
                                },
                                [_vm._v(_vm._s(_vm.phoneContent))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c("el-form-item", { attrs: { prop: "agreed" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "flex-wrap": "wrap",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loginForm.agreed,
                          expression: "loginForm.agreed",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.loginForm.agreed)
                          ? _vm._i(_vm.loginForm.agreed, null) > -1
                          : _vm.loginForm.agreed,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.loginForm.agreed,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.loginForm,
                                  "agreed",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.loginForm,
                                  "agreed",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.loginForm, "agreed", $$c)
                          }
                        },
                      },
                    }),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "8px" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("agreed.readAndAgree")) + " "
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: "/service",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("agreed.userAgreement")))]
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("agreed.and")) + " "),
                        _c(
                          "el-link",
                          {
                            staticStyle: { "vertical-align": "middle" },
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: "/privacy",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("agreed.privacyPolicy")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        round: "",
                        loading: _vm.loading,
                        size: "medium",
                        type: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [
                      !_vm.loading
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("login.logIn")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("login.logIng")))]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-row-center f-between" },
                [
                  _vm.loginForm.loginType === "username"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "link-type",
                          attrs: { to: "/forgotPassword" },
                        },
                        [_vm._v(_vm._s(_vm.$t("forget.forgotPassword")))]
                      )
                    : _c("div"),
                  _vm.register
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(_vm._s(_vm.$t("login.noAccount")) + " "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#0087FC",
                              cursor: "pointer",
                            },
                            on: { click: _vm.gotoRegister },
                          },
                          [_vm._v(_vm._s(_vm.$t("login.signUp")))]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "bk", staticStyle: { float: "right" } }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [_vm._v("Copyright © 2022-2025 Infonity AI ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }