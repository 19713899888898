var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "folder" }, [
    _c(
      "div",
      {
        staticClass: "flex-center",
        staticStyle: { height: "80px" },
        on: { click: _vm.open },
      },
      [
        _vm.folder.type === "system"
          ? _c("img", {
              staticClass: "img-forder",
              attrs: {
                src: require("../../assets/images/folder-system.png"),
                alt: "",
              },
            })
          : _c("img", {
              staticClass: "img-forder",
              attrs: {
                src: require("../../assets/images/folder-user.png"),
                alt: "",
              },
            }),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "flex-row-center",
        staticStyle: { height: "50px", "border-top": "1px solid #f5f5f5" },
      },
      [
        _c(
          "div",
          {
            staticClass: "f-grow flex-column",
            staticStyle: {
              height: "100%",
              "justify-content": "center",
              "padding-left": "10px",
              overflow: "hidden",
            },
          },
          [
            _c("strong", { staticClass: "single-line-ellipsis" }, [
              _vm._v(_vm._s(_vm.folder.name)),
            ]),
            _c(
              "span",
              {
                staticClass: "single-line-ellipsis",
                staticStyle: { "font-size": "11px", color: "#0092FF" },
              },
              [_vm._v(_vm._s(_vm.folder.label))]
            ),
          ]
        ),
        _vm.folder.type !== "system"
          ? _c("el-tooltip", { attrs: { content: _vm.$t("rename") } }, [
              _c("i", {
                staticClass: "el-icon-edit color-blue mr10",
                on: { click: _vm.openEditDialog },
              }),
            ])
          : _vm._e(),
        _vm.folder.type !== "system"
          ? _c("el-tooltip", { attrs: { content: _vm.$t("remove") } }, [
              _c("i", {
                staticClass: "el-icon-delete color-blue mr10",
                on: { click: _vm.deleteMaterialType },
              }),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }