<template>
  <div class="aiPage">
  <!-- AI视频 -->
  <div class="aiHeader">
    <div class="aiHeaderTitle">{{ $t('ai.headerTitle') }}</div>
    <i class="el-icon-s-tools" @click="isShowSettings = !isShowSettings"></i>
  </div>
  <div class="container" v-loading="recordLoading">
    <div class="chat-container">
      <div class="messages" v-if="messages.length">
        <ChatMessage
          v-for="(message, index) in messages"
          :key="index"
          :message="message"
          @submitScript="submitScript"
          @againVideo="againVideo"
          @submitVideo="submitVideo"
          @chooseVideo="chooseVideo"
          @submitAccount="submitAccount"
          @submitPost="submitPost"/>
        <div class="flex-column" v-if="loading" >
          <div style="text-align: center;">{{$t('ai.aiWaitMsg')}}
            <router-link class="link-type" :to="'/videoManager/videoManager'">{{$t('workbench.videoProduction')}}</router-link>
            {{$t('ai.otherTasksMsg')}}</div>
        </div>
      </div>
      <div v-else class="defaultPrompt">
        <img :src="makeVideoLogo">
        <div class="defaultTitle">{{ $t('ai.defaultTitle1') }}</div>
        <div class="defaultContent">
          {{ $t('ai.defaultTitle2') }}
        </div>
      </div>
      <div class="flexCenter">
        <ChatInput @playAudioLoca="playAudioLoca" @send="handleSend" @sendUrl="handleSendUrl" @setLoading="setRecordLoading" :disabled="disabled" />
      </div>
    </div>
    <div class="video-settings" v-if="isShowSettings">
      <div class="whiteBg">
        <el-form :model="settings" :rules="rules" ref="settingsForm" label-width="120px" label-position="left">
          <!-- Logo 上传 -->
          <el-form-item label="Logo">
            <!-- <imageUpload v-model="settings.logo" :limit="1" /> -->
            <div class="material-container"
                  v-if="settings.logo"
                  @mouseover="settings.hover = true"
                  @mouseleave="settings.hover = false"
                >
                  <img :src="settings.logo" alt="material" class="material-thumbnail"/>
                  <div v-if="settings.hover" class="overlay">
                    <div  @click="removeMaterial()">{{$t('basicInfo.remove')}}</div>
                  </div>
                </div>
                <!-- <div v-else  class="aspect-ratio-box flex-column" @click="chooseMaterial(3)">
                  <i class="el-icon-upload"></i>
                  <div style="margin-top: 5px;">
                    {{$t('basicInfo.materialImport')}}
                  </div>
                </div> -->
                <picUpload
                  v-else
                  @picSelectMaterial="picSelectMaterial"
                  @showLocaPic="showLocaPic"
                />
          </el-form-item>

          <!-- 背景颜色 -->
          <el-form-item :label="$t('aiSet.color')" prop="backgroundColor">
            <div class="color-picker-container" @click="showColorPicker">
              <el-color-picker ref="colorPicker" v-model="settings.backgroundColor" :predefine="predefinedColors" />
              <span class="ml-10">{{ settings.backgroundColor || '#ffffff' }}</span>
            </div>
          </el-form-item>

          <!-- 背景音乐音量 -->
          <el-form-item :label="$t('aiSet.musicVolume')" prop="backgroundMusicVolume">
            <div class="flex-row-center">
              <el-slider :step="0.1" v-model="settings.backgroundMusicVolume" :max="1" :min="0" show-input @input="changeVolume" style="width: 50%;"/>
              <el-tooltip :content="$t('editVideo.preview')">
                <!-- <el-button
                  icon="el-icon-video-play"
                  circle
                  @click.stop="playAudio"
                  style="margin-left: 10px"
                ></el-button> -->
                <div class="shiting" @click.stop="playAudio">
                  <i class="iconfont icon-shiting"></i>
                </div>
              </el-tooltip>
            </div>
          </el-form-item>

          <!-- 视频音色 -->
          <el-form-item :label="$t('aiSet.speechVoice')" prop="speechVoice">
            <span> {{ getVoiceLabel(settings.speechVoice) }}</span>
            <el-button type="text"  @click="openVoice" style="margin-left: 10px">{{ settings.speechVoice ? $t('change') : $t('select')}}</el-button>
          </el-form-item>

          <!-- 存储位置 -->
          <el-form-item :label="$t('aiSet.location')">
            <!-- <el-select v-model="settings.productTypeId" :placeholder="$t('aiSet.tipLocation')">
              <el-option v-for="item in productTypelist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select> -->
            <treeselect
              style="width: 300px;"
              v-model="settings.productTypeId"
              :options="productTypelist"
              :normalizer="normalizer"
              :clearable="false"
              :placeholder="$t('aiSet.tipLocation')">
              <template #value-label="{ node }">
                {{ node.raw.name }}
              </template>
            </treeselect>
          </el-form-item>

          <!-- 提交按钮 -->
          <!-- <el-form-item> -->
            <div class="formFooter">
              <el-button class="submitButton" :loading="setLoading" type="primary" @click="handleSubmit">
                <i class="iconfont icon-sousuotiaojianbaocun"></i>
                {{$t('aiSet.save')}}
              </el-button>
            </div>
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <div class="blackBg" @click="closeSettings"></div>
    </div>
    <el-drawer
      :title="$t('mixing.materia')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
    >
      <Material_library ref="materialLibrary" :skipInit="true" @selectionConfirmed="selectionConfirmed"></Material_library>
    </el-drawer>
    <el-drawer
      :destroy-on-close="true"
      :title="$t('aiSet.speechVoice')"
      size="65%"
      :visible.sync="voiceDrawer"
      :with-header="false"
      :before-close="handleCloseVoice"
    >
      <Voice
        ref="voiceRef"
        :voiceList="voiceList"
        :value="settings.speechVoice"
        :type="1"
        style="padding: 20px;"
      />
        <!-- @confirm="voiceSelected" -->
    </el-drawer>
  </div>
</div>
</template>

<script>
import ChatMessage from '../../components/ChatItem/ChatMessage.vue';
import ChatInput from '../../components/ChatItem/ChatInput.vue';
import Material_library from './material.vue';
import Voice from './Voice.vue';
import makeVideoLogo from '../../assets/images/makeVideoLogo.png'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { addAiSetting, editAiSetting, getAiSetting } from '../../api/ai/aiSetting';
import { listProductType } from '../../api/ai/productType';
import { chooseSocial, confirmScript, generatePost, getAiSatus, openAiSession, publishAi } from '../../api/ai/aiVideo';
import { parseTime } from '../../utils/common';
// import { listAccount } from '../../api/account/tiktok';
import { listAllAccount } from '../../api/account/tiktok';
import { settings } from 'nprogress';
import { getVoiceList } from '../../api/ai/ali';
import EventBus from '../../utils/EventBus';
import picUpload from '../../components/picUpload/index.vue';
export default {
  name: "Make_video",
  components: {
    ChatMessage,
    ChatInput,
    Material_library,
    Voice,
    Treeselect,
    picUpload
  },
  data() {
    return {
      makeVideoLogo,
      isShowSettings: false,
      activeTab:'ai',
      disabled:false,//是否禁用输入框
      loading:false,
      aiId:'',
      accountList:[],
      recordLoading: false,
      messages: [
      //   {
      //     type: 'text',
      //     content: '帮我生成一个视频，介绍马牌手电筒，30秒，中文，面向中国市场，特点是亮度高，充电快，省电。',
      //     time: parseTime(new Date()) ,
      //     user:'user'
      //   },
      //   {
      //     type: 'script',
      //     text: this.$t('ai.scriptSuccessGo'),
      //     titles: ["马牌手电筒：亮度高，照亮您的夜"],
      //     contents: [
      //       {title: "马牌手电筒，采用先进光源技术", description: "你好你好你好你好你好你好你好你好你好你好你好你好你好你好亮度高，轻松照亮您的夜行之路。,马牌手电筒，充电快，省电设计，让您在户外长时间使用，无需担心电量问题。,马牌手电筒，采用省电设计，不仅节能，更环保，是您夜间出行的理想选择。u啊给元素擦干篡改参与语言是度过v苏一股u收到vuSUV苏12u与速度速度v高速的v与速度也uu闪光灯vu三个vu所以"},
      //       {title: '你好你好你', description: '打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼打招呼'}
      //     ],
      //     completed: false,
      //     time: parseTime(new Date())
      //   },
      //   { type: 'video', text: 'Watch this video:', videoUrl: 'https://www.w3schools.com/html/mov_bbb.mp4', time: parseTime(new Date()) },
      //   { type: 'listSelector', text: '选择社交账号:', options: [
      //     {
      //     "id": "1839524081060913153",
      //     "topHeader": null,
      //     "profileRefId": null,
      //     "profileId": "1839494166072590338",
      //     "platform": "tiktok",
      //     "profileUrl": null,
      //     "userImage": "https://img.ayrshare.com/TfmERYvf39eUQzcKGEfiUfPhGFE3/social/tiktok-0yd4jXEM6V2QDOVY8iMgH.jpeg",
      //     "displayName": "jingz353",
      //     "tiktokVideoList": null,
      //     "tiktok": null,
      //     "avatar": null,
      //     "ayrshareSocialId": null,
      //     "userId": null,
      //     "languageId": 74,
      //     "country": "美国",
      //     "timeZoneId": 18,
      //     "status": 0,
      //     "source": null,
      //     "username": "jingz353",
      //     "createTime": "2024-09-27 12:34:52",
      //     "updateTime": "2024-09-27 16:40:31",
      //     "tokenExpiresTime": null,
      //     "bdLanguageCode": "en",
      //     "pageName": null,
      //     isSelected: false
      // },
      // {
      //     "id": "1839524742292938754",
      //     "topHeader": null,
      //     "profileRefId": null,
      //     "profileId": "1839494166072590338",
      //     "platform": "youtube",
      //     "profileUrl": null,
      //     "userImage": "https://img.ayrshare.com/TfmERYvf39eUQzcKGEfiUfPhGFE3/social/youtube-e3MkJ_UKMn9T30p4tRCZD.png",
      //     "displayName": "jing z",
      //     "tiktokVideoList": null,
      //     "tiktok": null,
      //     "avatar": null,
      //     "ayrshareSocialId": null,
      //     "userId": null,
      //     "languageId": 74,
      //     "country": "美国",
      //     "timeZoneId": 18,
      //     "status": 0,
      //     "source": null,
      //     "username": "jing z",
      //     "createTime": "2024-09-27 12:37:30",
      //     "updateTime": "2024-09-27 16:40:31",
      //     "tokenExpiresTime": null,
      //     "bdLanguageCode": "en",
      //     "pageName": null,
      //     isSelected: false
      // },
      // {
      //     "id": "1839555071607152642",
      //     "topHeader": null,
      //     "profileRefId": null,
      //     "profileId": "1839554914735988738",
      //     "platform": "tiktok",
      //     "profileUrl": null,
      //     "userImage": "https://img.ayrshare.com/xzk9EeBIDIOhmKGIsIsMOXDTyd32/social/tiktok-dunQRgHHGcEq0OCJGIZCj.jpeg",
      //     "displayName": "橙子",
      //     "tiktokVideoList": null,
      //     "tiktok": null,
      //     "avatar": null,
      //     "ayrshareSocialId": null,
      //     "userId": null,
      //     "languageId": 74,
      //     "country": "美国",
      //     "timeZoneId": 18,
      //     "status": 0,
      //     "source": null,
      //     "username": "zhangsan863",
      //     "createTime": "2024-09-27 14:38:01",
      //     "updateTime": "2024-09-27 14:38:01",
      //     "tokenExpiresTime": null,
      //     "bdLanguageCode": "en",
      //     "pageName": null,
      //     isSelected: false
      // }
      //   ], multiple: true, selected: [], completed: false, time: parseTime(new Date())
      //   },
      //   {
      //     type: 'post',
      //     text: this.$t('ai.contentSuccessGo'),
      //     titles: '1234rweffsdfs',
      //     contents: 'sdfsdfsdfsasfsdvsfdvsdfjvhbdfjvbdjfhvbjddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsfdfsdfsdf',
      //     completed: false,
      //     time: parseTime(new Date())
      //   }
      ],
      fileList: [],
      settings: {
        id:null,
        logo: null,
        logoWidth: null,
        logoHeight: null,
        backgroundColor: '#ffffff',
        backgroundMusicVolume: 0,
        speechVoice: '',
        productTypeId: 0,
        hover:false
      },
      voiceList: {},//音色列表
      currentAudio: null, // 新增：当前播放的音频实例
      productTypelist:[{
        id:0,
        name: this.$t('product.product'),
      }],//成片分组
      rules: {
        backgroundColor: [
          { required: true, message: this.$t('aiSet.tipColor'), trigger: 'change' }
        ],
        backgroundMusicVolume: [
          { required: true, message: this.$t('aiSet.tipVolume'), trigger: 'change' }
        ],
        speechVoice: [
          { required: true, message: this.$t('aiSet.tipVoice'), trigger: 'change' }
        ]
      },
      predefinedColors: ['#ffffff', '#000000', '#0000ff', '#ffff00', '#ff0000'],
      drawer: false,//素材选择的抽屉
      sureScript: '',//确认的文案
      videoUrls:[],//生成的视频列表
      videoUrl: '',
      timer: null, // 用于存储 setTimeout 的返回值
      isComponentActive: true, // 标志组件是否活跃
      voiceDrawer: false,//选择配音音色
      currentVoiceUrl: require('@/assets/ai-bgm.mp3'),//当前的音频url
      setLoading: false,//设置的提交
    };
  },
  created() {
    this.getSetting();
    this.getProductTypeList()
    this.getVoiceList()
    // EventBus.$on('search-media', (searchObj) => {
    //     this.getAccountList({platform: searchObj.platform}, 'search', searchObj.selectList && searchObj.selectList)
    // })
    // EventBus.$on('search-mediaAccount',(searchObj) => {
    //   console.log(searchObj);
    //   this.getAccountList({platform: searchObj.platform, searchValue: searchObj.searchValue}, 'search', searchObj.selectList && searchObj.selectList)
    // })
    // confirmScript({id:49})
    // .then(response => {
    //   console.log(response)
    // })
    // this.makeSuccess("https://www.w3schools.com/html/mov_bbb.mp4")

    this.aiId = this.$route.query.id || '';
    if(this.aiId){
      this.getDetail()
    }
  },
  beforeDestroy() {
    // 页面关闭或组件销毁时清除定时器
    this.isComponentActive = false; // 将组件状态设置为非活跃
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    setRecordLoading() {
      this.recordLoading = true
    },
    handleCloseVoice(done){
      this.$nextTick(() => {
        let voice = this.$refs.voiceRef.getSelect();
        this.settings.speechVoice = voice && voice.voice || '';
        done()
      });
    },
    openVoice(){
      this.voiceDrawer = true
    },
    voiceSelected(voice){
      this.voiceDrawer = false
      this.settings.speechVoice = voice.voice
    },
    // 获取音色列表
    getVoiceList(){
      getVoiceList().then(res => {
        this.voiceList = res.data
      })
    },
    getVoiceLabel(voice) {
      if(!voice) return ''
      // 通过遍历 voiceList 找到当前选中的 voice 对象
      for (const category in this.voiceList) {
        const selectedVoice = this.voiceList[category].find(it => it.voice === voice);
        if (selectedVoice) {
          // this.currentVoiceUrl = selectedVoice.voiceUrl
          return selectedVoice.name
        }
      }
     return ''
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel = node.productCount > 0 ? `  (${node.productCount})` : '';
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children
      };
    },
    picSelectMaterial() {
      this.chooseMaterial(3)
    },
     //选择素材：2-音频（背景音乐）、3-logo、4-封面、5-封底
     chooseMaterial(type){
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(type);
      });
    },
    showLocaPic(items) {
      this.selectionConfirmed(items)
    },
    selectionConfirmed(items) {
      if(items.length==1){
        this.settings.logo = items[0].url
        this.settings.logoWidth = items[0].width
        this.settings.logoHeight = items[0].height
      }
      console.log('选中的素材:', items)
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
    },
    removeMaterial(){
      this.settings.logo = ''
      this.settings.logoWidth = null
      this.settings.logoHeight = null
    },
    //ai历史详情：根据各状态拼接
    getDetail(){
      this.disabled = true
      this.loading = true
      getAiSatus(this.aiId).then(response => {
        this.loading = false
        const data = response.data ||{};
        if(data.status !=3) return
        if(data.status ==3 && data.scriptJson){
          if(data.voiceUrl){
            this.messages.push({
              type: 'audio',
              content: data.tips,
              url: data.voiceUrl,
              user:'user',
              time:data.createTime
            });
          } else {
            this.messages.push({
              type: 'text',
              content: data.tips,
              user:'user',
              time:data.createTime
            });
          }
          let scriptJson = {};
          try {
            scriptJson = JSON.parse(data.scriptJson);
          } catch (e) {
            console.error("fontFace is not a valid JSON string:", e);
          }
          if(scriptJson.list && scriptJson.list.length > 0){
            this.messages.push({ type: 'script',
              text: this.$t('ai.scriptSuccess'),
              // titles: [scriptJson.title],
              contents: scriptJson.list,
              completed: true,
            })
          }
        }
        if(data.videoUrls){
          this.videoUrls = JSON.parse(data.videoUrls)
          if(this.videoUrls.length > 0){
            this.videoUrls.forEach((url) => {
              this.messages.push({
                type: 'video',
                completed: true,
                text: this.$t('ai.videoSuccess'),
                videoUrl: url, // 使用当前遍历到的 video URL
              });
            });
          }
        }
        if(data.generateVideoStatus==0)return
        if(data.generateVideoStatus == 1){
          this.messages.push({
            type: 'text',
            content: this.$t('ai.videoing') ,
            time:data.updateTime
          });
          this.loading = true
          this.getAiSatus()
          return
        }
        if(data.generateVideoStatus == 3){
          this.messages.push({
            type: 'text',
            content: this.$t('ai.videoFailed') ,
            time:data.updateTime
          });
          return
        }
        if(data.generateVideoStatus == 2){
          if(data.chooseSocialStatus==0){
            if(data.videoUrl){
              this.messages = this.messages.filter(
                (message) => message.videoUrl !== data.videoUrl
              );
            }
            this.messages.push({ type: 'video',
              completed:false,
              text: this.$t('ai.videoSuccess'),
              videoUrl: data.videoUrl,
              time:data.updateTime
            })
            return
          }else{
            // this.messages.push({ type: 'video',
            //   completed:true,
            //   text: this.$t('ai.videoSuccess'),
            //   videoUrl: data.videoUrl,
            // })
            this.messages.push({
              type: 'listSelector',
              text: this.$t('ai.socialChoosed'),
              options: data.socialBindingList,
              multiple: true,
              selected: [],
              completed: true,
            })
            if(data.generatePostStatus!=1){
              this.messages.push({
                type: 'text',
                content: this.$t('ai.contentFailed') ,
                time:data.updateTime
              });
              return
            }else{
              if(data.publishSocialStatus==0){
                this.messages.push({
                  type: 'post',
                  text: this.$t('ai.contentSuccessGo'),
                  titles: [data.postTitle],
                  contents: [data.postContent],
                  completed: false,
                  time: data.updateTime
                })
                return
              }else {
                this.messages.push({
                  type: 'post',
                  text: this.$t('ai.contentSuccess'),
                  titles: [data.postTitle],
                  contents: [data.postContent],
                  completed: true,
                  time: data.updateTime
                })
                if(data.publishSocialStatus == 1){
                  this.messages.push({ type: 'text', content: this.$t('ai.publishing') ,time: data.updateTime });
                }else if(data.publishSocialStatus == 2){
                  this.messages.push({ type: 'text', content: this.$t('ai.publishSuccess') ,time: data.updateTime });
                }else  if(data.publishSocialStatus==3){
                  this.messages.push({ type: 'text', content: this.$t('ai.publishFailed') ,time: data.updateTime });
                }
              }
            }
          }
        }
        // if(data.generateVideoStatus == 2){
        //   this.loading = false
        //   this.makeSuccess(data.videoUrl)
        // }else if(data.generateVideoStatus == 3){
        //   this.loading = false
        //   this.messages.push({ type: 'text', content: this.$t('ai.videoFailed') ,time:parseTime(new Date())});
        // }else if(data.generateVideoStatus == 1){
        //   setTimeout(() => {
        //     this.getAiSatus();
        //   }, 3000); // 休息3秒
        // }
      }).catch (error => {
        this.loading = false
      });
    },
    playAudioLoca(url) {
      console.log(url);
      this.messages.push({ type: 'audio', url: url , content:'', user:'user',time:parseTime(new Date())});
    },
    handleSendUrl(url){
      // this.recordLoading = false
      // this.messages.push({ type: 'audio', url: url , content:'', user:'user',time:parseTime(new Date())});
      this.disabled = true
      this.loading = true
      openAiSession({
        voiceUrl:url
      }).then(response => {
        this.sendCallBack(1, response)
      }).catch (error => {
        this.messages.push({ type: 'text', content: this.$t('ai.scriptFailed') ,time:parseTime(new Date())});
        this.disabled = false
        this.loading = false
      })
    },
    handleSend(message) {
      this.messages.push({ type: 'text', content: message ,user:'user',time:parseTime(new Date())});
      this.disabled = true
      this.loading = true
      openAiSession({tips:message}).then(response => {
        this.sendCallBack(2, response)
      }).catch (error => {
        this.messages.push({ type: 'text', content: this.$t('ai.scriptFailed') ,time:parseTime(new Date())});
        this.disabled = false
        this.loading = false
      });
    },
    //type-1语音 2文本
    sendCallBack(type, response){
      this.loading = false
      const data = response.data ||{};
      this.aiId = data.id
      if(type == 1 && data.tips){
        this.messages.forEach(item => {
          if (item.type === 'audio') {
            item.content = data.tips;
          }
        });
      }
      //status：0接收到提示语（默认状态）1分析成功（素材、脚本生成成功）2分析失败（素材脚本生成失败）3确认脚本（脚本已确认，或已修改）
      if(data.status == 1 && data.scriptJson){
        let scriptJson = {};
        try {
          scriptJson = JSON.parse(data.scriptJson);
        } catch (e) {
          console.error("fontFace is not a valid JSON string:", e);
        }
        if(scriptJson.list && scriptJson.list.length > 0){
          // const titles = scriptJson.list.map(item => item.title); // 提取 title 到 titles 数组
          // const contents = scriptJson.list.map(item => item.content); // 提取 content 到 contents 数组
          this.messages.push({ type: 'script',
            text: this.$t('ai.scriptSuccessGo'),
            // titles: titles,
            contents: scriptJson.list,
            completed: false,
            time: parseTime(new Date())
          })
        }
      }else{
        this.messages.push({ type: 'text', content: this.$t('ai.scriptFailed') + "\n" + data.errorMessage,time:parseTime(new Date())});
        this.disabled = false
      }
    },
    //确认脚本
    submitScript(message){
      console.log('message',JSON.stringify(message))
      // 判断是否有空的 title 或 description
      const isTitleEmpty = message.contents.some(
        item => !item.title.trim()
      );
      if(isTitleEmpty){
        this.$modal.msgError(this.$t('ai.tipTitle'));
        return
      }
      const isDesEmpty = message.contents.some(
        item => !item.description.trim()
      );
      if(isDesEmpty){
        this.$modal.msgError(this.$t('ai.tipContent'));
        return
      }
      this.messages.forEach(item => {
        if (item.type === 'script') {
          item.completed = true;
        }
      });
      // this.$forceUpdate(); // 手动触发更新
      const script = {
        list: message.contents
      }
      this.sureScript = JSON.stringify(script)
      this.scriptConfirm()
    },
    //脚本确认、重新生成视频
    scriptConfirm(){
      this.loading = true
      confirmScript({
        id: this.aiId,
        scriptJson: this.sureScript
      }).then(response => {
        //generateVideoStatus(脚本确认后，开始修改这个状态)：0默认1排队中（生成任务队列排队中）2生成成功3生成失败
        const data = response.data ||{};
        if(data.generateVideoStatus==3){
          this.loading = false
          this.messages.push({ type: 'text', content: this.$t('ai.videoFailed') ,time:parseTime(new Date())});
        }else if(data.generateVideoStatus == 2){
          if(data.videoUrls){
            this.videoUrls = JSON.parse(data.videoUrls)
          }
          this.makeSuccess(data.videoUrl)
        }else {
          this.messages.push({ type: 'text', content: this.$t('ai.videoing') ,time:parseTime(new Date())});
          this.getAiSatus()
        }
      }).catch (error => {
        this.getAiSatus()
      });
    },
    //AI视频制作成功。
    makeSuccess(url){
      this.loading = false
      this.messages.push({ type: 'video',
        completed:false,
        text: this.$t('ai.videoSuccess'),
        videoUrl: url,
        time: parseTime(new Date())})
    },
    getAiSatus(){
      getAiSatus(this.aiId).then(response => {
        const data = response.data ||{};
        if(data.generateVideoStatus == 2){
          this.loading = false
          if(data.videoUrls){
            this.videoUrls = JSON.parse(data.videoUrls)
          }
          this.makeSuccess(data.videoUrl)
        }else if(data.generateVideoStatus == 3){
          this.loading = false
          this.messages.push({ type: 'text', content: this.$t('ai.videoFailed') ,time:parseTime(new Date())});
        }else if(data.generateVideoStatus == 1 && this.isComponentActive){
          this.timer = setTimeout(() => {
            this.getAiSatus();
          }, 3000); // 休息3秒
        }
      }).catch (error => {
        this.loading = false
        this.messages.push({ type: 'text', content: this.$t('ai.videoFailed') ,time:parseTime(new Date())});
      });
    },
    //视频不满意，重新生成
    againVideo(){
      this.messages.forEach(item => {
        if (item.type === 'video') {
          item.completed = true;
        }
      });
      this.scriptConfirm()
    },
    //确认视频
    submitVideo(){
      this.messages.forEach(item => {
        if (item.type === 'video') {
          item.completed = true;
        }
      });
      if(this.videoUrls && this.videoUrls.length > 1){
        this.messages.push({ type: 'listVideo',
          text: this.$t('ai.videoChoose'),
          videoUrl: '',
          videoUrls: this.videoUrls,
          completed: false,
          time: parseTime(new Date())
        })
      } else {
        this.getAccountList()
      }
    },
    //生成多个视频时，选择完成
    chooseVideo(url){
      this.videoUrl = url
      this.getAccountList()
    },
    //账号列表
    // getAccountList() {
    //   this.loading = true;
    //   listAccount().then(response => {
    //     this.loading = false;
    //     this.accountList = response.rows;
    //     if(this.accountList.length>0){
    //       this.messages.push({ type: 'listSelector',
    //         text: this.$t('ai.socialChoose'),
    //         options: this.accountList,
    //         multiple: true,
    //         selected: [],
    //         completed: false,
    //         time: parseTime(new Date()) })
    //     }else{
    //       this.messages.push({ type: 'text', content: this.$t('ai.noSocial') ,time:parseTime(new Date())});
    //     }
    //   }).catch(error => {
    //     this.loading = false
    //   });
    // },
    // getAccountList(searchObj, type = '', selectList) {
    //   this.loading = true;
    //   listAllAccount(searchObj).then(response => {
    //     this.loading = false;
    //     if (type === 'search') {
    //       const newList = response.data.map(item => {
    //         if (selectList.length) {
    //           // if (selectList.find(selectItem => selectItem.id === item.id)) {

    //           // }
    //           let newItem;
    //           selectList.forEach(selectItem => {
    //             if (selectItem.id === item.id) {
    //               newItem = {...item, isSelected: true}
    //             }
    //           })
    //           return {...newItem}
    //         } else {
    //           return {...item, isSelected: false}
    //         }
    //       });
    //       console.log(newList);

    //       if(this.newList.length>0){
    //         this.messages[this.messages.length - 1] = {
    //           type: 'listSelector',
    //           text: this.$t('ai.socialChoose'),
    //           options: newList,
    //           oldOptions: this.accountList,
    //           multiple: true,
    //           selected: [],
    //           completed: false,
    //           time: parseTime(new Date())
    //         }
    //       }else{
    //         this.messages[this.messages.length - 1] = { type: 'text', content: this.$t('ai.noSocial') ,time:parseTime(new Date())}
    //       }
    //     } else {
    //       this.accountList = response.data.map(item => {
    //         return {...item, isSelected: false}
    //       });
    //       if(this.accountList.length>0){
    //         this.messages.push({ type: 'listSelector',
    //           text: this.$t('ai.socialChoose'),
    //           options: this.accountList,
    //           multiple: true,
    //           selected: [],
    //           completed: false,
    //           time: parseTime(new Date()) })
    //       }else{
    //         this.messages.push({ type: 'text', content: this.$t('ai.noSocial') ,time:parseTime(new Date())});
    //       }
    //     }
    //   }).catch(error => {
    //     this.loading = false
    //   });
    // },
    getAccountList() {
      this.loading = true;
      listAllAccount().then(response => {
        this.loading = false;
          this.accountList = response.data.map(item => {
            return {...item, isSelected: false}
          });
          if(this.accountList.length>0){
            this.messages.push({ type: 'listSelector',
              text: this.$t('ai.socialChoose'),
              options: this.accountList,
              multiple: true,
              selected: [],
              completed: false,
              time: parseTime(new Date()) })
          }else{
            this.messages.push({ type: 'text', content: this.$t('ai.noSocial') ,time:parseTime(new Date())});
          }
      }).catch(error => {
        this.loading = false
      });
    },
    //确认社媒账号
    submitAccount(selected){
      this.messages.forEach(item => {
        if (item.type === 'listSelector') {
          item.completed = true;
        }
      });
      console.log(selected)
      this.chooseSocial(selected)
    },
    // 选择社媒
    chooseSocial(selected){
      chooseSocial({id:this.aiId ,socialIds:selected}).then(response => {
        this.messages.push({ type: 'text', content: this.$t('ai.contenting') ,time:parseTime(new Date())});
        this.generatePost()
      }).catch(error => {
        this.loading = false
      });
    },
    // 生成帖子文案
    generatePost(){
      this.loading = true
      generatePost({id:this.aiId}).then(response => {
        //generatePostStatus 0默认1生成成功2生成失败
        this.loading = false
        const data = response.data ||{};
        if(data.generatePostStatus==1 && data.postTitle){
            this.messages.push({ type: 'post',
            text: this.$t('ai.contentSuccessGo'),
            // titles: [data.postTitle],
            // contents: [data.postContent],
            titles: data.postTitle,
            contents: data.postContent,
            completed: false,
            time: parseTime(new Date()) })
        }else {
          this.messages.push({ type: 'text', content: this.$t('ai.contentFailed') ,time:parseTime(new Date())});
        }
      }).catch(error => {
        this.loading = false
      });
    },
     //确认帖子文案
     submitPost(message){
      console.log('message',message)
      // const title = message.titles.join(",")
      // const description = message.contents.join(",")
      const title = message.titles
      const description = message.contents
      if(!title){
        this.$modal.msgError(this.$t('ai.tipTitle'));
        return
      }if(!description){
        this.$modal.msgError(this.$t('ai.tipContent'));
        return
      }
      this.messages.forEach(item => {
        if (item.type === 'post') {
          item.completed = true;
        }
      });
      this.loading = true
      publishAi({
          id:this.aiId,
          postTitle:title,
          postContent:description
        }).then(response => {
          this.loading = false
        //publishSocialStatus ：0默认1推送队列2发布成功3发布失败
        const data = response.data ||{};
        if(data.publishSocialStatus==3){
          this.messages.push({ type: 'text', content: this.$t('ai.publishFailed') ,time:parseTime(new Date())});
        }else if(data.publishSocialStatus == 1){
          this.messages.push({ type: 'text', content: this.$t('ai.publishing') ,time:parseTime(new Date())});
        }else if(data.publishSocialStatus == 2){
          this.messages.push({ type: 'text', content: this.$t('ai.publishSuccess') ,time:parseTime(new Date())});
        }
      }).catch (error => {
        this.loading = false
        this.messages.push({ type: 'text', content: this.$t('ai.publishFailed') ,time:parseTime(new Date())});
      });
    },
    //获取设置信息
    getSetting() {
      getAiSetting().then(response => {
        this.settings = {
          ...this.settings,
          ...response.data,
        };
        if (this.settings.backgroundMusicVolume && typeof this.settings.backgroundMusicVolume === 'string') {
          this.settings.backgroundMusicVolume = parseInt(this.settings.backgroundMusicVolume, 0);
        }
        console.log('this.settings',this.settings)
        if(!this.settings.speechVoice){
          this.$confirm(this.$t('ai.setBasicInfo'),
            this.$t('tip.tip'), {
            confirmButtonText: this.$t('ai.goToSettings'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning',
          }).then(() => {
            this.isShowSettings = true
            // this.activeTab = 'setting'
          })
        }
      })
    },
    //成片分组列表
    getProductTypeList() {
      listProductType().then(response => {
        this.productTypelist = response.rows;
        this.productTypelist.unshift({
          id: 0,
          name: this.$t('product.product'),
        });
      });
    },
    // 选择背景颜色
    handleColorSelect(color) {
      this.settings.backgroundColor = color;
    },
    // 显示颜色选择器
    showColorPicker() {
      this.$refs.colorPicker.$el.click();
    },
    handleSubmit() {
      this.$refs.settingsForm.validate((valid) => {
        if (valid) {
          this.setLoading = true
          if(this.settings.id){
            editAiSetting(this.settings).then(response => {
              this.$modal.msgSuccess(this.$t('aiSet.success'));
            }).finally(() => {
              this.setLoading = false
            })
          }else{
            addAiSetting(this.settings).then(response => {
              this.$modal.msgSuccess(this.$t('aiSet.success'));
            }).finally(() => {
              this.setLoading = false
            })
          }
          this.isShowSettings = false
        }
      });
    },
    //音量被改变
    changeVolume(){
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null;
      }
    },
    //试听音色
    playAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null;
      }else{
        this.currentAudio = new Audio(this.currentVoiceUrl); // 创建新的音频实例
        this.currentAudio.volume = this.settings.backgroundMusicVolume
        // 监听音频播放结束事件
        this.currentAudio.addEventListener('ended', this.onAudioEnded);
        this.currentAudio.play(); // 播放音频
      }
    },
    onAudioEnded(){
      this.currentAudio = null;
    },
    //音色选择框关闭时，同步关闭音频
    speechVoiceChange(visible){
      if (!visible && this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null; // 清空当前音频实例
      }
    },
    closeSettings() {
      this.isShowSettings = false
    }
  }
};
</script>

<style lang="scss">
.aiPage {
  margin: 24px;
}
.flexCenter {
  width: 100%;
  display: flex;
  justify-content: center;
}
.aiHeader {
  height: 56px;
  background: linear-gradient(rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.4) 100%);
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.02);
  border-radius: 14px;
  border: 1px solid #FFFFFF;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}
.aiHeaderTitle {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  background: linear-gradient(90deg, #0089FF 0%, #6E16D1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.el-icon-s-tools {
  font-size: 20px;
  color: #016FEE;
  cursor: pointer;
}
.container{
  /* background-color: white; */
  padding: 16px;
  height: 100%;
  /* border-radius: 8px; */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: #FFFFFF;
  border-radius: 14px;
  border: 1px solid #E5E7EB;
  position: relative;
}
.chat-container {
  /* display: flex; */
  /* flex-direction: column; */
  position: relative;
  height: 100%;
  min-height: 65vh;
  height: calc(100vh - 280px);
  padding: 16px 16px 56px 16px;
  background: #f9fbff;
  border-radius: 10px;
}

.messages {
  /* flex: 1; */
  height: calc(100vh - 380px);
  overflow-y: auto;
  padding-bottom: 20px;
}
.video-settings {
  /* padding: 20px; */
  /* background-color: #fff; */
  position: absolute;
  width: 100%;
  height: calc(100vh - 280px);
  top: 0;
  left: 0;
}

.whiteBg {
  background: #fff;
  height: 580px;
  padding: 20px;
  border-radius: 14px;
}
.blackBg {
  height: calc(100% - 500px);
  background: rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.upload-demo {
  display: flex;
  align-items: center;
}

.color-picker-container {
  width: 130px;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  position: relative;
}

/* .color-picker-container .el-input {
  pointer-events: none;
}

.color-picker-container .el-color-picker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
} */

.ml-10 {
  margin-left: 10px;
}
.material-container {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.material-thumbnail {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.aspect-ratio-box {
  width: 140px;
  height: 140px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
}

.shiting {
  width: 38px;
  height: 38px;
  background: #0156FF;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  cursor: pointer;
}
.icon-shiting {
  color: #fff;
  font-size: 17px;
}

.formFooter {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submitButton {
  width: 106px;
  height: 40px;
  background: linear-gradient( 90deg, #0089FF 0%, #6E16D1 100%);
  border-radius: 12px;
  display: flex;
  justify-content: center;
}
.defaultPrompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.defaultTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: rgba(0,0,0,0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
}
.defaultContent {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0,0,0,0.4);
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-top: 10px;
}

.el-slider__button-wrapper {
  height: 36px;
  width: 36px;
  position: absolute;
  z-index: 1 !important;
  top: -15px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: transparent;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal;
}
</style>
