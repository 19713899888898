export default {
  tab: {
    newTask: '视频任务',
    taskDetail: '任务详情',
    publishTask: '发布任务',
    strippingDetail: '拆条详情',
    editManuscript: '编辑文稿',
    planDetail: '方案详情',
    productDetail: '商品详情',
    materialDetail: '素材详情',
    worksDetail: '作品详情',
    tiktokAccountDetail: 'Tiktok账号详情',
    createProduct: '创建商品',
    orderDetail: '订单详情',
    aiDetail: 'AI详情',
    createModel: '创建模型',
    modelDetail: '模型详情',
  },
  newRegister: {
    account: '账号名',
    againPassword: '再次输入密码',
    emailOrPhone: '邮箱/手机号',
    tipAccount: '请输入账号',
    passwordMismatch: '两次输入的密码不一致',
    getEmailOrPhoneCode: '获取验证码',
    verificationCode: '邮箱/手机号验证码',
    tipCorrectEmailOrPhone: '请输入正确格式的邮箱或手机号'
  },
  //登录页面
  login: {
    title: '若依后台管理系统',
    logIn: '登录',
    logIng: '登 录 中 ...',
    username: '账号名/邮箱/手机号',
    password: '密码',
	  code: '验证码',
    rememberMe: '记住密码',

    accountLogin:'账号登录',
    emailLogin:'邮箱登录',
    mobileLogin:'手机登录',

    email:'邮箱',
    mobile:'手机号',
    imageVerificationCode:'请输入图片验证码',
    verificationCode:'请输入验证码',
    noAccount:'还没有账号？',
    signUp:'立即注册',
    quickSignUp:'快速注册',
    getEmailCode:'获取邮箱验证码',
    getSMSCode:'获取短信验证码',
    later:'s后重新获取',

    tipEmail:'请输入您的邮箱/手机号',
    tipCorrectEmail:'请输入正确的邮箱',
    tipPhone:'请输入您的手机号',
    tipCorrectPhone:'请输入正确的手机号码',
    tipAccount:'请输入您的账号',
    tipPassword:'请输入您的密码',
    tipCode:'请输入验证码',
  },
  //快速注册
  quickSignUp:{
    registrationSuccess:'注册成功',
    rememberCredentials:'请记住账号和密码，以便下次登录。',
    accountLabel:'<br>账号：',
    passwordLabel:'<br>密码：',
    autoFill:'自动填充',
  },
  register: {
    register: '注册',                               // Register
    completeInformation: '完善信息',
    basicInfo: '基本信息',                          // Basic Information
    setupAccount: '配置账号',                       // Configure Account
    companyName: '企业名称',                       // Company Name
    industry: '所属行业',                           // Industry
    brand: '品牌',                                 // Brand
    brandTip: '多个品牌请用逗号分隔',
    product: '产品',                               // Product
    productTip: '多个产品请用逗号分隔',
    next: '下一步',                                // Next
    previous: '上一步',                            // Previous
    registerType: '注册类型',                      // Registration Type
    emailRegister: '邮箱注册',                     // Email Registration
    phoneRegister: '手机号注册',                    // Phone Registration
    password: '密码',                              // Password
    passwordHint: '请输入8位以上大小写加数字密码',  // Please enter a password of 8 characters or more, including uppercase, lowercase, and numbers
    imageCaptcha: '图片验证码',                     // Image Captcha
    enterImageCaptcha: '请输入图片验证码',          // Please enter the image captcha
    verificationCode: '验证码',                    // Verification Code
    enterCode: '请输入验证码',                      // Please enter the verification code
    submitRegister: '注 册',                      // Register
    registering: '注 册 中...',                      // Registering
    useExistingAccount: '使用已有账户登录',         // Use existing account to log in
    enterCompanyName: '请输入您的企业名称',         // Please enter your company name
    selectIndustry: '请选择所属行业',              // Please select an industry
    enterBrandName: '请输入品牌名称',              // Please enter the brand name
    enterProduct: '请输入公司的主要产品',          // Please enter the main product of the company
    passwordEmpty: '密码不能为空',                  // Password cannot be empty
    passwordCriteria: '密码长度8-18位,必须由大写字母，小写字母，数字，特殊符号组成', // Password must be 8-18 characters long and include uppercase, lowercase, numbers, and special characters
    enterVerificationCode: '请输入验证码',          // Please enter the verification code
    enterImageCaptchaAgain: '请输入图片验证码',     // Please enter the image captcha
    acceptTerms: '请勾选用户服务协议',             // Please check the user service agreement
    registrationReceived: '我们已收到您的注册信息，24小时内完成审核，会以邮箱/手机短信形式通知，请注意查收。', // We have received your registration information. It will be reviewed within 24 hours, and you will be notified by email or SMS. Please check your messages.
    systemPrompt: '系统提示',                      // System Prompt
  },
  forget: {
    forgotPassword: '忘记密码',                    // Forgot Password
    resetType: '选择重置账号类型',                 // Select Account Reset Type
    resetByEmail: '邮箱重置',                     // Reset by Email
    resetByPhone: '手机号重置',                   // Reset by Phone
    enterNewPassword: '请输入新密码',             // Please enter a new password
    resetPassword: '重置密码',                    // Reset Password
    resetting: '重置中...',                       // Resetting...
    backToLogin: '返回登录',                      // Return to Login
    resetSuccess: '恭喜你，您的密码重置成功！',    // Congratulations, your password has been reset successfully!
  },
  agreed:{
    readAndAgree:'我已阅读并同意',
    userAgreement:'《用户协议》',
    and:'和',
    privacyPolicy:'《隐私条款》',
    prompt:'请先阅读并同意《用户协议》和《隐私条款》。',
  },
  menus: {
    workbench: "首页",
    personalCenter: "个人中心",
    assignRoles: "分配角色",
    assignUser: "分配用户",
    dictionaryData: "字典数据",
    dispatchingLog: "调度日志",
    modifyConfig: "修改生成配置",
  },
  //工作台
  workbench: {
    homePage: '首页',                                  // Home
    clickToEnter: '点击进入',                           // Click to Enter
    accountManagement: '账号管理',                       // Account Management
    accountManagementTitle: '一键添加，多账号管理',          // One-click addition, multi-account management
    accountManagementDes: '全域账号协同，实现数据与内容的智能化管理', // Collaborate across all accounts for intelligent management of data and content
    materia: '素材库',                                 // Material Library
    materiaTitle: '素材分类管理清晰明了',                  // Clear and concise material categorization
    materiaDes: '构建多维度素材库生态, 激发创作灵感',            // Build a multi-dimensional material library ecosystem to inspire creativity
    videoProduction: '视频混剪',                         // Video Editing
    videoProductionTitle: '视频剪辑简单轻松、快捷流畅',         // Simple and smooth video editing
    videoProductionDes: '重塑视频创作流程, 从脚本生成到智能剪辑',      // Reshape the video creation process from script generation to intelligent editing
    releaseManagement: '发布管理',                       // Release Management
    releaseManagementTitle: '定时定向快捷发布',               // Quick, targeted, scheduled releases
    releaseManagementDes: '突破地域与平台界限, 实现精准定时、多维度的内容传播策略', // Break geographical and platform boundaries to achieve precise, multi-dimensional content distribution strategies
    AITitle: 'AI助力，自动完成、省心省力',                   // AI Assistance, automated and worry-free
    AIDes: '从创意萌芽到作品呈现, 一气呵成',                    // From concept to finished video, seamlessly done
    welcomeMessage: 'Hi,欢迎登录InfoPub！',                // Hi, welcome to InfoPub!
    startVideo: '视频快速混剪，开始AI创作吧！',                // Quick video editing, start AI creation now!
    accountCount: '账号数量',                              // Number of Accounts
    newAccount: '授权新账号',                               // Authorize New Account
    fanCount: '粉丝数',                                   // Fans Count
    workCount: '作品数',                                  // Works Count
    playCount: '播放数',                                  // Play Count
    commentCount: '评论数',                                // Comment Count
    publishTask: '发布任务',                               // Publish Task
    account: '账号',                                     // Account
    fans: '粉丝',                                       // Fans
    likes: '点赞',                                      // Likes
    posts: '发帖',                                      // Posts
    publishing: '发布中',                                 // Publishing
    publishSuccess: '发布成功',                            // Publish Success
    publishFail: '发布失败',                               // Publish Failed
    videoCreation: '视频创作',                              // Video Creation
    videoMix: '视频混剪',                                 // Video Editing
    AIVideoMix: 'AI混剪',                                 // AI Video Editing
                  
    generating: '生成中',                                 // Generating
    generateFail: '生成失败',                              // Generate Failed
    generateSuccess: '生成成功',                            // Generate Success
    AISales: 'AI销售成效',                                // AI Sales Performance
    viewMore: '查看更多',                                 // View More
    commentsCount1: '已评论',                            // XX Comments
    commentsCount2: '条',                            // XX Comments
    announcement: '公告',                                 // Announcement
    helpDoc: '帮助文档',                                  // Help Documents
    productSummary: '产品概括',                             // Product Summary
    userGuide: '使用指南',                                 // User Guide
    planDescription: '套餐说明',                            // Plan Description
    productNews: '产品资讯',                               // Product News
    events: '活动',                                      // Events
    messages: '消息',                                    // Messages
    notifications: '通知',                                 // Notifications
    modelTraining: '模型训练',
    modeLDes: '模型训练，精准锻造，让数据驱动智慧升级！',
    notOpen: '暂未开放，敬请期待',
    communityList:'社区列表',

    videoByText: '文生视频',
    videoByTextTip: '文字转视频，一键即成',
    AIVideo: 'AI视频',                                   
    AIVideoTip: 'AI快剪，轻松制作', 
    videoTranslation:'视频翻译',
    videoTranslationTip:'视频秒懂，翻译助力',
    operationsStrategy:'运营有策',
    operationsStrategyTip:'运营有方，计划先行',
    releaseCommunity:'发布社区',
    releaseCommunityTip:'流畅发布，处处可看',
    messageInteraction:'消息互动',
    messageInteractionTip:'即时消息，随时通知',
  },
  //账号管理
  account: {
    accountBinding:'添加社交媒体账号（TikTok、Instagram、YouTube等）',
    tiktokBinding:'点击绑定TikTok账号',
    bindAccount:'绑定账号',
    addAccount:'添加账号',
    account:'账号',
    tipAccount:'请输入用户名、账号或昵称',
    bindHomePage:'账号',
    username:'用户名',
    user:'用户',
    nickname:'昵称',
    role:'用户角色',
    sales:'销售',
    operations:'运营',
    marketing:'市场',
    tipRole:'可选择，也可直接输入',
    description:'备注',
    countryAndLanguage:'国家和语言',
    tipSetDefault:'点击设置默认国家和语言',
    bindingState:'状态',
    bindingSource:'平台',
    unbundle:'解绑',
    set:'设置',
    default:'默认',
    setDefault:'设置默认国家和语言',
    language:'默认语言',
    country:'国家和时区',
    tipCountry:'请选择国家',
    tipLanguage:'请选择语言',
    tipTimezone:'请选择时区',
    successfully:'解绑成功',
    tipUnbind1:'解绑后，此账号将无法发帖！是否确认解绑主页名称为"',
    tipUnbind2:'"的数据项？',
    tipDelete:'确认删除该用户以及该用户下的所有账号数据吗？',
    tipDeleteSuccessfully:'删除成功',
    settingsSaved:'设置已保存',
    bindTime:'绑定时间',
    bindPlatform:'绑定平台',
    tipBindPlatform:'请选择绑定平台',
    updateTime:'最后一次操作时间',
    accountDetails:'账号详情',
  },
  //素材库
  material: {
    all: '全部',
    position:'位置',
    formRulesNameLength: '文件名长度不能大于50',
    formRulesDescribeLength: '文件描述长度不能大于500',
    video:'视频',
    picture:'图片',
    audio:'音乐',
    copywriting:'文案',
    tipName:'素材名称或描述',
    upload:'上传素材',
    edit:'编辑素材',
    tipSelect:'请选择素材文件',
    tipDelete:'确认删除该素材吗?',
    removeSubtitle:'视频去字幕',
    aiFaceSwap:'AI换脸',
    isStripped:'是否切片',
    provideFaceSwapMaterial:'需要提供换脸的素材',
    retainOriginalVideo:'保留原视频',
    tipRemoveSubtitle:'提示：目前视频上传支持切片、去字幕和AI换脸功能，其中去字幕功能只支持mp4格式。',
    tipVideo:'请先选择素材',
    timeLimit:'已选择素材时长不满足场景需求',
    exceedQuota: '已超出容量配额'
  },
  //文案
  copywriting:{
    tipName:'文案名称或内容',
    new:'新增文案',
    edit:'编辑文案',
    view:'查看文案',
    title:'文案标题',
    content:'文案内容',
    tipTitle:'请输入文案标题',
    tipContent:'请输入文案内容',
    tipDelete:'确认删除该文案吗?',
  },
  //文件
  file:{
    file:'文件',
    upload:'上传',
    download:'下载',
    name:'文件名称',
    description:'文件描述',
    tipSelect:'请选择文件',
    tipName:'请输入文件名称',
    attachment:'附件',
    clickUpload:'点击上传',
    chooseUpload:'选择文件或文件夹',
    pleaseUpload:'请上传',
    sizeLimit:'大小不超过',
    formatLimit:'格式为',
    dFile:'的文件',
    filePreview:'预览',
    invalidFormat1:'文件格式不正确, 请上传',
    invalidFormat2:'格式的文件!',
    sizeExceed:'上传文件大小不能超过',
    quantityExceed:'上传文件数量不能超过',
    uploading:'正在上传，请稍候...',
    uploadFailed:'上传文件失败，请重试',
    createFolder:'新建文件夹',
    folder:'文件夹',
    tipSelectFolder:'请选择文件夹',
    tipDeleteFolderSingle:'确认删除该文件夹及里面的文件吗?',
    editFolder:'编辑文件夹',
    goBack:'返回上一层',
    goBackMaterial:'素材库',
    goBackWorks: '作品库',
    folderName:'文件夹名称',
    folderLabel:'文件夹标签',
    selectMaterial: '选择素材',
    selectProduct: '选择作品',
    localUpload: '本地上传',
    tipDeleteSystemFolder:'您选中的文件中包含文件夹，是否删除文件夹及里面的所有文件？',
    tipDeleteFolder:'是否删除选中的文件？',
  },
  //标签
  label:{
    formRulesLabelLength: '标签不能大于20',
    label:'标签',
    hintLabel:'请输入标签名称',
    tipLabel:'标签不能为空',
    add:'添加标签',
    edit:'修改标签',
    tipSelect:'请选择标签',
    tipDelete:'确认删除该标签吗?',
  },
  //作品管理:
  product:{
    product:'作品库',
    video:'作品',
    tipName:'作品名称或描述',
    tipDelete:'确认删除该作品吗?',
    tipVideo:'请先选择作品',
  },
  //AI视频
  ai:{
    headerTitle: 'AI视频制作',
    video:'AI视频',
    doing:'AI创作中',
    workHard:'AI努力工作中, 暂不支持输入',
    tipInstruct:'请输入指令，指令内容包含：视频主题、语言、面向国家、视频时长',
    send:'发送',
    title:'标题',
    content:'文案',
    sure:'确认',
    scriptSuccess:'AI生成脚本成功。',
    scriptSuccessGo:'AI生成脚本成功，请检查脚本，点击进行修改。',
    scriptFailed:'素材脚本生成失败,请重试',
    tipTitle:'请输入标题',
    tipContent:'请输入文案',
    videoing:'视频生成中，请稍候',
    videoFailed:'视频生成失败',
    videoSuccess:'AI视频制作成功。',
    videoAgain:'重新生成',
    videoChoose:'由于您生成了多个视频，请选择要发布的视频:',
    videoChooseFirst:'请选择视频',
    socialChoose:'请选择社交账号：',
    socialChooseFirst:'请先选择账号',
    socialChoosed:'选择的社交账号：',
    noSocial:'未找到社交账号，请在账号管理中先行绑定社交账号。',
    contentFailed:'生成文案失败',
    contenting:'生成描述文本中',
    contentSuccessGo:'描述文本生成成功，点击编辑修改。',
    contentSuccess:'描述文本生成成功。',
    publishing:'视频成功推到发布队列，稍后你可以进入“发布”中查看。',
    publishSuccess:'视频发布成功，可以进入发布管理中查看。',
    publishFailed:'视频发布失败',
    aiWaitMsg:'AI创作中，若等待时间过长，您可以先去',
    otherTasksMsg:'中操作其他内容',
    setBasicInfo:'使用AI视频功能前，需要先设置基本信息，请先设置',
    goToSettings:'去设置',
    defaultTitle1: '暂未生成AI内容',
    defaultTitle2: '您可试试在下方输入框中输入信息，可为您AI生成创意视频，快来使用吧～',
  },
  //AI设置
  aiSet:{
    set:'设置',
    color:'背景颜色',
    tipColor:'请选择背景颜色',
    musicVolume:'背景音乐音量',
    tipVolume:'请设置背景音乐音量',
    speechVoice:'视频音色',
    tipVoice:'请选择视频音色',
    location:'存储位置',
    tipLocation:'请选择存储位置',
    save:'保存设置',
    success:'保存成功',
  },
  //视频混剪
  videoEditor:{
    addScene:'新建场景',
    scene:'场景-',
    draft:'存草稿',
    draftFailed:'保存草稿成功',
    preview:'预览',
    previewResult:'混剪结果预览',
    info:'基本信息',
    tipInfo:'请先完善基本信息',
    tipInfoMust:'请先完善必填信息',
    videoName:'视频名称',
    videoRatio:'视频比例',
    tipVideoName:'请输入视频名称',
    tipNameMust:'视频名称为必填项',
    configureInfo:'为各个镜头组配置素材、文案、时长等信息',
    selectModel:'选择模型',
    keywordDescription:'关键字描述',
    effectPreview:'效果预览',
    viewConfigEffect:'查看配置效果',
    generateVideo:'生成视频',
    videoSettings:'视频设置',
    createVideoName:'为整个视频任务创建名称',
    materialScene:'素材场景',
    splitScene:'分场景',
    videoMixing:'视频混剪',
    lastSceneWarning:'最后一个场景了，不能删除',
  },
  videoSetting: {
    videoParams: '对视频文件的参数和属性进行配置',          // Configure parameters and properties of video files
    videoNameLocation: '配置视频的名称和存储位置',         // Set the video name and storage location
    visualExperience: '创造独特的视觉体验',               // Create a unique visual experience
    visualElements: '构建视觉环境的各种元素和背景',         // Build various elements and backgrounds of the visual environment
    segmentAllocation: '为各视频片段分配素材、文案和时长设置', // Assign materials, scripts, and duration settings for each video segment
    subtitleTitleEffects: '配置字幕和标题效果',             // Configure subtitle and title effects
  },
  basicInfo:{
    sceneSettings:'场景设置',
    configureInfo:'为各个镜头组配置素材、文案、时长等信息',
    scene:'场景',
    sceneName:'场景名称',
    tipSceneName:'请输入场景名称',
    import:'导入素材',
    tipImport:'请导入素材',
    materialImport:'素材库导入',
    remove:'移除',
    isMute:'是否开启原音',
    materia:'素材库',
    scene1:'场景-1',
    pruning:'粗剪',
    pruningTitle:'视频粗剪',
    mark:'标记',
    segment:'视频切片',
    selectSegments:'请勾选需要使用的视频段落',
    startPoint:'当前为起点，不能分割',
    endPoint:'当前为终点，不能分割',
    existingMark:'当前位置已有标记点',
    minSelect:'最少选择一条要使用的视频段落',
    timeType:'时长设置',
    timeVideo:'视频原始时长',
    timeVideoExp:'按照视频原始时长',
    timeAudio:'随音频，视频智能截取',
    timeAudioExp:'镜头时长=音频的时长 视频截取同等时长的素材',
    timeRegular:'固定时长',
    timeRegularExp:'场景时长=固定时长 视频和音频截取同等时长的素材',
    tipDuration:'请输入正确的时长',
    previewNotSupported:'该模式下暂不支持预览，不影响生成视频',
    audioDurationType:'随音频模式不支持预览，请直接生成视频',
  },
  subtitle:{
    subtitle:'字幕与配音',
    open:'开启',
    close:'关闭',
    content:'字幕文本',
    tipContent:'请输入字幕文本',
    tipContentFirst:'请先输入字幕文本',
    font:'字幕字体',
    tipFont:'请选择字体',
    fontSizeType:'字幕字号',
    tipFontSizeType:'请选择字体大小',
    small:'小',
    normal:'中',
    big:'大',
    spacing:'字间距',
    fontColor:'字幕颜色',
    tipFontColor:'请选择字体颜色',
    outline:'描边宽度',
    outlineColour:'描边颜色',
    position:'字幕位置',
    top:'上',
    center:'中',
    bottom:'下',
    fontFaces:'字幕样式',
    italic:'斜体',
    blod:'加粗',
    underline:'下划线',
    dubbingSettings:'配音设置',
    language:'语言',
    tipLanguage:'请选择语言',
    tipLanguageFirst:'请先选择语言',
    voice:'配音音色',
    tipVoice:'请选择音色',
    gain:'配音音量',
    preview:'实时预览',
    effectColorStyle:'花字',
    translate:'翻译',
    sumup:'AI生成',
    abbreviation:'AI缩写',
    expand:'AI扩写',
    rewrite:'AI重写',
    calculate:'计算口播时长',
    tipCalculate1:'预计',
    tipCalculate2:'s，未超出视频时长，可正常使用',
    tipCalculate3:'s，可能会超出视频时长',
  },
  title:{
    title:'标题设置',
    show:'显示标题',
    hide:'隐藏标题',
    tipTitle:'请输入标题',
    font:'字体',
    tipFont:'请选择字体',
    fontSizeType:'字号',
    tipFontSizeType:'请选择字体大小',
    small:'小',
    normal:'中',
    big:'大',
    spacing:'字间距',
    fontColor:'字体颜色',
    tipFontColor:'请选择字体颜色',
    outline:'描边宽度',
    outlineColour:'描边颜色',
    alignment:'位置',
    left:'左',
    center:'中',
    right:'右',
    opacity:'透明度',
    fontFaces:'样式',
    italic:'斜体',
    blod:'加粗',
    underline:'下划线',
    preview:'实时预览',
    effectColorStyle:'花字',
  },
  mixing:{
    mixSet:'混剪设置',
    position:'logo位置',
    leftTop:'左上',
    centerTop:'正上',
    rightTop:'右上',
    leftBottom:'左下',
    centerBottom:'正下',
    rightBottom:'右下',
    cover:'封面',
    backCover:'封底',
    music:'背景音乐',
    upload:'点击上传',
    bgColor:'背景颜色',
    filter:'滤镜',
    filterType:'匹配方式',
    filterRandom:'每组场景随机匹配一个滤镜',
    filterSame:'全部场景使用同一个滤镜',
    transition:'转场',
    transitionType:'匹配方式',
    transitionRandom:'每组场景随机匹配一个转场',
    transitionSame:'全部场景使用同一个转场',
    materia:'素材库',
  },
  //视频管理
  videoManager:{
    type:'类型',
    status:'生成状态',
    newTask:'新建任务',
    name:'视频名称',
    tipName:'请输入视频名称',
    length:'视频总时长',
    statusDraft:'草稿',
    publishStatus:'发布状态',
    publishTime:'发布时间',
    publish:'发布',
    playVideo:'查看视频',
    aiPage:'详情',
    tipDelete:'确认删除该条数据吗?',
    videoContent:'视频内容',
  },
  videoItem:{
    clipDetails:'切片详情',
    clipSuccess:'切片成功',
    generating:'生成中',
    removeSubtitleSuccess:'去字幕成功',
    removeSubtitleFail:'去字幕失败',
    aiFaceSwapSuccess:'AI换脸成功',
    aiFaceSwapFail:'AI换脸失败',
    clipping:'切片中',
    clipCompleted:'切片成功',
    clipFail:'切片失败',
  },
  stripping:{
    basicInfo:'基本信息',
    name:'名称',
    description:'描述',
    tags:'标签',
    fileSize:'文件大小',
    videoDuration:'视频时长',
    addedTime:'添加时间',
    clipInfo:'切片信息',
    videoClips:'视频片段',
    videoTags:'视频标签',
    videoDescription:'视频描述',
  },
  //视频预览
  preview:{
    result:'混剪结果预览',
    make:'生成视频',
    preview:'预览',
    videoPreview:'视频预览',
    tipVideo:'请选择视频',
    tipSuccess:'生成视频任务成功',
    tipFailed:'视频预览加载失败',
  },
  //发布管理
  issueManager:{
    platform:'发布平台',
    account:'平台账号',
    type:'发布类型',
    status:'发布状态',
    time:'发布时间',
    issue:'发布任务',
    taskName:'备注',
    social:'社交账号',
    content:'文案',
    statusDraft:'草稿',
    playVideo:'查看视频',
    playResult:'查看结果',
    publishContent:'发布内容',
    notUploaded:'暂未上传',
    publishing: '发布中',
    published: '已发布',
    failed: '已失败',
    result: '结果',
    publish: '发布',
  },
  issueVideo:{
    taskName:'备注',
    tipTaskName:'请输入任务名称',
    add:'添加发布',
    saveDraft:'保存草稿',
    issue:'发布',
    saveSuccess:'保存成功',
    tipInfoMust:'请先完善必填信息',
    addSuccess:'任务创建成功',
    tipDeleteSure:'确认删除该条发布信息吗?',
    tipDelete:'最后一条发布信息了，不允许删除',
  },
  issueItem:{
    account:'发布账号',
    tipAccount:'请选择发布账号',
    post:'文案编辑',
    tipPost:'请输入文案',
    tipPostFirst:'请先输入文案',
    tipPostLength:'Twitter平台文案长度不能超过280',
    emo:'表情',
    translate:'翻译',
    oneTranslate:'一键翻译',
    tipTranslate:'请选择需要翻译成的语言',
    translateTo:'将文本内容翻译为：',
    translateLanguage:'需要翻译成的语言',
    selectVideo:'选择视频',
    tipVideo:'请选择视频',
    import:'作品导入',
    remove:'移除',
    paly:'播放',
    publishTime:'发布时间',
    publishType:'发布类型',
    tipTimeType:'请选择发布时间类型',
    sameTime:'统一时间',
    customTime:'自定义时间',
    issueNow:'立即发布',
    issueCountry:'按账号设置国家发布（当地时间）',
    issueUnify:'按统一时间发布',
    localTime:'按账号所属当地时间',
    standardTime:'世界标准时间',
    tipDate:'选择日期时间',
    tipTimezone:'请选择时区',
    product:'成品库',
    tipIssueTime:'请选择发布时间',
    tipIssueTimezone:'请选择发布时区',
    tipIssueTimeAll:'请将发布时间补充完整',
    tipSet:'提示：需提前设置好对应账号的默认国家和语言',
    tipSocialTime:'提示：请先选择发布账号',
    tipSetNo:'当前账号未设置默认国家和语言，请到账号管理中设置，或修改成其他发布时间。',
    tipBdCode:'当前选中的账号均未设置国家和语言，无法一键翻译成对应语言，可使用普通翻译功能手动选择要翻译的语言。',
    tipNotFind:'当前选中账号在账号管理中未找到，请重新选择发布账号',
    reset:'文案重置',
    tipOneTranslate:' 提示：对于一键翻译的文案，如果发布账号和文案有修改，需再次点击一键翻译，否则可能存在不对应的情况',
  },
  infoStream:{
    tipKeyword:'请输入关键词',
  },
  aiCrosscutting:{
    newTask:'视频任务',
    templateGeneration:'模板生成',
    customGeneration:'自由生成',
    customScript:'自有文案',
    scriptCopy:'脚本文案',
    resetContent:'重置内容',
    viewMore:'查看更多',
    inputPrompt:'说说想写什么',
    enterKeyword:'请输入关键词或主题内容',
    keywordPrompt:'请输入关键词或主题内容，AI帮你生成优质文案。',
    contentLength:'内容长度',
    short:'短',
    medium:'中',
    long:'长',
    generationCount:'生成数量',
    maxGeneration:'最多可生成5条',
    seoIntegration:'结合标签',
    golden3sStart:'黄金3s开头',
    generateContent:'AI生成',
    scriptPreview:'生成预览',
    parseHint:'勾选生成内容进行下一步内容文章解析，解析后支持手动修改，最多一次选择5条',
    titleExtraction:'取标题',
    scriptSupplement:'文案补充',
    scriptPolish:'文案润色',
    scriptSimplify:'文案精简',
    keywordInputHint:'暂未生成创意文案',
    uniqueContent:'在左侧面板中输入信息，可为你生成图文创意',
    analyze:'解析',
    script:'文案',
    addScript:'添加文案',
    title:'标题',
    enterTitle:'请输入标题',
    scriptContent:'文案内容',
    enterScriptContent:'请输入文案内容',
    contentRewrite:'内容仿写',
    deleteScript:'删除当前文案',
    selectTemp:'选择模板',
    selectMaterial:'从素材库获取',
    selectTemplate:'请选择模板',
    enterScriptFirst:'请先输入文案',
    generateScriptFirst:'请先生成文案',
    selectAtLeastOneScript:'请至少选择一条文案',
    selectUpTo5Script:'最多一次选择5条文案',
  },
  template:{
    apply:'应用',
    scriptTemplate:'文案模板',
    applyTemplate:'应用该模板',
    changeTemplate:'更换模板',
    moreTemplates:'更多模板',
    simplified:'精简版',
    enriched:'丰富版',
  },
  goldenTypes:{
    curiosity:'好奇类',
    leverage:'借势类',
    painPoint:'痛点类',
    extreme:'极限类',
    fear:'恐吓类',
    contrast:'反差类',
    benefitTransfer:'利益输送',
    empathy:'同理心',
  },
  editManuscripts:{
    script:'文案',
    title:'标题',
    enterTitle:'请输入标题',
    scriptSegmentation:'文稿分段',
    addLanguage:'添加语种',
    tipAddLanguage:'一次最多支持3个语种',
    grouping:'分组',
    nextStep:'下一步',
    material:'素材',
  },
  editVideo:{
    video:'视频',
    generateVideo:'生成视频',
    title:'标题',
    enterTitle:'请输入标题',
    dataError:'数据错误，请修改参数后，重新尝试',
    transitionSetup:'转场设置',
    preview:'试听',
    selectAudio:'请先选择音乐',
    autoGenerate:'智能生成',
    replaceClip:'替换素材',
    musicSelect:'已选中音乐'
  },
  videoMenu:{
    scenes:'场景',
    music:'音乐',
    voiceOver:'配音',
    subtitles:'字幕',
    text:'标题',
    transitions:'转场',
    setting:'设置',
  },
  aiSubtitle:{
    setup:'字幕设置',
    show:'显示字幕',
    hide:'关闭字幕',
    font:'字体选择',
    textColor:'文字颜色',
    fontSize:'字体大小',
    style:'样式',
    position:'位置',
  },
  aiTitle:{
    setup:'标题设置',
    textContent:'文本内容',
    font:'字体',
    fontSize:'字号',
    letterSpacing:'字间距',
    fontColor:'字体颜色',
    strokeWidth:'描边宽度',
    strokeColor:'描边颜色',
    position:'位置',
    opacity:'透明度',
    style:'样式',
    decorativeText:'花字',
  },
  //运营策略
  operation: {
    planName:'方案名称',
    enterPlanName:'请输入方案名称',
    timePeriod:'时间段',
    videoTopicCount:'视频主题数量',
    videoTopicRangea:'视频主题数量最少1个，最多100个，建议每日视频发布数量控制在1-4个',
    releaseMethod:'视频发布方式',
    distributeEvenly:'按时间平均分配',
    aiAssign:'AI自主分配',
    userProfile:'用户画像',
    enterUserProfile:'请输入用户画像',
    planLanguage:'方案语言',
    socialAccount:'社交账号',
    accountSupport:'支持同一时区相同语言的账户批量选择',
    targetDetails:'目标客户行业：\n目标客户公司规模：\n目标客户国家：\n主推的产品关键词：',
    nameRequired:'方案名称不能为空',
    selectTimePeriod:'请选择时间段',
    videoTopicRequired:'视频主题数量不能为空',
    selectReleaseMethod:'请选择视频发布方式',
    userProfileRequired:'用户画像不能为空',
    selectPlanLanguage:'请选择方案语言',
    selectSocialAccount:'请选择社交账号',
    selectExistAccount:'请选择当前可用的社交账号',
    selectTimeZoneAccount:'请选择相同时区的社交账号',
    releaseTime:'发布时间',
    state: '发布状态',
    selectReleaseTime:'请选择发布时间',
    videoTopic:'视频主题',
    videoTopicRequired:'视频主题不能为空',
    selectVideo:'请选择视频',
    topicCount:'主题数量',
    executionStatus:'执行状态',
    completed:'已完成',
    failed:'失败',
    generationStatus:'生成状态',
    planTime:'方案时间',
    executionSuccess:'执行成功',
    closeSuccess:'关闭成功',
    addPlan:'添加运营方案',
    editPlan:'修改运营方案',
    confirmDeletePlan:'是否确认删除选中的运营方案？',
    topic:'主题',
    video:'视频',
    createVideoTopic:'创建视频主题',
    editVideoTopic:'修改视频主题',
    confirmDelete:'是否确认删除选中的方案？',
  },
  //商城相关
  mall: {
    tiktokZone: "账号专区",
    materialZone: "素材专区",
    accountType: "账号类型",
    materialType: "素材类型",
    accountTypeRequired: "请选择账号类型",
    businessDirection: "业务方向",
    businessDirectionRequired: "请选择业务方向",
    price: "价格",
    priceMin: "最低价",
    priceMax: "最高价",
    enterPrice: "请输入价格",
    baseInfo: "基础信息",
    productCode: "商品id",
    productName: "商品名称",
    enterProductName: "请输入商品名称",
    productDescription: "商品描述",
    enterDescription: "请输入商品描述",
    productCover: "商品封面",
    salesInfo: "销售信息",
    productPrice: "商品价格",
    strikethroughPrice: "划线价格",
    stock: "库存",
    residual: "剩余",
    productInfo: "商品信息",
    productCategory: "商品分类",
    material: "素材",
    account: "账号",
    productType: "商品类型",
    avatar: "头像",
    nickname: "昵称",
    resumeDescription: "简历描述",
    homepageLink: "主页链接",
    followers: "粉丝数",
    following: "关注数",
    likes: "收到点赞数",
    videos: "视频数",
    accountCountry: "账号国家",
    onSale: "是否上架",
    saleNow: "立即上架",
    saleLater: "暂不上架",
    codeRequired: "商品编号不能为空",
    nameRequired: "商品名称不能为空",
    descriptionRequired: "商品描述不能为空",
    coverRequired: "商品封面不能为空",
    priceRequired: "商品价格不能为空",
    strikethroughPriceRequired: "划线价格不能为空",
    stockRequired: "请输入真实库存",
    selectCategory: "请选择商品分类",
    selectMaterial: "请选择素材",
    accountRequired: "账号不能为空",
    selectSaleStatus: "请选择是否上架",
    materialDuration: "素材时长",
    putOnSale: "上架",
    removeFromSale: "下架",
    saleSuccess: "上架成功",
    removeSuccess: "下架成功",
    confirmDeleteProduct: "是否删除选中商品？",
    email: "邮箱",
    emailNote: "由于您购买的素材中包含账号，需要填写您的邮箱，购买成功后，账号信息会发送到您的邮箱中。",
    addToCart: "加入购物车",
    addedToCart: "已加入购物车",
    buyNow: "立即购买",
    discountPrice: "优惠价",
    originalPrice: "原价",
    addToCartSuccess: "加入购物车成功",
    orderNumber: "订单号",
    enterOrderNumber: "请输入订单号",
    incomeType: "收支类型",
    transactionStatus: "交易状态",
    quantity: "数量",
    paidAmount: "实付金额",
    orderStatus: "订单状态",
    paymentMethod: "支付方式",
    wechat: "微信",
    cancelOrder: "取消订单",
    goToPay: "去付款",
    details: "详情",
    confirmDeleteOrder: "是否确认删除选中订单？",
    cancelSuccess: "取消成功",
    orderTime: "下单时间",
    payTime: "付款时间",
    pay: "支付",
    transactionNumber: "交易单号",
    paymentAmount: "支付金额",
    amount: "金额",
    accountAmount: "账户余额",
    wechatScan: "请使用微信扫码支付",
    remainingTime: "剩余支付时间",
    paymentSuccess: "支付成功",
    paymentCancel: "支付取消",
    paymentFail: "支付失败",
    paymentOvertime: "支付超时",
    searchNameOrDesc: "商品名称/描述",
    checkout: "结算",
    unitPrice: "单价",
    selectProduct: "请选择商品",
    billNumber: "账单编号",
    enterBillNumber: "请输入账单编号",
    failure: "失败",
    success: "成功",
    pendingPayment: '待收款',
    withdrawable: '可提现',
    pointInfo:'在商品详情页商品名称下面展示的卖点信息，建议60字以内',
    sortOrder:'对发布的商品进行排序，排序号越小越靠前',
    salePrice:'该价格为商品的成交价',
    strikethroughPriceTip:'划线价格为原价，可与商品价格形成对比，更容易出售',
    productDescriptionTip:'用来描述商品的详细属性，比如账号的基本画像包括姓名、性别、简介、业务方向、地域、粉丝量；如果是素材则可以介绍素材的营销方向，此前引用后生成的数据等等。',
    productDetails:'商品详情',
    detailRequired:'商品详情不能为空',
    sellerInformation:'卖家信息',
    buyInformation:'买家信息',
  },
  profile: {
    accountCollaboration: '选择用户',
    selectCollaborationID: '选择已有用户',
    createCollaboration: '新建用户身份',
    boundSocialMedia: '已绑定社媒',
    availableSocialMedia: '可绑定社媒',
    directCreate: '直接创建',
    confirmSelection: '下一步',
    pleaseSelectID: '请选择用户',
    confirmDelete: '是否确认删除该项数据？',
    topHeader: '用户名',
    enterTip: '请输入用户名',
    enterTipAllowNone: '请输入用户名，若不填，则随机生成',
    loading: '加载中...',
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  settings: {
    systemLayout:'系统布局配置',
    themeColor: '主题色',
    languageSucceed: '设置语言成功',
    layoutSize: '布局大小',
    personalCenter: '个人中心',
    layoutSetting: '布局设置',
    logout: '退出登录',
    tipLogout: '确定注销并退出系统吗？',
    themeStyle:'主题风格设置',
    themeColorShort:'主题颜色',
    systemLanguage:'系统语言',
    open:'开启',
    fixed:'固定',
    show:'显示',
    dynamicTitle:'动态标题',
    saveConfig:'保存配置',
    resetConfig:'重置配置',
    savingToLocal:'正在保存到本地，请稍候...',
    clearingCache:'正在清除设置缓存并刷新，请稍候...',
  },
  user:{
    userInfo:'个人信息',
    userName:'账号名称',
    mobilePhone:'手机号码',
    userEmail:'用户邮箱',
    userRole:'所属角色',
    creationDate:'创建日期',
    basicInfo:'基本资料',
    changePassword:'修改密码',
    oldPassword:'旧密码',
    oldPasswordRequired:'请输入旧密码',
    newPassword:'新密码',
    newPasswordRequired:'请输入新密码',
    confirmPassword:'确认密码',
    confirmPasswordRequired:'请确认新密码',
    passwordsDoNotMatch:'两次输入的密码不一致',
    oldPasswordCannotBeEmpty:'旧密码不能为空',
    newPasswordCannotBeEmpty:'新密码不能为空',
    passwordPolicy:'密码长度8-18位,必须由大写字母，小写字母，数字，特殊符号组成',
    confirmPasswordCannotBeEmpty:'确认密码不能为空',
    updateSuccess:'修改成功',
  },
  userInfo:{
    nickName:'用户昵称',
    tipName:'用户昵称不能为空',
    phonenumber:'手机号码',
    tipPhone:'手机号码不能为空',
    tipCorrectPhone:'请输入正确的手机号码',
    email:'邮箱',
    tipEmail:'邮箱地址不能为空',
    tipCorrectEmail:'请输入正确的邮箱地址',
    tipPhoneEmail:'手机号码和邮箱地址至少填写一个',
    sex:'性别',
    male:'男',
    female:'女',
  },
  //操作引导
  guidance: {
    guide: '操作引导',
    txtToVidWelcome: 'Hi，欢迎来到InfoPub-文生视频模块！',
    txtToVidInstructions: '在左输入文案、选择模板等进行视频生成。如需帮助可查看下方新手教程',
    vidEditIntro: 'Hi，欢迎来到InfoPub-视频混剪模块！',
    vidEditInstructions: '完善视频设置、素材场景等进行视频生成。如需帮助可查看下方新手教程',
  },
  search:'搜索',
  reset:'重置',
  remove:'删除',
  operate:'操作',
  cancel:'取消',
  save:'保存',
  confirm:'确定',
  sure:'确认',
  rename:'重命名',
  add:'新增',
  edit:'编辑',
  update:'修改',
  id:'编号',
  confirmSelection:'确认选择',
  checkAll:'全选',
  view:'查看',
  play:'播放',
  copyLink:'复制链接',
  back:'返回',
  quota:'任务配额',
  yes:'是',
  no:'否',
  expansion:'展开',
  collapse:'收起',
  change:'更换',
  select:'选择',
  export:'导出',
  detail:'详情',
  refresh: '刷新',
  sort: '排序',
  skip: '跳过',
  submit: '提交',
  all:'全部',

  status:{
    status:'状态',
    initiate:'启用',
    disable:'停用',
  },

  time:{
    createTime:'创建时间',
    startDate:'开始日期',
    endingDate:'结束日期',
  },

  tip:{
    tip:'提示',
    input:'请输入',
    select:'请选择',
    update:'修改成功',
    add:'新增成功',
    remove:'删除成功',
    submit:'提交成功',
    infoPrompt:'检测到您还没有完善信息，请点击“去完善”提交您的基本信息。您也可以随时点击右上角头像，在个人中心入口编辑您的资料信息。',
    completeNow:'去完善',
    later:'以后再说',
    roleNameInput: '请输入用户角色'
  },
  publicationStream: {
    title: '发布流',
    plays: '播放数',
    Likes: '点赞数',
    comments: '评论数',
    forwarding: '转发数',
    lookAllComments: '查看全部评论',
    close: '收起',
    saySomething: '说点什么吧',
    comment: '评论',
    reply: '回复',
  },
  messageCenter: {
    title: '消息列表',
    account: '账号',
    placeholder: '请输入',
    refreshMessage: '刷新消息',
  }
}
