<template>
  <el-dialog
    class="publicDialog"
    :visible.sync="folderDialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :show-close="false"
    >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">新建文件夹</div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left" label-width="100px">
      <el-form-item :label="$t('file.folderName')" prop="input">
        <el-input v-model="form.input"  style="width:100%;"/>
      </el-form-item>
      <el-form-item :label="$t('file.folderLabel')" prop="label">
        <el-select
          ref="searchSelect"
          v-model="form.labels"
          style="width:100%;"
          allow-create
          filterable
          multiple
          :placeholder="$t('label.tipSelect')"
          @input="handleInput"
        >
          <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancelBtn" @click="close">{{$t('cancel')}}</el-button>
      <el-button class="submitBtn" :loading="buttonLoading" type="primary" @click="handleConfirm('ruleForm')">{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {addMaterialType} from '../../api/ai/materialType';
export default {
  props: {
    folderDialogVisible: {
      type: Boolean,
      required: true
    },
    labelList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      buttonLoading: false,
      form: {
        input: '',
        labels: []
      },
      rules: {
        input: [{ required: true, message: this.$t('tip.input'), trigger: 'blur' }]
      }
    };
  },
  methods: {
    handleInput(value) {
      if (value != null) {
       var label = value[value.length - 1]
        if(label != null && label.length > 20){
           value[value.length - 1] = label.substring(0, 20)
        }
      }
    },
    handleConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.buttonLoading = true
          addMaterialType({
            name: this.form.input,
            parentId: 0,
            label:this.form.labels.join(',')
          }).then(response => {
            this.$emit('submitAddFile')
            this.close()
          }).finally(() => {
            this.buttonLoading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close(){
      this.$emit('close');
    },
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
