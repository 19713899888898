var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu flex-row-center" },
        [
          _vm.device !== "mobile" ? void 0 : _vm._e(),
          _c(
            "el-badge",
            {
              staticClass: "item",
              attrs: {
                value: _vm.currentUploadingFiles.length,
                max: 99,
                hidden: _vm.currentUploadingFiles.length === 0,
              },
            },
            [
              _c("el-tooltip", { attrs: { content: "传输列表" } }, [
                _c(
                  "div",
                  {
                    staticClass: "circle-button flex-center",
                    on: { click: _vm.showUploadProgress },
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-sort",
                      staticStyle: { color: "#0156FF" },
                    }),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper ml10" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar },
                }),
                _c("span", { staticClass: "ml5 mr5 font12" }, [
                  _vm._v(_vm._s(_vm.nickName)),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/user/profile" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("settings.personalCenter"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.setting = true
                        },
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.layoutSetting"))),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("settings.logout")))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.progressDialogVisible, "append-to-body": true },
          on: {
            "update:visible": function ($event) {
              _vm.progressDialogVisible = $event
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              "上传完成（" +
                _vm._s(_vm.uploadedCount) +
                "/" +
                _vm._s(_vm.totalFiles) +
                "）"
            ),
          ]),
          _c("p", { staticClass: "color-gray" }, [
            _vm._v("-仅展示本次上传进度-"),
          ]),
          _c(
            "div",
            { staticStyle: { "max-height": "300px", "overflow-y": "auto" } },
            _vm._l(_vm.fileList, function (file, index) {
              return _c("div", { key: file.uid }, [
                _c("div", [_vm._v(_vm._s(file.name))]),
                _c(
                  "div",
                  { staticClass: "flexIcon" },
                  [
                    _c("el-progress", {
                      attrs: {
                        status: _vm.progressList[index].status
                          ? _vm.progressList[index].status
                          : undefined,
                        "stroke-width": 10,
                        percentage: _vm.progressList[index].percentage || 0,
                      },
                    }),
                    _vm.progressList[index].status === "exception"
                      ? _c("i", {
                          staticClass: "el-icon-warning-outline",
                          staticStyle: { color: "red" },
                        })
                      : _vm._e(),
                    _vm.progressList[index].status === "success"
                      ? _c("i", {
                          staticClass: "el-icon-circle-check",
                          staticStyle: { color: "green" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.progressDialogVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }