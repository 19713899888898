<!-- 先选择文件，后上传 -->
<template>
  <div v-loading="loading">
    <el-upload
      ref="upload"
      action=""
      multiple
      directory
      list-type="text"
      :auto-upload="false"
      :limit="limit"
      :file-list="fileList"
      :show-file-list="true"
      :on-change="handleFileChange"
      :on-remove="handleFileRemove"
      :on-exceed="handleFileExceed"
      :on-preview="handleCardPreview"
      :class="{hide: this.fileList.length >= this.limit}"
    >
    <!-- drag -->
    <el-button size="small" type="primary">{{ computedButtonText }}</el-button>
    <!-- <i class="el-icon-upload"></i>
    <div class="el-upload__text">将文件拖到此处，或<em>{{$t('file.clickUpload')}}</em></div> -->
    <div class="el-upload__tip" slot="tip" v-if="showTip">
      {{$t('file.pleaseUpload')}}
      <template v-if="fileSize"> {{$t('file.sizeLimit')}} <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
      <template v-if="fileType"> {{$t('file.formatLimit')}} <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
      {{$t('file.dFile')}}
    </div>
    </el-upload>

    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('file.filePreview')"
      width="800"
      append-to-body
    >
      <img
        v-if="isImagePreview"
        :src="dialogMediaUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
      <video
        v-else
        :src="dialogMediaUrl"
        controls
        style="display: block; max-width: 100%; margin: 0 auto"
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";

export default {
  props: {
    value: [String, Object, Array],
    // 媒体数量限制
    limit: {
      type: Number,
      default: 5,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 500,
    },
    // 文件类型, 例如["png", "jpg", "jpeg", "mp4", "avi", "mkv","mp3","m4a", "wav", "aac"],
    //"png", "jpg", "jpeg"——图片
    //"mp4", "avi", "mkv"——视频
    //"mp3", "wav", "aac","m4a"——音频
    // "png", "jpg", "jpeg", "bmp","tiff","tif","gif","webp",
    // "mp4", "avi", "mkv","webm", "mpeg", "asf","mov","wmv","3gp","rm","rmvb","flv","f4v",
    // "mp3","m4a","wma","aac","wav","ra","ogg","ape","flac"
    fileType: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "webp",
      "mp4", "avi", "mkv", "mov",
      "mp3", "aac", "wav","m4a"],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      number: 0,
      uploadList: [],
      dialogMediaUrl: "",
      dialogVisible: false,
      isImagePreview: true,
      hideUpload: false,
      fileList: [],
      loading: false
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          // 首先将值转为数组
          const list = Array.isArray(val) ? val : this.value.split(',');
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            if (typeof item === "string") {
              item = { name: item, url: item };
            }
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
    computedButtonText() {
      return this.buttonText || this.$t('file.tipSelect');
    },
  },
  methods: {
    // 上传前loading加载
    handleBeforeUpload(file) {
      let isValidFile = false;
      if (this.fileType.length) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        isValidFile = this.fileType.some(type => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
      } else {
        isValidFile = file.type.indexOf("image") > -1 || file.type.indexOf("video") > -1;
      }

      if (!isValidFile) {
        this.$modal.msgError(`${this.$t('file.invalidFormat1')}${this.fileType.join("/")}${this.$t('file.invalidFormat2')}`);
        return false;
      }
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(`${this.$t('file.sizeExceed')} ${this.fileSize} MB!`);
          return false;
        }
      }
      this.loading = true
      // this.$modal.loading(this.$t('file.uploading'));
      this.number++;
    },
     // 文件格式校验
     validateFileFormat(file) {
      const extension = file.name.split('.').pop().toLowerCase(); // 获取文件扩展名
      return this.fileType.includes(extension); // 判断文件是否在允许的格式中
    },
    // 文件个数超出
    handleFileExceed() {
      this.$modal.msgError(`${this.$t('file.quantityExceed')} ${this.limit} `);
    },
    // 删除文件
    handleDelete(file) {
      const findex = this.fileList.map(f => f.name).indexOf(file.name);
      if (findex > -1) {
        this.fileList.splice(findex, 1);
        this.$emit("input", this.listToString(this.fileList));
      }
    },
    // 文件改变时处理
    handleFileChange(file, fileList) {
      // this.fileList = fileList;  // 更新选中的文件列表
      // this.$emit("input", this.fileList);
      // 遍历检查文件格式
      const isValid = this.validateFileFormat(file);

      if (!isValid) {
        // 文件格式不符合，移除该文件
        this.$refs.upload.handleRemove(file);
        this.$message.error(`不支持的文件格式: ${file.name}`);
      } else {
        // 文件格式符合，更新文件列表
        this.fileList = fileList;
        this.$emit("input", this.fileList);
      }
    },
    // 删除文件
    handleFileRemove(file, fileList) {
      this.fileList = fileList;  // 更新选中的文件列表
      this.$emit("input", this.fileList);
    },
    // 上传结束处理
    uploadedSuccessfully() {
      if (this.number > 0 && this.uploadList.length === this.number) {
        this.fileList = this.fileList.concat(this.uploadList);
        this.uploadList = [];
        this.number = 0;
        this.$emit("input", this.fileList);
      }
    },
    // 预览
    handleCardPreview(file) {
      this.dialogMediaUrl = file.url;
      this.isImagePreview = file.url.match(/\.(jpeg|jpg|png)$/) != null;
      this.dialogVisible = true;
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        if (list[i].url) {
          strs += list[i].url.replace(this.baseUrl, "") + separator;
        }
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>
<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
::v-deep.hide .el-upload--picture-card {
    display: none;
}
// 去掉动画效果
::v-deep .el-list-enter-active,
::v-deep .el-list-leave-active {
    transition: all 0s;
}

::v-deep .el-list-enter, .el-list-leave-active {
  opacity: 0;
  transform: translateY(0);
}
::v-deep .el-upload__tip{
	line-height: 1.5;
  overflow-wrap: break-word; /* 允许在单词间断行 */
  word-wrap: break-word; /* 兼容旧版浏览器 */
  overflow: hidden; /* 隐藏超出部分 */
}
</style>
