<template>
  <div class="list-selector">
    <div class="topTitle">
      <div>{{ showSelect ? message.text : '选择社交帐号'  }}</div>
      <el-input v-if="showSelect" placeholder="请输入要搜索的账号" v-model="searchValue" class="input-with-select">
        <el-select v-model="platform" slot="prepend" placeholder="请选择" style="width: 100px;">
          <el-option label="全部平台" value=""></el-option>
          <el-option v-for="dict in dict.type.social_platform" :key="dict.value" :label="dict.label"
            :value="dict.value" />
          <!-- <el-option label="youtube" value="youtube"></el-option>
          <el-option label="tiktok" value="tiktok"></el-option>
          <el-option label="facebook" value="facebook"></el-option>
          <el-option label="linkedin" value="linkedin"></el-option>
          <el-option label="twitter" value="twitter"></el-option>
          <el-option label="instagram" value="instagram"></el-option>
          <el-option label="reddit" value="reddit"></el-option>
          <el-option label="google" value="gmb"></el-option>
          <el-option label="pinterest" value="pinterest"></el-option>
          <el-option label="telegram" value="telegram"></el-option> -->
        </el-select>
        <i slot="append" class="iconfont icon-sousuotubiao" @click="searchAccount"></i>
      </el-input>
    </div>
    <div class="mediaList" v-if="showSelect">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in socialPlatformList" :key="index" :name="item.name">
          <div slot="label" class="tabItem">
            <i :class="['iconfont', item.icon]"></i>
            <span v-if="activeName === item.name" class="mediaLabel">{{ item.label }}</span>
          </div>
          <div class="tabContent" v-loading="loading">
            <div class="boxItem" v-for="item in options" :key="item.name">
              <div class="leftTitle">
                <el-avatar :size="28" :src="item.userImage"></el-avatar>
                <div class="mediaName">{{ item.username }}</div>
              </div>
              <div
                :class="['selectable-button', { selected: item.isSelected }]"
                @click="toggleSelect(item.id)"
              >
                <div v-if="item.isSelected" class="checkmark"><i class="iconfont icon-dagou"></i></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="selectList" v-if="filterSelectList().length">
      <div class="selectNum">
        已选账号: <span>{{ filterSelectList().length }}</span>
      </div>
      <div class="selectListContent">
        <div class="boxItem" v-for="(item, index) in filterSelectList()" :key="index">
          <div class="leftTitle">
            <el-avatar :size="28" :src="item.userImage"></el-avatar>
            <div class="mediaName">{{ item.username }}(<i :class="['iconfont', filterIcon(item.platform)]"></i>)</div>
            <div class="cancelBox" @click="toggleSelect(item.id)">x</div>
          </div>
        </div>
      </div>
    </div>
    <div class="selectList" v-if="submitList.length">
      <div class="selectNum">
        已选账号: <span>{{ submitList.length }}</span>
      </div>
      <div class="selectListContent">
        <div class="boxItem" v-for="(item, index) in submitList" :key="index">
          <div class="leftTitle">
            <el-avatar :size="28" :src="item.userImage"></el-avatar>
            <div class="mediaName">{{ item.username }}(<i :class="['iconfont', filterIcon(item.platform)]"></i>)</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <el-checkbox-group v-if="message.multiple" v-model="selected" :disabled="message.completed" class="checkbox-group">
      <el-checkbox v-for="item in message.options" :label="item.id" :key="item.id" >
        <div class="flex-row-center">
          <el-avatar :src="item.avatar" size="small" style="margin-right: 10px;flex-shrink: 0;"></el-avatar>
          <span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ item.username }}</span>
        </div>
      </el-checkbox>
    </el-checkbox-group> -->
    <!-- <el-radio-group v-else v-model="selected" :disabled="message.completed" class="checkbox-group">
      <el-radio v-for="item in message.options" :label="item.id" :key="item.id" class="flex-row-center">
        <el-avatar :src="item.avatar" size="small" style="margin-right:10px;flex-shrink: 0;"></el-avatar>
        {{ item.username }}</el-radio>
    </el-radio-group> -->
    <div class="flexCenter">
      <el-button class="gradientBtn" type="primary" v-if="!message.completed" @click="submit">{{$t('ai.sure')}}</el-button>
    </div>
  </div>
</template>

<script>
import EventBus from '../../utils/EventBus';
import { listAllAccount } from '../../api/account/tiktok';
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  dicts: ['social_platform'],
  data() {
    return {
      selected: this.message.selected||[],
      showSelect: true,
      platform: '',
      searchValue: '',
      activeName: 'all',
      isSelected: false, // 初始状态为未选中
      // socialPlatformList: [
      //   {
      //     name: 'all',
      //     label: '全部平台',
      //     icon: 'icon-quanbuzhanghao'
      //   },
      //   {
      //     name: 'youtube',
      //     label: 'youtube',
      //     icon: 'icon-youtube-normal'
      //   },
      //   {
      //     name: 'tiktok',
      //     label: 'tiktok',
      //     icon: 'icon-tiktok-normal'
      //   },
      //   {
      //     name: 'facebook',
      //     label: 'facebook',
      //     icon: 'icon-facebook-normal'
      //   },
      //   {
      //     name: 'linkedin',
      //     label: 'linkedin',
      //     icon: 'icon-linkedin-normal'
      //   },
      //   {
      //     name: 'twitter',
      //     label: 'twitter',
      //     icon: 'icon-twitter-normal'
      //   },
      //   {
      //     name: 'instagram',
      //     label: 'instagram',
      //     icon: 'icon-instagram-normal'
      //   },
      //   {
      //     name: 'reddit',
      //     label: 'reddit',
      //     icon: 'icon-reddit-normal'
      //   },
      //   {
      //     name: 'gmb',
      //     label: 'google',
      //     icon: 'icon-gmb-normal'
      //   },
      //   {
      //     name: 'pinterest',
      //     label: 'pinterest',
      //     icon: 'icon-pinterest-normal'
      //   },
      //   {
      //     name: 'telegram',
      //     label: 'telegram',
      //     icon: 'icon-telegram-normal'
      //   },
      // ],
      options: [],
      oldOptions: [],
      submitList: [],
      loading: false
    };
  },
  computed: {
    socialPlatformList(){
      const platformList = this.dict.type.social_platform.map(type => ({
        name: type.value,
        label: type.label,
        icon: 'icon-' + type.value + '-normal'
      }));
      platformList.unshift({
        name: 'all',
        label: '全部平台',
        icon: 'icon-quanbuzhanghao'
      });
      return platformList;
    },
  },
  watch: {
    message: {
      handler(newVal) {
        this.options = JSON.parse(JSON.stringify(newVal.options))
        this.oldOptions = JSON.parse(JSON.stringify(newVal.options))
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submit() {
      if(this.filterSelectList().length === 0) {
        this.$modal.msgError(this.$t('ai.socialChooseFirst'));
      } else {
        this.submitList = this.filterSelectList()
        const newIdList = this.filterSelectList().map(item => item.id)
        this.$emit('submit', JSON.stringify(newIdList));
        this.showSelect = false
      }
    },
    toggleSelect(id) {
      this.oldOptions.forEach(item => {
        if (item.id === id) {
          item.isSelected = !item.isSelected
        }
      })
      this.options.forEach(item => {
        if (item.id === id) {
          item.isSelected = !item.isSelected; // 切换选中状态
        }
      })
    },
    filterSelectList() {
      if (this.oldOptions.length) {
        return  this.oldOptions.filter(item => item.isSelected === true)
      } else {
        return false
      }
    },
    filterIcon(name) {
      let icon;
      switch (name) {
        case 'youtube':
          icon = 'icon-youtube-normal'
          break;
        case 'tiktok':
          icon = 'icon-tiktok-normal'
          break;
        case 'facebook':
          icon = 'icon-facebook-normal'
          break;
        case 'linkedin':
          icon = 'icon-linkedin-normal'
          break;
        case 'twitter':
          icon = 'icon-twitter-normal'
          break;
        case 'instagram':
          icon = 'icon-instagram-normal'
          break;
        case 'reddit':
          icon = 'icon-reddit-normal'
          break;
        case 'gmb':
          icon = 'icon-gmb-normal'
          break;
        case 'pinterest':
          icon = 'icon-pinterest-normal'
          break;
        case 'telegram':
          icon = 'icon-telegram-normal'
          break;
        default:
          icon = 'icon-quanbuzhanghao'
          break;
      }
      return icon
    },
    handleClick(tab, event) {
      this.getList({platform: tab.name === 'all' ? '' : tab.name})
      // EventBus.$emit('search-media', {platform: tab.name === 'all' ? '' : tab.name, selectList: this.filterSelectList().length ? this.filterSelectList() : '' })
    },
    searchAccount() {
      this.getList({platform: this.platform, searchValue: this.searchValue})
      // EventBus.$emit('search-mediaAccount', {platform: this.platform, searchValue: this.searchValue, selectList: this.filterSelectList().length && this.filterSelectList()})
    },
    getList(searchObj) {
      this.loading = true
      listAllAccount(searchObj).then(response => {
          const newList = response.data.map(item => {
            if (this.filterSelectList().length) {
              return {...item, isSelected: this.filterSelectList().some(selectItem => selectItem.id === item.id)}
            } else {
              return {...item, isSelected: false}
            }
          });
          this.options = newList
      }).finally(e => {
        this.loading = false
      })
    }
  }
};
</script>

<style lang="scss">
.list-selector {
  padding: 10px 10px 16px 10px;
  background: #E7EEF5;
  border-radius: 10px;
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-group .el-checkbox {
  width: calc(50% - 10px);/* 每行两个 */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.checkbox-group .el-checkbox:nth-child(2n) {
  margin-right: 0;
}

.flexCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.topTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  .input-with-select {
    width: 400px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #DCDCDC;
    .el-input-group__append, .el-input-group__prepend {
      background: #FFFFFF;
      border-radius: 8px;
      border: none;
    }
  }
  .el-input-group > .el-input__inner {
      border-top: none;
      border-bottom: none;
      border-right: none;
  }
  .icon-sousuotubiao {
    cursor: pointer;
    font-size: 20px;
    background: linear-gradient( 90deg, #0089FF 0%, #6E16D1 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.mediaList {
  height: 420px;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
  border-radius: 12px;
  border: 1px solid #E5E7EB;
  .el-tabs__nav-scroll {
    padding: 0 18px;
  }
  .tabItem {
    display: flex;
    align-items: center;
    .mediaLabel {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: rgba(0,0,0,0.9);
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-left: 8px;
    }
    .iconfont {
      font-size: 15px;
    }
  }
  .tabContent {
    padding: 0 8px 20px 8px;
    display: flex;
    flex-wrap: wrap;         /* 允许内容换行 */
    flex-direction: column;  /* 从上到下排列 */
    height: 350px;           /* 容器高度，控制列的高度 */
    width: 100%;             /* 容器宽度 */
    overflow-x: auto;        /* 横向滚动条 */
    .boxItem {
      width: 180px;           /* 小盒子宽度 */
      height: 30px;          /* 小盒子高度 */
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 40px;
      .leftTitle {
        width: 160px;
        display: flex;
        align-items: center;
        .mediaName {
          width: 125px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .selectable-button {
        width: 14px;
        height: 14px;
        background: #FFFFFF;
        border: 1px solid #bbbaba;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color 0.3s, border 0.3s;
      }

      .selectable-button.selected {
        background-color: #016FEE; /* 选中状态的背景色 */
        border: 1px solid #016FEE; /* 选中状态的边框 */
      }

      .checkmark {
        color: white;
        .icon-dagou {
          font-size: 10px;
        }
      }
    }

  }
}

.icon-quanbuzhanghao {
  color: #0156FF !important;
}
.icon-youtube-normal {
  color: #DB0200;
}
.icon-tiktok-normal {
  background: #333333;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-facebook-normal {
  color: #1A77F3;
}
.icon-linkedin-normal {
  color: #0474B3;
}
.icon-twitter-normal {
  color: #004DA5;
}
.icon-instagram-normal {
  background: linear-gradient( 221deg, #D82A81 0%, #FFCB5C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.icon-reddit-normal {
  font-size: 19px;
  color: #FF4500;
}
.icon-gmb-normal {
  color: #0096FF;
}
.icon-pinterest-normal {
  color: #CC1F28;
}
.icon-telegram-normal {
  color: #0072FF;
}

.selectList {
  background: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #E5E7EB;
  margin-top: 8px;
  padding: 10px 8px;
  .selectNum {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #293951;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 10px 5px;
    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #0156FF;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
  }

  .selectListContent {
    display: flex;
    flex-wrap: wrap;
    .boxItem {
      // width: 180px;           /* 小盒子宽度 */
      height: 30px;          /* 小盒子高度 */
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 24px;
      .leftTitle {
        // width: 160px;
        display: flex;
        align-items: center;
        position: relative;
        .mediaName {
          // width: 125px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .cancelBox {
          position: absolute;
          right: -5px;
          top: -2px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          color: #fff;
          background: #BDBDBD;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
      .iconfont {
        font-size: 12px;
      }
    }
  }

}
</style>
