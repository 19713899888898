<template>
  <div class="scroll-list" ref="scrollList">
    <vue-seamless-scroll
      ref="scrollDom"
      :data="communityList"
      class="scroll"
      :class-option="defaultOption"
      v-if="!nowList"
    >
    <transition-group name="bounce" tag="div">
      <div
        v-for="(item, index) in communityList"
        :key="index"
        class="flexItem"
        style="min-height: 80px; height: 80px; border-bottom: 1px solid #DFDFDF;padding: 14px 0;"
      >
          <img
            :src="require(`@/assets/images/head/头像${item.avatar}.png`)"
            style="width: 32px; height: 32px; margin-right: 10px;"
          />
          <div class="content">
            <!-- <div class="tag"><i class="iconfont icon-gongzuotai2"></i>{{ timeAgo(item.createTime) }}</div> -->
            <div class="tag"><i class="iconfont icon-gongzuotai2"></i>{{ item.time }}</div>
            <span class="community-content"><i :class="['iconfont', filterIcon(item.platform)]"></i>{{ item.platform }}</span>
            <span class="community-content" style="color: #016FEE;">&nbsp;&nbsp;{{ item.name }}</span>
            <span class="community-content">{{ item.content1 }}</span>
            <span class="community-content">{{ item.content2 }}</span>
          </div>
      </div>
    </transition-group>
    </vue-seamless-scroll>
    <vue-seamless-scroll
      ref="scrollDom"
      :data="communityList"
      class="scroll"
      :class-option="defaultOption"
      v-else
    >
    <transition-group name="bounce" tag="div">
      <div
        v-for="(item, index) in communityList"
        :key="index"
        class="flexItem"
        style="min-height: 80px; height: 80px; border-bottom: 1px solid #DFDFDF;padding: 14px 0;"
      >
          <img
            :src="require(`@/assets/images/head/头像${item.avatar}.png`)"
            style="width: 32px; height: 32px; margin-right: 10px;"
          />
          <div class="content">
            <!-- <div class="tag"><i class="iconfont icon-gongzuotai2"></i>{{ timeAgo(item.createTime) }}</div> -->
            <div class="tag"><i class="iconfont icon-gongzuotai2"></i>{{ item.time }}</div>
            <span class="community-content"><i :class="['iconfont', filterIcon(item.platform)]"></i>{{ item.platform }}</span>
            <span class="community-content" style="color: #016FEE;">&nbsp;&nbsp;{{ item.name }}</span>
            <span class="community-content">{{ item.content1 }}</span>
            <span class="community-content">{{ item.content2 }}</span>
          </div>
      </div>
    </transition-group>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
import { getCommunity } from "@/api/workbench";
import { timeAgo } from '@/utils/index';

export default {
  name: "scroll-list",
  components: {
    vueSeamlessScroll
  },
  props: {
    // data: {
    //   type: Array,
    //   default: () => [],
    // }
  },
  data() {
    return {
      communityList: [],
      nowList: false,
      defaultOption: {
        step: 10, // 初始步长
        limitMoveNum: 4, // 开始无缝滚动的数据量
        hoverStop: true, // 是否开启鼠标悬停停止
        direction: 0, // 0 向下 1 向上 2 向左 3 向右
        openWatch: true, // 开启数据实时监控刷新 DOM
        singleHeight: 80, // 单步运动停止的高度
        singleWidth: 0, // 单步运动停止的宽度
        waitTime: 1000 // 单步运动停止的时间
      },
      interval: null,
    };
  },
  async created() {
    await this.getCommunityList();
  },
  mounted() {
    this.startChangingStep();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async getCommunityList() {
      try {
        const {data} = await getCommunity({max: 50, sort: 'asc'})
        console.log(data);
        if (data.length) {
          const newList = data.filter(item => item.time !== '刚刚');
          const nowList = data.filter(item => item.time === '刚刚');
          this.communityList = newList;
          this.$nextTick(() => {
            // 在DOM更新后再设置为 nowList
            setTimeout(() => {
              this.communityList = [...nowList]; // 使用新数组来替换
              this.nowList = true;
              console.log(this.communityList);
            }, 2000);
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    startChangingStep() {
      const changeInterval = Math.floor(Math.random() * (8000 - 4000 + 1)) + 4000;
      this.interval = setInterval(() => {
        this.defaultOption.waitTime = parseFloat((Math.random() * (5000 - 1000) + 1000).toFixed(0));
        this.$refs.scrollDom._startMove();
      }, changeInterval);
    },
    filterIcon(name) {
      const icons = {
        'Youtube': 'icon-youtube-normal',
        'Tiktok': 'icon-tiktok-normal',
        'Facebook': 'icon-facebook-normal',
        'Linkedin': 'icon-linkedin-normal',
        'Twitter': 'icon-twitter-normal',
        'Instagram': 'icon-instagram-normal',
        'Reddit': 'icon-reddit-normal',
        'Gmb': 'icon-gmb-normal',
        'Pinterest': 'icon-pinterest-normal',
        'Telegram': 'icon-telegram-normal'
      };
      return icons[name] || '';
    }
  },
};
</script>

<style lang="scss">
.scroll-list {
  height: 500px; /* 设置容器高度 */
  overflow: hidden; /* 隐藏溢出内容 */
  .community-content {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 500;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    .iconfont {
      font-size: 12px;
      margin-left: 5px;
    }
    .icon-quanbuzhanghao {
      color: #0156FF !important;
    }
    .icon-youtube-normal {
      color: #DB0200;
    }
    .icon-tiktok-normal {
      background: #333333;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .icon-facebook-normal {
      color: #1A77F3;
    }
    .icon-linkedin-normal {
      color: #0474B3;
    }
    .icon-twitter-normal {
      color: #004DA5;
    }
    .icon-instagram-normal {
      background: linear-gradient( 221deg, #D82A81 0%, #FFCB5C 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .icon-reddit-normal {
      font-size: 19px;
      color: #FF4500;
    }
    .icon-gmb-normal {
      color: #0096FF;
    }
    .icon-pinterest-normal {
      color: #CC1F28;
    }
    .icon-telegram-normal {
      color: #0072FF;
    }
  }
  .flexItem {
    display: flex;
    align-items: center;
    .content {
      .tag {
        display: inline-block;
        padding: 2px 5px;
        // height: 20px;
        // background: #5401FF;
        border: 1px solid #EC4C0C;
        // background: #d4e8fe;
        border-radius: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 10px;
        // color: #FFFFFF;
        color: #EC4C0C;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        .iconfont {
          font-size: 10px;
          margin-right: 3px;
        }
      }
    }
  }
}
</style>
