<template>
  <div class="editable-text-list">
    <p>{{ message.text }}</p>
    <div v-if="message.type === 'script'">
      <div v-for="(item, index) in contents" :key="index">
        <div class="inputItem">
          <div class="numberText">{{ (index + 1).toString().padStart(2, '0') }}</div>
          <div class="leftAutoTextarea">
            <el-input :ref="`leftInput`" @blur="focusInput('left', index)" :disabled="item.leftDisabled" resize="none" autosize type="textarea" v-model="item.title" :readonly="message.completed" @input="updateTitle(index, $event)" />
            <i class="iconfont icon-edit" @click="clickEdit('left', index)"></i>
          </div>
          <div class="autoTextarea">
            <el-input :ref="`rightInput`" @blur="focusInput('right', index)" :disabled="item.rightDisabled" resize="none" autosize type="textarea" v-model="item.description" :readonly="message.completed" @input="updateContent(index, $event)" />
            <i class="iconfont icon-edit" @click="clickEdit('right', index)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="postMessage" v-if="message.type === 'post'">
      <div class="topTextarea">
        <div class="title">文本标题:</div>
        <el-input :ref="`topInput`" @blur="focusInput('top')" :disabled="postTopDisabled" resize="none" autosize type="textarea" v-model="message.titles" :readonly="message.completed"/>
        <i class="iconfont icon-edit" @click="clickEdit('top')"></i>
      </div>
      <div class="line"></div>
      <div class="topTextarea">
        <div class="title">文本内容:</div>
        <el-input :ref="`bottomInput`" @blur="focusInput('bottom')" :disabled="postBottomDisabled" resize="none" autosize type="textarea" v-model="message.contents" :readonly="message.completed"/>
        <i class="iconfont icon-edit" @click="clickEdit('bottom')"></i>
      </div>
    </div>
    <!-- <div class="list-container">
      <div class="list">
        <h4>{{$t('ai.title')}}</h4>
        <div class="list-item">
          <el-input type="textarea" :rows="3" :value="item" :readonly="message.completed" @input="updateTitle(index, $event)" />
        </div>
      </div>
      <div class="list">
        <h4>{{$t('ai.content')}}</h4>
        <div v-for="(item, index) in message.contents" :key="index" class="list-item">
          <el-input type="textarea" :rows="3" :value="item" :readonly="message.completed" @input="updateContent(index, $event)" />
        </div>
      </div>
    </div> -->
    <div class="flexCenter">
      <el-button class="submitButton" type="primary" v-if="!message.completed" @click="submit">{{$t('ai.sure')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      postTopDisabled: true,
      postBottomDisabled: true,
      contents: this.message.type === 'script' &&  [...this.message.contents.map(item => {
        return {
          ...item,
          leftDisabled: true,
          rightDisabled: true
        }
      })]
    }
  },
  methods: {
    updateTitle(index, value) {
      // this.$set(this.message.contents, index, value);
      this.$set(this.message.contents[index], 'title', value);
    },
    updateContent(index, value) {
      this.$set(this.message.contents[index], 'description', value);
    },
    submit() {
      if(this.message.type=='script'){//脚本
        this.$emit('submitScript', this.message);
      }else if(this.message.type=='post'){//帖子
        this.$emit('submitPost', this.message);
      }else{
        this.$emit('submit', this.message);
      }
    },
    clickEdit(type, index) {
      if (type === 'left') {
        this.contents[index].leftDisabled = false
        this.$nextTick(() => {
          this.$refs.leftInput[index].$el.querySelector('textarea').focus()
        });
      } else if(type === 'right') {
        this.contents[index].rightDisabled = false
        this.$nextTick(() => {
          this.$refs.rightInput[index].$el.querySelector('textarea').focus()
        });
      } else if(type === 'top') {
        this.postTopDisabled = false
        this.$nextTick(() => {
          this.$refs.topInput.$el.querySelector('textarea').focus()
        });
      } else if(type === 'bottom') {
        this.postBottomDisabled = false
        this.$nextTick(() => {
          this.$refs.bottomInput.$el.querySelector('textarea').focus()
        });
      }
    },
    focusInput(type, index) {
      if(type === 'left') {
        this.contents[index].leftDisabled = true
      } else if(type === 'right') {
        this.contents[index].rightDisabled = true
      } else if (type === 'top') {
        this.postTopDisabled = true
      } else if (type === 'bottom') {
        this.postBottomDisabled = true
      }
    }
  }
};
</script>

<style lang="scss">
.editable-text-list {
  padding: 10px;
  background: #E7EEF5;
  border-radius: 10px;

  // .autoTextarea {
  //   overflow: hidden; /* 隐藏滚动条 */
  //   resize: none; /* 禁用手动调整大小 */
  //   min-height: 50px; /* 设置最小高度 */
  //   // height: auto; /* 设置高度为自动 */
  //   // padding: 10px; /* 可以根据需要调整内边距 */
  // }
  .inputItem {
    display: flex;
    justify-content: space-between;
    // min-height: 100px;
    align-items: stretch;
    margin-bottom: 8px;
    .numberText {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      font-family: ArialMT;
      font-size: 13px;
      background-color: #0156FF;
      color: #fff;
      line-height: 22px;
      text-align: center;
      font-style: normal;
      margin: 10px 5px 0 10px;
    }

    .el-textarea.is-disabled .el-textarea__inner {
        background-color: #fff;
        border-color: #fff;
        color: #000;
        cursor: not-allowed;
    }

    .leftAutoTextarea {
      width: 35%;
      display: flex;
      // height: 100%;
      position: relative;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
      border-radius: 12px;
      border: 1px solid #E5E7EB;
      padding-right: 40px;
      .icon-edit {
        position: absolute;
        right: 10px;
        top:50%;
	      transform:translateY(-50%);
        cursor: pointer;
      }
      .el-textarea__inner {
        height: 100%;
        background: #FFFFFF;
        // box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
        border-radius: 12px;
        border: none;
      }

    }
    .autoTextarea {
      width: 63%;
      position: relative;
      background: #FFFFFF;
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
      border-radius: 12px;
      border: 1px solid #E5E7EB;
      .el-textarea__inner {
        height: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        border: none;
        padding-right: 40px;
      }
      .icon-edit {
        position: absolute;
        right: 10px;
        top:50%;
	      transform:translateY(-50%);
        cursor: pointer;
      }
    }
  }

  .postMessage {
    // height: 102px;
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #E5E7EB;

    .el-textarea.is-disabled .el-textarea__inner {
      background-color: #fff;
      border-color: #fff;
      color: #000;
      cursor: not-allowed;
    }

    .line {
      width: 100%;
      height: 1px;
      background: #E5E7EB;
    }

    .topTextarea {
      display: flex;
      align-items: center;
      padding-left: 10px;
      position: relative;
      padding-right: 20px;
      .title {
        font-family: PingFang-SC, PingFang-SC;
        font-weight: bold;
        font-size: 14px;
        color: rgba(0,0,0,0.9);
        line-height: 22px;
        text-align: left;
        font-style: normal;
        width: 70px;
      }
      .icon-edit {
        position: absolute;
        right: 10px;
        top:50%;
	      transform:translateY(-50%);
        cursor: pointer;
      }
      .el-textarea {
          width: 100%;
      }
      .el-textarea__inner {
        height: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        border: none;
      }
    }
    // .leftAutoTextarea {
    //   width: 35%;
    //   display: flex;
    //   // height: 100%;
    //   position: relative;
    //   background: #FFFFFF;
    //   box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
    //   border-radius: 12px;
    //   border: 1px solid #E5E7EB;
    //   padding-right: 40px;
    //   .icon-edit {
    //     position: absolute;
    //     right: 10px;
    //     top:50%;
	  //     transform:translateY(-50%);
    //     cursor: pointer;
    //   }
    //   .el-textarea__inner {
    //     height: 100%;
    //     background: #FFFFFF;
    //     // box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.03);
    //     border-radius: 12px;
    //     border: none;
    //   }
    //   .numberText {
    //     width: 16px;
    //     font-family: ArialMT;
    //     font-size: 14px;
    //     color: #0156FF;
    //     line-height: 22px;
    //     text-align: left;
    //     font-style: normal;
    //     margin: 3px 0 0 10px;
    //   }
    // }
  }
}

.list-container {
  display: flex;
  gap: 10px;
}

.list {
  flex: 1;
}

.list-item {
  margin-bottom: 5px;
}

.flexCenter {
  width: 100%;
  display: flex;
  justify-content: center;
  .submitButton {
    width: 80px;
    height: 32px;
    background: linear-gradient( 90deg, #0089FF 0%, #6E16D1 100%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
  }
}
</style>
