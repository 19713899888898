<template>
  <div class="app-container" style="background: #F2F6F8;padding: 0;">
    <div class="flex-row-center mb10" style="justify-content: flex-end;">
      <el-button @click="cancel">训练记录</el-button>
      <el-button @click="cancel">删除</el-button>
      <el-button @click="cancel">保存</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm">发布</el-button>
    </div>
    <el-form v-loading="loading" ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="10">
        <el-col :span="8">
          <div class="model-bg">
            <span class="model-title">文件</span>
            <el-form-item label="模型名称" prop="modelName">
              <el-input v-model="form.modelName" placeholder="请输入模型名称" />
            </el-form-item>
            <el-form-item label="模型描述" prop="description">
              <el-input v-model="form.description" placeholder="请输入模型描述" />
            </el-form-item>
            <el-form-item label="训练模块" prop="modelType">
              <el-radio-group v-model="form.modelType">
                <el-radio v-for="dict in dict.type.training_module" :key="dict.value" :label="dict.value">
                  {{ dict.label }}
                </el-radio>
                <!-- <el-radio label="通用信息">通用信息</el-radio>
                <el-radio label="公司介绍">公司介绍</el-radio>
                <el-radio label="产品介绍">产品介绍</el-radio>
                <el-radio label="合作案例">合作案例</el-radio>
                <el-radio label="产品报价">产品报价</el-radio>
                <el-radio label="售后服务">售后服务</el-radio>
                <el-radio label="销售话术">销售话术</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item prop="fileList">
              <videoUpload
                v-model="form.fileList"
                :limit="10"
                :fileSize="50"
                :fileType="fileType"
                buttonText="添加知识库"
              />
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="model-bg flex-column">
            <span class="model-title">问答</span>
            <div class="flex-row-center mb10" style="justify-content: flex-end;">
              <el-button :loading="quesLoading" type="primary" @click="addQestion">添加问答</el-button>
            </div>
            <div v-if="isShowQuestion">
              <el-form-item label="问题" prop="problem">
                <el-input v-model="questionDetail.problem" placeholder="请输入问题" />
              </el-form-item>
              <el-form-item label="回答" prop="answer">
                <el-input v-model="questionDetail.answer" placeholder="请输入答案" />
              </el-form-item>
            </div>
            <div class="flex-row-center mb10" style="justify-content: flex-end;">
              <el-button @click="cancelQestion">取消</el-button>
              <el-button type="primary" @click="confirmQestion">确认</el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="model-bg flex-column">
            <span class="model-title">预览</span>
            <div class="messages">
              <ChatMessage
                v-for="(message, index) in messages"
                :key="index"
                :message="message"
                @submitScript="submitScript"
                @againVideo="againVideo"
                @submitVideo="submitVideo"
                @chooseVideo="chooseVideo"
                @submitAccount="submitAccount"
                @submitPost="submitPost"/>
            </div>
            <ChatInput @send="handleSend" @sendUrl="handleSendUrl" :disabled="disabled" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import VideoUpload from "../../components/VideoUpload/index.vue";
import ChatMessage from '../../components/ChatItem/ChatMessage.vue';
import ChatInput from "./chatInput.vue";
import {getModel, addModel, updateModel } from "@/api/model/model";
import { addModelDocumentSegments, updateModelDocumentSegments } from "../../api/model/modelDocumentSegments";
export default {
  name: 'AddModel',
  dicts: ['training_module'],
  components: {
    VideoUpload,
    ChatMessage,
    ChatInput
  },
  data() {
    return {
      fileType: ['txt','doc', 'docx', 'pdf'],
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        modelName: [
          { required: true, message: "请输入模型名称", trigger: "blur" }
        ],
        description: [
          { required: true, message: "请输入模型描述", trigger: "blur" }
        ],
        modelType: [
          { required: true, message: "请选择训练模块", trigger: "blur" }
        ],
      },
      modelId:null,
      loading:false,
      buttonLoading:false,
      messages: [],
      disabled: false,
      questions: [],
      questionDetail: {},   
      isShowQuestion: true, // 是否显示问答
      quesLoading: false,
    }
  },
  created() {
    this.modelId = this.$route.query.id || '';
    if (this.modelId) {
      this.isShowQuestion = false
      this.getDetail()
    }
  },
  methods: {
    // 添加问答
    addQestion(){
      if(this.isShowQuestion){
        this.$modal.msgError('请先确认当前问答');
      } else {
        this.isShowQuestion = true
      }
    },
    // 取消问答
    cancelQestion(){
      this.isShowQuestion = false
    },
    confirmQestion(){
      if(this.questionDetail.problem || this.questionDetail.answer){
        this.$modal.msgError('请输入问题和答案');
        return
      }
      this.questions.push(this.questionDetail)
      if(this.modelId){
        //有id则直接走接口
        this.quesLoading = true
        if(this.questionDetail.id){
          //有id则修改
          updateModelDocumentSegments(this.questionDetail).then(response => {
            this.$modal.msgSuccess(this.$t('tip.update'));
            this.isShowQuestion = false
            this.questionDetail = {}
          }).finally(() => {
            this.quesLoading = false;
          })
        } else {
          addModelDocumentSegments(this.questionDetail).then(response => {
            this.$modal.msgSuccess(this.$t('tip.add'));
            this.isShowQuestion = false
            this.questionDetail = {}
          }).finally(() => {
            this.quesLoading = false;
          })
        }
      } else {
        //无id则直接添加到数组
        this.isShowQuestion = false
        this.questionDetail = {}
      }
    },
    handleSend(message) {
      this.messages.push({ type: 'text', content: message ,user:'user'});
      this.disabled = true
      openAiSession({tips:message}).then(response => {
        this.sendCallBack(2, response)
      }).catch (error => {
        this.messages.push({ type: 'text', content: this.$t('ai.scriptFailed') ,time:parseTime(new Date())});
        this.disabled = false
      });
    },
    getDetail() {
      this.loading = true
      getModel(this.modelId).then(response => {
        this.form = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.buttonLoading = true;
          if (this.form.modelId != null) {
            updateModel(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.update'));
              this.$tab.closeOpenPage({path: "/modelTraining"});
            }).finally(() => {
              this.buttonLoading = false;
            });
          } else {
            addModel(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.add'));
              this.$tab.closeOpenPage({path: "/modelTraining"});
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$tab.closeOpenPage({path: "/modelTraining"});
    },
  }
}
</script>

<style scoped>
.el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-col {
  align-items: stretch; /* 让子元素在垂直方向上拉伸，保持高度一致 */
  margin-bottom: 10px;
}
.model-bg{
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  height: 80vh;
}
.model-title{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.messages {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 20px;
}
</style>
