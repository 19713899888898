var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-input" },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          autosize: { minRows: 1, maxRows: 5 },
          placeholder: _vm.disabled ? "等待回复完成" : "请描述你的问题",
          disabled: _vm.disabled,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.sendMessage($event)
          },
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary", disabled: _vm.disabled },
          on: { click: _vm.sendMessage },
        },
        [_vm._v(" " + _vm._s(_vm.$t("ai.send")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }