var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "priceTag" },
    _vm._l(_vm.shopList, function (item) {
      return _c(
        "div",
        {
          key: item.raw.dictValue,
          class: ["tag", item.select ? "selectTag" : ""],
          on: {
            click: function ($event) {
              return _vm.selectTagFun(item.raw.dictValue)
            },
          },
        },
        [
          item.raw.dictLabel.length > 4
            ? _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: item.raw.dictLabel,
                    placement: "top",
                  },
                },
                [_c("span", [_vm._v(_vm._s(item.raw.dictLabel))])]
              )
            : _c("span", [_vm._v(_vm._s(item.raw.dictLabel))]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }