<template>
  <div class="chat-input">
    <el-input
      type="textarea"
      v-model="message"
      :autosize="{ minRows: 1, maxRows: 5}"
      :placeholder="disabled ? '等待回复完成' : '请描述你的问题'"
      @keyup.enter="sendMessage"
      :disabled="disabled"
    ></el-input>
    <el-button
      type="primary"
      @click="sendMessage"
      :disabled="disabled"
    >
      {{ $t('ai.send') }}
    </el-button>

  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: '',
      mediaRecorder: null,
      audioChunks: [],
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/resource/oss/upload", // 上传文件服务器地址
      recordingTime: 0, // 录制的秒数
      recordingTimer: null, // 计时器
      isCancel: false // 是否取消录制
    };
  },
  computed: {
    // 格式化录制时间为 mm:ss 格式
    formattedRecordingTime() {
      const minutes = Math.floor(this.recordingTime / 60).toString().padStart(2, '0');
      const seconds = (this.recordingTime % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  },
  methods: {
    sendMessage() {
      if (this.message.trim()) {
        this.$emit('send', this.message);
        this.message = '';
      }
    },
  }
};
</script>

<style scoped>
.chat-input {
  display: flex;
  align-items: center;
}

.chat-input .el-input {
  flex: 1;
  margin-right: 10px;
}
</style>
