<!-- 编辑文稿 -->
<template>
  <div class="priceTag">
    <div v-for="item in shopList" :key="item.raw.dictValue" :class="['tag', item.select ? 'selectTag' : '']" @click="selectTagFun(item.raw.dictValue)">
      <el-tooltip v-if="item.raw.dictLabel.length > 4" class="item" effect="dark" :content="item.raw.dictLabel" placement="top">
        <span>{{ item.raw.dictLabel }}</span>
      </el-tooltip>
      <span v-else>{{ item.raw.dictLabel }}</span>
    </div>
  </div>
</template>
<script>


export default {
  name: 'EditManuscripts',
  components: {
  },
  props:{
    list: {
      require: true,
      default: () => []
    }
  },
  data() {
    return {
      shopList: []
    }
  },
  watch: {
    list: {
      handler(newVal) {
        this.shopList = JSON.parse(JSON.stringify(newVal)).map((item, index) => {
          if (index === 0) {
            this.$emit('selectTag', item)
          }
          return {...item, select: index === 0}
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    selectTagFun(code) {
      this.shopList.forEach(item => {
        if (item.raw.dictValue === code) {
          item.select = true
          this.$emit('selectTag', item)
        } else {
          item.select = false
        }
      })
    },
  }
}
</script>
<style lang="scss">
.priceTag {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px;
    .tag {
      cursor: pointer;
      width: 80px;
      height: 28px;
      background: #EEEEF0;
      border-radius: 8px;
      border: 1px solid #E5E7EB;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(161,161,161,0.9);
      margin: 4px;
      span {
        width: 75px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }
    .selectTag {
      background: #0156FF;
      color: #fff;
    }
}
</style>
