<template>
  <div class="material-detail-container">
    <div class="title-header">
      <div class="title-header-text">素材详情</div>
    </div>
    <div class="material-content" v-loading="loading">
      <div class="mb20">
        <img v-if="detail.fileType === 'image'" :src="detail.url" alt="image" class="image-viewer" />
        <audio v-else-if="detail.fileType=='audio'" controls :src="detail.url" class="audio-player" ref="audioPlayer"></audio>
        <video v-else-if="detail.fileType=='video'" controls :src="detail.url" class="video-player" ref="videoPlayer"></video>
      </div>
      <div class="material-title">
        {{ detail.fileType == 'text' ? detail.title :detail.name }}
      </div>
      <div class="material-content-text">
        {{ detail.fileType == 'text' ? detail.content :detail.description }}
      </div>
      <div class="mt20">
        <el-tag
          v-for="(tag, index) in detail.labels"
          :key="index"
          style="margin-right: 5px; margin-bottom: 5px;"
        >
          {{ tag }}
        </el-tag>
      </div>
      <el-descriptions
        title=""
        label-class-name="my-label"
        class="f-grow"
        :column="2"
        border
      >
        <el-descriptions-item>
          <template slot="label">
            上传时间
          </template>
          <span>
            {{ detail.createTime }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            素材格式
          </template>
          <span>
            {{ getText() }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            素材编号
          </template>
          <span>
            {{ detail.id }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { getMaterialDetail } from "../../api/ai/material";

export default {
  name: "MaterialDetail",
  data() {
    return {
      loading: false,
      detail: {},
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    getText(){
      switch(this.detail.fileType){
        case 'image':
          return '图片'
        case 'audio':
          return '音乐'
        case 'video':
          return '视频'
        case 'text':
          return '文本'
        default:
          return '其他'
      }
    },
    getDetail() {
      this.loading = true;
      getMaterialDetail(this.id)
        .then((res) => {
          this.detail = res.data;
          if (this.detail.label) {
            this.detail.labels = this.detail.label.split(",");
          } else {
            this.detail.labels = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.material-detail-container {
  margin: 15px;
}
.material-content {
  padding: 37px 195px;
  background: #ffffff;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 14px;
}
.material-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
}
.material-content-text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #71717a;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
}
.my-label {
  width: 120px;
}

.video-player {
  max-height:400px;
}
.image-viewer {
  /* max-width: 100%; */
  max-height:400px;
  display: block;
  margin: auto;
}
.audio-player {
  width: 100%;
}
</style>
