<template>
  <div class="home flex-row">
    <div class="home-left">
      <el-row :gutter="16">
        <el-col
          :span="8"
          v-for=" (item,index) in buttons"
          :key="index"
          style="display: flex; flex-direction: column;"
        >
          <TabItem
            :template="item"
            style="height: 100%; flex-grow: 1;"
          />
        </el-col>
      </el-row>
      <!-- 发布任务 -->
      <div class="task-bg" v-if="publish.length > 0">
        <div
          class="flex-row-center"
          style="min-height: 54px;"
        >
          <div class="blue-line"></div>
          <span class="home-title mr20">{{ $t('workbench.publishTask') }}</span>
          <!-- <el-radio-group
            v-model="socialType"
            class="custom-radio-group"
            @change="getPublishList"
          >
            <el-radio
              v-for="dict in dict.type.social_platform"
              :key="dict.value"
              :label="dict.value"
              class="radio-social"
            >
              <template v-slot:default>
                <i
                  class="iconfont"
                  :class="['icon-' + dict.value + '-normal', socialType==dict.value ? dict.value : 'unbind']"
                  style="margin-right: 2px;"
                ></i>
                {{ dict.label }}
              </template>
            </el-radio>
          </el-radio-group> -->
        </div>
        <el-empty
          v-if="publish.length === 0"
          v-loading="loading"
          :image-size="50"
        ></el-empty>
        <el-row
          v-else
          v-loading="loading"
          :gutter="16"
        >
          <el-col
            :span="8"
            v-for=" item in publish"
            :key="item.id"
          >
            <div class="publish-containerContent">
              <div
                class="publish-container"
                @click="goTarget('/issueManager')"
              >
                <div class="flex-row">
                  <div class="point f-shrink"></div>
                  <el-tooltip
                    effect="dark"
                    :content="item.postContent"
                    placement="top"
                  >
                    <span class="post-content f-grow">{{ item.postContent || "--" }}</span>
                  </el-tooltip>
                </div>
                <span v-if="item.status == 0">{{$t('issueManager.statusDraft')}}</span>
                <div class="flex-row-center f-between time">
                  <span v-if="item.status == 0">{{$t('issueManager.statusDraft')}}</span>
                  <span
                    v-if="item.status == 1"
                    style="color: #ED7B2F;"
                  ><i class="iconfont icon-dacha1"></i>{{ $t('issueManager.publishing') }}</span>
                  <span
                    v-if="item.status == 2"
                    style="color: #00A870;"
                  > <i class="iconfont icon-a-dacha3"></i>{{ $t('issueManager.published') }}</span>
                  <span
                    v-if="item.status == 3"
                    style="color: #E34D59;"
                  ><i class="iconfont icon-a-dacha2"></i>{{ $t('issueManager.failed') }}</span>
                  <!-- <dict-tag
                    :options="dict.type.publish_time_type"
                    :value="item.publishTimeType"
                  /> -->
                  <span>{{ item.publishTime }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 视频创作 -->
      <div class="task-bg" v-if="videos.length > 0">
        <div
          class="flex-row-center f-between"
          style="min-height: 54px;"
        >
          <div class="flex-row-center">
            <div class="blue-line"></div>
            <span class="home-title mr20">{{ $t('workbench.videoCreation') }}</span>
          </div>
          <div>
            <span class="ml5">{{ $t('workbench.videoMix') }}：{{ videoStatistics.clip }}</span>
            <span class="ml5">{{ $t('workbench.AIVideoMix') }}：{{ videoStatistics.aiClip }}</span>
            <span class="ml5">{{ $t('workbench.AIVideo') }}：{{ videoStatistics.ai }}</span>
            <el-tooltip
              class="ml10"
              effect="dark"
              :content="$t('refresh')"
              placement="top"
            >
              <el-button
                size="mini"
                circle
                icon="el-icon-refresh"
                @click="getVideoList"
              />
            </el-tooltip>
          </div>
        </div>
        <el-empty
          v-if="videos.length === 0"
          v-loading="loadingVideo"
          :image-size="50"
        ></el-empty>
        <div
          v-else
          v-loading="loadingVideo"
          class="mediaBox"
        >
          <div
            v-for=" item in videos"
            :key="item.id"
            class="video-container"
          >
            <div
              class="flexBox"
              @click="goVideoManager(item)"
            >
              <div class="flex-center video-cover f-shrink">
                <video
                  :src="item.url"
                  alt="video"
                  class="video-cover"
                />
              </div>
              <div class="ml5 parentWidth">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.taskName"
                  placement="top"
                >
                  <div class="video-title">{{ item.taskName }}</div>
                </el-tooltip>
                <div class="content">{{ $t('videoManager.length') }}：{{ item.videoTotalLength }}</div>
                <div class="flex-row-center f-between">
                  <dict-tag
                    class="time mt20"
                    :options="dict.type.issue_type"
                    :value="item.taskType"
                  />
                  <div style="width: 50px;">
                    <span v-if="item.status == 0">{{$t('videoManager.statusDraft')}}</span>
                    <el-tooltip
                      effect="dark"
                      :content="$t('workbench.generating')"
                      placement="top"
                    >
                      <i
                        v-if="item.status == 1"
                        class="el-icon-loading"
                      ></i>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      :content="$t('workbench.generateFail')"
                      placement="top"
                    >
                      <i
                        v-if="item.status == 2"
                        class="el-icon-circle-close"
                        style="color: red;"
                      ></i>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      :content="$t('workbench.generateSuccess')"
                      placement="top"
                    >
                      <i
                        v-if="item.status == 3"
                        class="el-icon-circle-check"
                        style="color: #00A0FF;"
                      ></i>
                    </el-tooltip>
                  </div>
                </div>
                <div class="timeFont">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-right">
      <!-- 账号统计 -->
      <div
        v-if="false"
        class="matrix-container mb10"
        v-loading="workLoading"
      >
        <div class="flex-row-center">
          <i
            class="iconfont icon-zhanghaoguanlibeifen mr5"
            style="color: #0156FF;font-size: 36px;"
          ></i>
          <div class="f-grow ml10">
            <div class="work-title mb5">{{ $t('workbench.accountCount') }}：{{ workStatistics.socialCount || 0 }}</div>
            <router-link
              class="link-type"
              :to="'/account'"
            >{{ $t('workbench.newAccount') }}</router-link>
          </div>
          <!-- <router-link class="link-type" :to="'/account'">查看数据总览</router-link> -->
        </div>
        <el-row
          :gutter="1"
          style="margin-top: 69px;"
        >
          <el-col :span="6">
            <div
              class="flex-column-center"
              style="border-right: 1px solid #F6F6F6;"
            >
              <span class="fans-title">{{ $t('workbench.fanCount') }}</span>
              <span class="fans-num">{{ workStatistics.fansCount || 0 }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div
              class="flex-column-center"
              style="border-right: 1px solid #F6F6F6;"
            >
              <span class="fans-title">{{ $t('workbench.workCount') }}</span>
              <span class="fans-num">{{ workStatistics.videoCount || 0 }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div
              class="flex-column-center"
              style="border-right: 1px solid #F6F6F6;"
            >
              <span class="fans-title">{{ $t('workbench.playCount') }}</span>
              <span class="fans-num">{{ workStatistics.viewCount || 0 }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="flex-column-center">
              <span class="fans-title">{{ $t('workbench.commentCount') }}</span>
              <span class="fans-num">{{ workStatistics.commentCount || 0 }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="task-bg">
        <div
          class="flex-row-center"
          style="min-height: 54px;"
        >
          <div class="blue-line"></div>
          <span class="home-title mr20">{{ $t('workbench.communityList') }}</span>
        </div>
        <div class="scroll-container">
          <vueScrolling :data="communityList" />
        </div>
      </div>
    </div>
    <CompanyInfo
      ref="companyInfo"
      :visible.sync="infoVisible"
      :user="user"
    />
  </div>
</template>

<script>
import TabItem from "./home/TabItem.vue";
import { getQuota, myMerchant } from "../api/system/merchant";
import { getAccount, userStatistics, listAccount } from "../api/account/tiktok";
import { listPublish } from "../api/ai/publish";
import { getIndexStatistics, listVideoTask } from "../api/ai/videoTask";
import CompanyInfo from "./home/CompanyInfo.vue";
import vueScrolling from "@/components/vueScrolling/index.vue";

export default {
  // name: "Index",
  dicts: ["publish_time_type", "issue_type", "social_platform"],
  components: {
    TabItem,
    CompanyInfo,
    vueScrolling
  },
  data() {
    return {
      quota: {},
      workStatistics: {},
      workLoading: false,
      buttons: [
        {
          name: this.$t("workbench.videoByText"),
          path: "/videoManager/aiCrosscutting",
          quota: "",
          use: "",
          des: this.$t("workbench.videoByTextTip"),
          hover: false,
          icon: "icon-shipin",
          bgColor: "#EAF8FA",
          color: "#35C2CF",
        },
        {
          name: this.$t("workbench.AIVideo"),
          path: "/videoManager/make_video",
          quota: "",
          use: "",
          des: this.$t("workbench.AIVideoTip"),
          hover: false,
          icon: "icon-aihunjian1",
          bgColor: "#EEF1FD",
          color: "#5978EC",
        },
        {
          name: this.$t("workbench.videoTranslation"),
          path: "/ai/videoTranslation",
          quota: "",
          use: "",
          des: this.$t("workbench.videoTranslationTip"),
          hover: false,
          icon: "icon-danyuanpeizhi",
          bgColor: "#FFEBEA",
          color: "#FF4239",
        },
        {
          name: this.$t("workbench.operationsStrategy"),
          path: "/operationStrategy",
          quota: "",
          use: "",
          des: this.$t("workbench.operationsStrategyTip"),
          hover: false,
          icon: "icon-jihua",
          bgColor: "#FFE9F9",
          color: "#FF24C4",
        },
        {
          name: this.$t("workbench.releaseCommunity"),
          path: "/infoStream",
          quota: "",
          use: "",
          des: this.$t("workbench.releaseCommunityTip"),
          hover: false,
          icon: "icon-fabuliu",
          bgColor: "#FFE9F9",
          color: "#FFA128",
        },
        {
          name: this.$t("workbench.messageInteraction"),
          path: "/message",
          quota: "",
          use: "",
          des: this.$t("workbench.messageInteractionTip"),
          hover: false,
          icon: "icon-xiaoxi",
          bgColor: "#F6EAFF",
          color: "#A735FF",
        },
      ],
      accountList: [], //账号列表
      account: "", //当前账号
      loading: false, //发布的loading
      // loadingPublishNum: false,//发布数量的loading
      // publishNum:{}, // 发布对应的数量
      publish: [],
      loadingVideo: false, //视频的loading
      videoStatistics: {}, //视频统计
      videos: [],
      sales: [
        {
          content: "专一感源于整齐划一",
          num: 1000,
          open: true,
        },
        {
          content: "好设计是诚实的",
          num: 1300,
          open: false,
        },
        {
          content: "好设计是尽可能的无设计",
          num: 3400,
          open: true,
        },
        {
          content: "割草机视频",
          num: 1234,
          open: true,
        },
        {
          content: "塑像本来我只是把不要的部分去掉",
          num: 1007,
          open: true,
        },
        {
          content: "好设计是有创意的",
          num: 1505,
          open: true,
        },
      ],
      notice: [
        {
          type: "1",
          content: "新用户优惠活动开启啦！",
          time: "2023-08-01",
        },
        {
          type: "2",
          content: "抖音账号审核不通过",
          time: "2023-07-01",
        },
        {
          type: "3",
          content: "视频生成失败",
          time: "2023-03-01",
        },
        {
          type: "1",
          content: "新用户优惠活动开启啦！",
          time: "2022-08-01",
        },
        {
          type: "2",
          content: "抖音账号审核不通过",
          time: "2022-07-01",
        },
        {
          type: "3",
          content: "视频生成失败",
          time: "2022-03-01",
        },
      ],
      help: [
        {
          url: require("@/assets/images/workbench/help_product.png"),
          name: this.$t("workbench.productSummary"),
        },
        {
          url: require("@/assets/images/workbench/help_usage.png"),
          name: this.$t("workbench.userGuide"),
        },
        {
          url: require("@/assets/images/workbench/help_package.png"),
          name: this.$t("workbench.planDescription"),
        },
        {
          url: require("@/assets/images/workbench/help_product_info.png"),
          name: this.$t("workbench.productNews"),
        },
      ],
      user: {},
      infoVisible: false,
      socialType: "tiktok",
      communityList: [
        {
          avatar: 10,
          time: '刚刚',
          name: 'Laura Garner',
          platform: 'Tiktok',
          content1: '通过平台发布一条视频到',
          content2: '《我们的割草机最新的型号问世啦！！家人们最新型号解说查看》'
        },
        {
          avatar: 3,
          time: '3分钟前',
          name: 'Daisy Delgado',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《兰香企业宣传视频》'
        },
        {
          avatar: 4,
          time: '5分钟前',
          name: 'Keith Gonzalez',
          platform: 'Instagram',
          content1: '通过平台发布一条视频到',
          content2: '《文具总动发布会V2.2》点赞突破 1w'
        },
        {
          avatar: 15,
          time: '2分钟前',
          name: 'Sara Lawson',
          platform: 'Tiktok',
          content1: '通过平台发布一条视频到',
          content2: '《创新科技家用机器人展示》'
        },
        {
          avatar: 7,
          time: '10分钟前',
          name: 'Peter Simmons',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《城市风景航拍合集》'
        },
        {
          avatar: 18,
          time: '15分钟前',
          name: 'Evelyn Fox',
          platform: 'Instagram',
          content1: '通过平台发布一条视频到',
          content2: '《宠物趣味合集》评论数突破 5k'
        },
        {
          avatar: 2,
          time: '20分钟前',
          name: 'Chris Jenkins',
          platform: 'Tiktok',
          content1: '通过平台发布一条视频到',
          content2: '《街头美食探秘》'
        },
        {
          avatar: 5,
          time: '25分钟前',
          name: 'Megan Ortiz',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《晨间瑜伽教学》'
        },
        {
          avatar: 9,
          time: '刚刚',
          name: 'JACK li',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《冬季时尚穿搭指南》'
        },
        {
          avatar: 14,
          time: '1分钟前',
          name: 'Lily Patterson',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《最受欢迎的宠物玩具展示》点赞数突破 2w'
        },
        {
          avatar: 6,
          time: '3分钟前',
          name: 'Lucas Murphy',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《美丽的山川与河流航拍》'
        },
        {
          avatar: 17,
          time: '8分钟前',
          name: 'Sophia Wells',
          platform: 'Instagram',
          content1: '通过平台发布一条视频到',
          content2: '《美食节之旅》评论数达到 1k'
        },
        {
          avatar: 12,
          time: '刚刚',
          name: 'Jackson Flores',
          platform: 'Tiktok',
          content1: '通过平台发布一条视频到',
          content2: '《DIY家庭装饰教程》'
        },
        {
          avatar: 20,
          time: '2分钟前',
          name: 'Emma Bell',
          platform: 'Youtube',
          content1: '通过平台发布一条视频到',
          content2: '《城市夜景探索》播放量突破 10w'
        },
        {
          avatar: 11,
          time: '6分钟前',
          name: 'Grace White',
          platform: 'Instagram',
          content1: '通过平台发布一条视频到',
          content2: '《自然风光摄影》'
        },
        {
          avatar: 1,
          time: '刚刚',
          name: 'Oscar Ford',
          platform: 'Tiktok',
          content1: '通过平台发布一条视频到',
          content2: '《无人机拍摄的惊艳景色》'
        }
      ],
      displayedList: [],
    };
  },
  created() {
    this.getQuota();
    this.getWorkStatistics();
    this.getAccountList();
    this.getPublishList();
    this.getVideoList();
    this.getUser();
  },
  methods: {
    goVideoManager(item) {
      if (item.taskType == 3) {
        this.goTarget("/videoManager/aiCrosscutting");
      } else {
        this.goTarget("/videoManager/videoManager");
      }
    },
    getUser() {
      myMerchant().then((response) => {
        this.user = response.data || {};
        if (
          !this.user ||
          (!this.user.industryCategory &&
            !this.user.companyName &&
            !this.user.brand &&
            !this.user.product)
        ) {
          this.$confirm(this.$t("tip.infoPrompt"), this.$t("tip.tip"), {
            confirmButtonText: this.$t("tip.completeNow"),
            cancelButtonText: this.$t("tip.later"),
            // showCancelButton: false,
            type: "warning",
            showClose: false,
            distinguishCancelAndClose: true, // 重要，设置为true才会把右上角X和取消区分开来
            closeOnClickModal: false,
          })
            .then(() => {
              this.infoVisible = true;
              this.$nextTick().then((rs) => {
                if (this.user && this.user.industryCategory) {
                  this.user.industryCategorys =
                    this.user.industryCategory.split(",");
                }
                this.$refs.companyInfo.init(this.user);
              });
            })
            .catch(() => {});
        }
      });
    },
    // 计算图标的 class，根据 today 和 yesterday 的差值返回不同图标
    getIcon(todayAddCount, yesterdayAddCount) {
      const diff = todayAddCount || 0 - yesterdayAddCount || 0;
      return diff > 0 ? "icon-up" : diff < 0 ? "icon-down" : "icon-neutral";
    },
    // 计算差值并取正数
    getDiff(todayAddCount, yesterdayAddCount) {
      return Math.abs(todayAddCount || 0 - yesterdayAddCount || 0);
    },
    getWorkStatistics() {
      this.workLoading = true;
      userStatistics()
        .then((response) => {
          this.workStatistics = response.data || {};
        })
        .finally(() => {
          this.workLoading = false;
        });
    },
    goTarget(href) {
      if (href) {
        this.$router.push({ path: href });
      } else {
        this.$modal.alert(this.$t("workbench.notOpen"));
      }
    },
    //获取配额
    getQuota() {
      getQuota().then((response) => {
        this.quota = response.data || {};
        this.buttons.forEach((button) => {
          switch (button.path) {
            case "/account":
              button.quota = this.quota.socialCount;
              button.use = this.quota.useSocialCount;
              break;
            case "/material":
              button.quota = this.quota.fileSize;
              button.use = this.quota.useFileSize;
              break;
            case "/videoManager/videoManager":
              button.quota = this.quota.videoLength;
              button.use = this.quota.useVideoLength;
              break;
            case "/issueManager":
              button.quota = this.quota.publishCount;
              button.use = this.quota.usePublishCount;
              break;
            default:
              button.quota = "";
              button.use = "";
          }
        });
      });
    },
    /** 账号列表 */
    getAccountList() {
      // listAccount().then(response => {
      //   this.accountList = response.rows;
      //   if(this.accountList.length>0){
      //     this.account = this.accountList[0].id
      //     this.getPublishList()
      //   }
      // })
    },
    getPublishList() {
      // if(!this.account){
      //   this.getAccountList()
      //   return
      // }
      this.loading = true;
      listPublish({
        pageNum: 1,
        pageSize: 3,
        // socialType: this.socialType,
        // socialId: this.account,
      })
        .then((response) => {
          this.publish = response.rows;
        })
        .finally(() => {
          this.loading = false;
        });
      // this.loadingPublishNum = true
      // getAccount(this.account).then(response => {
      //   this.publishNum = response.data || {}
      // }).finally(() => {
      //   this.loadingPublishNum = false
      // })
    },
    getVideoList() {
      this.getIndexStatistics();
      this.loadingVideo = true;
      listVideoTask({
        pageNum: 1,
        pageSize: 3,
      })
        .then((response) => {
          this.videos = response.rows;
        })
        .finally(() => {
          this.loadingVideo = false;
        });
    },
    getIndexStatistics() {
      getIndexStatistics().then((response) => {
        this.videoStatistics = response.data || {};
      });
    },
    getNoticeType(type) {
      if (type == "1") {
        return this.$t("workbench.events");
      } else if (type == "2") {
        return this.$t("workbench.messages");
      } else if (type == "3") {
        return this.$t("workbench.notifications");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home {
  padding: 20px;
  background: #f2f6f8;
}
.home-left {
  width: 70%;
}
.home-right {
  width: 30%;
  margin-left: 18px;
}
.matrix-container {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  border: 1px solid #e5e7eb;
  padding: 32px 24px;
}
.fans-title {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: center;
  font-style: normal;
}
.fans-num {
  height: 38px;
  font-family: BebasNeue;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 38px;
  text-align: left;
  font-style: normal;
  margin-top: 24px;
}
.task-bg {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  border: 1px solid #e5e7eb;
  padding: 0 16px 24px;
  margin-bottom: 16px;
}

.publish-containerContent {
  // height: 134px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #dfdfdf;
}
.custom-radio-group ::v-deep.el-radio__input {
  display: none; /* 隐藏原点 */
}
.custom-radio-group .el-radio__label {
  display: flex; /* 使内容水平排列 */
  align-items: center; /* 垂直居中 */
}
.radio-social {
  margin-right: 20px;
}
.el-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.el-col {
  align-items: stretch; /* 让子元素在垂直方向上拉伸，保持高度一致 */
  margin-bottom: 10px;
}

.hello {
  font-family: PingFang-SC-Bold;
  font-size: 44px;
  color: #152133;
  letter-spacing: 1.29px;
  font-weight: 700;
}
.hello-tip {
  font-family: PingFang-SC-Regular;
  font-size: 20px;
  color: #152133;
  letter-spacing: 0.59px;
  font-weight: 400;
}
.work {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #152133;
  letter-spacing: 0;
  font-weight: 400;
}
.blue-line {
  width: 4px;
  height: 16px;
  background: #0156ff;
  border-radius: 2px;
  margin-right: 4px;
}
.home-title {
  height: 22px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.work-title {
  height: 22px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.link-type {
  height: 20px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-decoration-line: underline;
}
.el-input__inner {
  background: transparent;
  border: none;
}
.post-content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-clamp: 2;
  margin: 0 0 18px 7px;

  height: 44px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
.video-cover {
  width: 76px;
  height: 118px;
  border-radius: 4px;
}
.module-bg {
  height: 100%;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 16px 12px 0 0;
  // margin-right: 10px;
  background-image: url("../assets/images/workbench/bg_small.png");
  background-size: cover; // 这也能防止图片重复出现
  background-position: center; /* 保证图片居中 */
}
.module-content {
  background: #fff;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
}
.custom-list {
  list-style: none; /* 去掉默认的项目符号 */
  padding-left: 20px; /* 添加左边距使符号显示 */
}

.custom-list li {
  position: relative;
  padding: 10px; /* 根据需要调整 */
}

.custom-list li::before {
  content: "•"; /* 使用圆点作为符号 */
  position: absolute;
  left: 0;
  font-size: 16px; /* 修改点的大小 */
  color: #016fee; /* 修改点的颜色 */
}

.notice-1 {
  background: #fff7e9;
  border-radius: 2px;
  font-size: 12px;
  color: #ff8200;
  line-height: 20px;
  padding: 1px 8px;
}
.notice-2 {
  background: #ccf6e4;
  border-radius: 2px;
  color: #03864f;
  line-height: 20px;
  padding: 1px 8px;
}
.notice-3 {
  background: #d5e5fa;
  border-radius: 2px;
  color: #006fee;
  line-height: 20px;
  padding: 1px 8px;
}
.help-img {
  width: 38px;
  height: 48px;
  margin-top: 23px;
}
.help-title {
  font-size: 14px;
  color: #152133;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 16px;
}
.publish-container {
  height: 100%;
  border-radius: 8px;
  margin-right: 16px;
  padding: 16px;
  cursor: pointer;

  .point {
    width: 8px;
    height: 8px;
    background-color: #006fee;
    border-radius: 8px;
    margin-top: 7px;
  }
  .pulish-title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 500;
  }
  .content {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
  .time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 20px;
    font-weight: 400;
  }
}
.video-container {
  width: 32.5%;
  height: 100%;
  // margin-right: 16px;
  padding: 8px 16px 8px 10px;
  cursor: pointer;
  overflow: hidden;
  background: #f4f4f5;
  border-radius: 8px;

  .flexBox {
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .parentWidth {
    width: 100%;
  }
  .timeFont {
    width: 150px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 20px;
    font-weight: 400;
  }

  .video-title {
    width: 90%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    letter-spacing: 1px;
    line-height: 22px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .content {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
  .time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.26);
    line-height: 20px;
    font-weight: 400;
  }
}
.mediaBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .mediaBox {
    width: 24.5%;
  }
}

.scroll-container {
  height: 430px; /* 设置容器高度 */
  overflow: hidden; /* 隐藏溢出部分 */
  position: relative; /* 为绝对定位提供参考 */
}

.scroll-list {
  // height: auto; /* 列表的高度自适应 */
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}
</style>

