var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products-container" },
    [
      _vm.quota.fileSize
        ? _c(
            "div",
            {
              staticClass: "flex-row-center f-end",
              staticStyle: { "margin-bottom": "15px" },
            },
            [
              _c("span", { staticClass: "mr5" }, [
                _vm._v(_vm._s(_vm.$t("quota")) + "："),
              ]),
              _c("Slider", {
                attrs: {
                  value: _vm.formatQuota(_vm.quota.useFileSize) || 0,
                  maxValue: _vm.formatQuota(_vm.quota.fileSize) || "10G",
                },
              }),
              _c("div", { staticClass: "ml5" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.quota.useFileSize || 0) +
                    "/" +
                    _vm._s(_vm.quota.fileSize || "10G") +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "right-panel" }, [
        _c("div", { staticClass: "video-list" }, [
          _c("div", { staticClass: "flex-row-center f-between w-100" }, [
            _c(
              "div",
              { staticClass: "f-shrink" },
              [
                _vm.isSelectionMode == 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button",
                        attrs: { icon: "el-icon-upload2" },
                        on: { click: _vm.addMaterial },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.upload")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 && _vm.currentLevel < 2
                  ? _c(
                      "el-button",
                      {
                        staticClass: "normal-button",
                        attrs: { icon: "el-icon-folder-add" },
                        on: { click: _vm.openAddDialog },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.createFolder")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 && _vm.ids.length > 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "delete-button",
                        attrs: { icon: "iconfont icon-delete" },
                        on: { click: _vm.deleteMultiple },
                      },
                      [_vm._v(_vm._s(_vm.$t("remove")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode != 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button matreial-floating-button",
                        on: { click: _vm.confirmSelection },
                      },
                      [_vm._v(_vm._s(_vm.$t("confirmSelection")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _vm.isSelectionMode == 0
                  ? _c(
                      "el-radio-group",
                      {
                        staticClass: "custom-radio-group",
                        model: {
                          value: _vm.styleType,
                          callback: function ($$v) {
                            _vm.styleType = $$v
                          },
                          expression: "styleType",
                        },
                      },
                      [
                        _c("el-radio", {
                          attrs: {
                            label: "grid",
                            border: _vm.styleType === "grid",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-grid",
                                      class:
                                        _vm.styleType === "grid"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2790080755
                          ),
                        }),
                        _c("el-radio", {
                          attrs: {
                            label: "list",
                            border: _vm.styleType === "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-list",
                                      class:
                                        _vm.styleType === "list"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3722370355
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { width: "400px", "margin-right": "5px" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "account-input",
                        attrs: {
                          placeholder: _vm.$t("product.tipName"),
                          clearable: "",
                        },
                        on: { change: _vm.handleQuery },
                        model: {
                          value: _vm.queryParams.searchValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "searchValue", $$v)
                          },
                          expression: "queryParams.searchValue",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-input__icon iconfont icon-sousuotubiao",
                          attrs: { slot: "prefix" },
                          on: { click: _vm.handleQuery },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("refresh"),
                      placement: "top",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        circle: "",
                        icon: "el-icon-refresh",
                      },
                      on: { click: _vm.refresh },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            [
              _c("div", [
                _vm.currentPath.length > 0
                  ? _c("div", { staticClass: "flex-row-center fileSpace" }, [
                      _c("div", { staticClass: "breadcrumb" }, [
                        _c("span", { on: { click: _vm.goLast } }, [
                          _vm._v("← " + _vm._s(_vm.$t("file.goBack"))),
                        ]),
                        _c(
                          "span",
                          { staticClass: "ml10", on: { click: _vm.goBack } },
                          [_vm._v(_vm._s(_vm.$t("file.goBackWorks")) + " /")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "breadcrumb",
                          staticStyle: { "margin-left": "5px" },
                        },
                        _vm._l(
                          _vm.currentPathFolders,
                          function (folder, index) {
                            return _c(
                              "span",
                              {
                                key: folder.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.navigateTo(index)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(folder.name) + " "),
                                index < _vm.currentPath.length - 1
                                  ? _c("span", [_vm._v(" / ")])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.styleType === "grid"
                  ? _c(
                      "div",
                      { staticClass: "flex-row f-wrap folders" },
                      _vm._l(_vm.currentFolders, function (folder) {
                        return _c("folder", {
                          key: folder.id,
                          attrs: { folder: folder },
                          on: {
                            "open-folder": _vm.openFolder,
                            "edit-folder": _vm.openEditDialog,
                            "delete-folder": _vm.deleteProductType,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.styleType === "grid"
                ? _c(
                    "div",
                    { staticClass: "flex-row f-wrap" },
                    _vm._l(_vm.productList, function (video, index) {
                      return _c("video-grid-item", {
                        key: index,
                        attrs: {
                          video: video,
                          isSelectionMode: _vm.isSelectionMode != 0,
                          type: 3,
                        },
                        on: {
                          "delete-video": _vm.deleteVideo,
                          "view-text": _vm.viewVideo,
                          "edit-video": _vm.editVideo,
                          "toggle-selection": _vm.handleToggleSelection,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.styleType === "list"
                ? _c(
                    "div",
                    { staticClass: "w-100 mt10" },
                    [
                      _c("video-list-table", {
                        attrs: {
                          videoList: _vm.mergedList,
                          isSelectionMode: _vm.isSelectionMode != 0,
                        },
                        on: {
                          "open-folder": _vm.openFolder,
                          "edit-folder": _vm.openEditDialog,
                          "delete-folder": _vm.deleteProductType,
                          "delete-video": _vm.deleteVideo,
                          "view-text": _vm.viewVideo,
                          "edit-video": _vm.editVideo,
                          "toggle-selection": _vm.handleToggleSelection,
                          "select-multiple": _vm.handleSelectMultiple,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getProductList,
                },
              }),
              _vm.productList.length == 0
                ? _c("el-empty", { attrs: { "image-size": 200 } })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c("EditDialog", {
        attrs: {
          visible: _vm.editDialogVisible,
          title: _vm.editDialogTitle,
          initialValue: _vm.initialInput,
          initialLabel: _vm.initialLabel,
          labelList: _vm.labelList,
        },
        on: {
          "update:visible": function ($event) {
            _vm.editDialogVisible = $event
          },
          confirm: _vm.handleConfirm,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            visible: _vm.uploadDialogVisible,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadDialogVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.uploadTitle)),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    _vm.uploadDialogVisible = false
                  },
                },
              }),
            ]),
          ]),
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                model: _vm.uploadForm,
                "label-width": "80px",
                rules: _vm.rules,
                "label-position": "left",
              },
            },
            [
              _vm.uploadTitle == _vm.$t("file.upload")
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("file.file"), prop: "fileList" } },
                    [
                      _c("videoUploadMultifile", {
                        attrs: { limit: 10, fileType: _vm.fileTypes },
                        model: {
                          value: _vm.uploadForm.fileList,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadForm, "fileList", $$v)
                          },
                          expression: "uploadForm.fileList",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.uploadTitle == _vm.$t("edit")
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("file.name"), prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("tip.input") },
                        model: {
                          value: _vm.uploadForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadForm, "name", $$v)
                          },
                          expression: "uploadForm.name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.position"),
                    prop: "productTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.list,
                      normalizer: _vm.normalizer,
                      clearable: false,
                      placeholder: _vm.$t("file.tipSelectFolder"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "value-label",
                        fn: function (ref) {
                          var node = ref.node
                          return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.uploadForm.productTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploadForm, "productTypeId", $$v)
                      },
                      expression: "uploadForm.productTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("file.description"),
                    prop: "description",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("tip.input") },
                    model: {
                      value: _vm.uploadForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploadForm, "description", $$v)
                      },
                      expression: "uploadForm.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("label.label") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("label.tipSelect"),
                        "default-first-option": "",
                        "allow-create": "",
                        filterable: "",
                        multiple: "",
                      },
                      model: {
                        value: _vm.uploadForm.labels,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploadForm, "labels", $$v)
                        },
                        expression: "uploadForm.labels",
                      },
                    },
                    _vm._l(_vm.labelList, function (dict) {
                      return _c("el-option", {
                        key: dict.id,
                        attrs: { label: dict.label, value: dict.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "cancelBtn",
                  on: {
                    click: function ($event) {
                      _vm.uploadDialogVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn",
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: { click: _vm.submitUpload },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }