<template>
  <div class="forgot-password">
    <div class="forgot-password-main">
      <h3 class="title">{{ $t('forget.forgotPassword') }}</h3>
      <el-form
        ref="forgotPasswordForm"
        :model="forgotPasswordForm"
        :rules="forgotPasswordRules"
        class="forgot-password-form"
      >
        <el-form-item :label="$t('forget.resetType')">
          <el-radio-group v-model="forgotPasswordForm.resetType">
            <el-radio :label="'email'">{{ $t('forget.resetByEmail') }}</el-radio>
            <el-radio :label="'phone'">{{ $t('forget.resetByPhone') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="forgotPasswordForm.resetType === 'email'" prop="email">
          <el-input
            v-model="forgotPasswordForm.email"
            type="text"
            auto-complete="off"
            :placeholder="$t('login.email')"
          >
            <svg-icon slot="prefix" icon-class="email" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <el-form-item v-else prop="phonenumber">
          <el-input
            v-model="forgotPasswordForm.phonenumber"
            type="text"
            auto-complete="off"
            :placeholder="$t('login.mobile')"
          >
            <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="forgotPasswordForm.imgCode"
            auto-complete="off"
            :placeholder="$t('login.imageVerificationCode')"
            style="width: 60%"
          >
            <svg-icon
              slot="prefix"
              icon-class="validCode"
              class="el-input__icon input-icon"
            />
          </el-input>
          <div class="forgot-password-code" style="cursor : pointer;">
            <img :src="codeUrl" @click="getCode" style="height: 38px;"/>
          </div>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            v-model="forgotPasswordForm.code"
            auto-complete="off"
            :placeholder="$t('login.verificationCode')"
            style="width: 63%"
            @keyup.enter.native="handleForgotPassword"
          >
            <svg-icon
              slot="prefix"
              icon-class="validCode"
              class="el-input__icon input-icon"
            />
          </el-input>
          <span v-if="forgotPasswordForm.resetType === 'email'">
            <el-button :loading="emailLoading" style="margin-left: 10px" class="btn round" @click="sendEmailCode"
                       v-if="!isDisabled">{{ emailContent }}</el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ emailContent }}</el-button>
          </span>
          <span v-else>
            <el-button :loading="phoneLoading" style="margin-left: 10px" class="btn round"  @click="sendPhoneCode"
                       v-if="!isDisabled">{{ phoneContent }}</el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ phoneContent }}</el-button>
          </span>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model="forgotPasswordForm.password"
            type="password"
            show-password
            auto-complete="off"
            :placeholder="$t('forget.enterNewPassword')"
            @keyup.enter.native="handleForgotPassword"
          >
            <svg-icon
              slot="prefix"
              icon-class="password"
              class="el-input__icon input-icon"
            />
          </el-input>
        </el-form-item>

        <el-form-item style="width: 100%">
          <el-button
            :disabled="!forgotPasswordForm.resetType"
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleForgotPassword"
          >
            <span v-if="!loading">{{ $t('forget.resetPassword') }}</span>
            <span v-else>{{ $t('forget.resetting') }}</span>
          </el-button>
          <div style="float: right">
            <router-link class="link-type" :to="'/login'">{{ $t('forget.backToLogin') }}</router-link>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="bk" style="float:right;"></div>
    <div class="el-forgot-password-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
  </div>
</template>

<script>
  import {getCodeImg, forgetPassword } from "@/api/login";
  import {sendEmilCode, sendMyPhone} from "@/api/base/resource";
  import Cookies from "js-cookie";
  import {encrypt} from "@/utils/jsencrypt";

  export default {
    name: "ForgotPassword",
    data() {
      return {
        isDisabled: false,
        emailContent: this.$t('login.getEmailCode'),
        phoneContent: this.$t('login.getSMSCode'),
        timer: null,
        count: '',
        codeUrl: "",
        forgotPasswordForm: {
          resetType: "email",
          email: "",
          phonenumber: "",
          code: "",
          password: "",
          imgCode:'',
          uuid:'',
        },
        forgotPasswordRules: {
          email: [
            { required: true, trigger: ['blur', 'change'], message: this.$t('login.tipEmail') },
            {
              pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: this.$t('login.tipCorrectEmail'),
              trigger: ['blur', 'change']
            }
          ],
          phonenumber: [
            { required: true, trigger: ['blur', 'change'], message: this.$t('login.tipPhone') },
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: this.$t('login.tipCorrectPhone'),
              trigger: ['blur', 'change']
            }
          ],
          code: [{ required: true, trigger: ['blur', 'change'], message: this.$t('register.enterVerificationCode') }],
          imgCode: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterImageCaptchaAgain')}],
          password: [
            { required: true, message: this.$t('register.passwordEmpty'), trigger: ['blur', 'change'] },
            {
              pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
              message: this.$t('register.passwordCriteria'),
              trigger: "blur"
            }
          ]
        },
        loading: false,
        emailLoading: false,
        phoneLoading: false,
        captchaEnabled: true,
      };
    },
    watch: {
      //切换登录类型
      'forgotPasswordForm.resetType': function() {
        //清空必填项提示
        this.$nextTick(() => {
          this.$refs.forgotPasswordForm.clearValidate();
        });
      }
    },
    created() {
      this.getCode()
    },
    methods: {
      getCode() {
        getCodeImg().then((res) => {
          this.captchaEnabled =
            res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled;
          if (this.captchaEnabled) {
            this.codeUrl = "data:image/gif;base64," + res.data.img;
            this.forgotPasswordForm.uuid = res.data.uuid;
          }
        });
      },
      sendEmailCode() {
        if (this.forgotPasswordForm.email === '') {
          this.$refs.forgotPasswordForm.validateField('email');
          return;
        }
        if (!/^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.forgotPasswordForm.email)) {
          this.$refs.forgotPasswordForm.validateField('email');
          return;
        }
        if (this.forgotPasswordForm.imgCode == null) {
          this.$refs.forgotPasswordForm.validateField('imgCode')
          return
        }
        let vm = this;
        vm.emailContent = '';
        this.emailLoading = true;
        sendEmilCode({
          sendType: 'reset',
          email: vm.forgotPasswordForm.email,
          uuid: vm.forgotPasswordForm.uuid,
          code: vm.forgotPasswordForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.emailLoading = false;
            vm.isDisabled = false;
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.emailContent = vm.count + this.$t('login.later');
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                vm.isDisabled = true;
                vm.count--;
                vm.emailContent = vm.count + this.$t('login.later');
              } else {
                vm.isDisabled = false;
                vm.emailContent = this.$t('login.getEmailCode');
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        }).catch(() => {
          this.emailLoading = false;
          vm.emailContent = this.$t('login.getEmailCode');
          this.getCode()
        });
      },
      sendPhoneCode() {
        if (this.forgotPasswordForm.phonenumber === '') {
          this.$refs.forgotPasswordForm.validateField('phonenumber');
          return;
        }
        if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.forgotPasswordForm.phonenumber)) {
          this.$refs.forgotPasswordForm.validateField('phonenumber');
          return;
        }
        if (this.forgotPasswordForm.imgCode == null) {
          this.$refs.forgotPasswordForm.validateField('imgCode')
          return
        }
        let vm = this;
        vm.phoneContent = '';
        this.phoneLoading = true;
        sendMyPhone({
          sendType: 'reset',
          phone: vm.forgotPasswordForm.phonenumber,
          uuid: vm.forgotPasswordForm.uuid,
          code: vm.forgotPasswordForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.phoneLoading = false;
            vm.isDisabled = false;
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.phoneContent = vm.count + this.$t('login.later');
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                vm.isDisabled = true;
                vm.count--;
                vm.phoneContent = vm.count + this.$t('login.later');
              } else {
                vm.isDisabled = false;
                vm.phoneContent = this.$t('login.getSMSCode');
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        }).catch(() => {
          this.phoneLoading = false;
          vm.phoneContent = this.$t('login.getSMSCode');
          this.getCode()
        });
      },
      handleForgotPassword() {
        this.$refs.forgotPasswordForm.validate((valid) => {
          if (valid) {
            this.loading = true;
            this.forgotPasswordForm.username = this.forgotPasswordForm.resetType === 'email' ? this.forgotPasswordForm.email : this.forgotPasswordForm.phonenumber
            if(this.forgotPasswordForm.resetType==='email'){
              this.forgotPasswordForm.phonenumber = ''
            }else if(this.forgotPasswordForm.resetType==='phone'){
              this.forgotPasswordForm.email = ''
            }
            forgetPassword(this.forgotPasswordForm)
              .then((res) => {
                const username = this.forgotPasswordForm.resetType === 'email' ? this.forgotPasswordForm.email : this.forgotPasswordForm.phonenumber;
                this.$alert(
                  "<font color='red'>" + this.$t('forget.resetSuccess') + "</font>",
                  this.$t('register.systemPrompt'),
                  {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                  }
                )
                  .then(() => {
                    Cookies.set("username", this.forgotPasswordForm.username, {expires: 30});
                    Cookies.set("password", encrypt(this.forgotPasswordForm.password), {expires: 30});
                    this.$router.push({path: "/login"});
                  })
                  .catch(() => {});
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            return false;
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .bk {
    background-image: url("../assets/images/loginBackground.png");
  //background-size: 100% 100%;
    background-size: cover; /* 覆盖整个元素，可能裁剪部分图片 */
    background-position: center; /* 图片在元素中央显示 */
    width: 60%;
    height: 100vh; /* 100% 屏幕高度 */
  }
  .forgot-password {
    height:100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #F7F8FA;
  }
  .forgot-password-main {
    width: 40%;
    background-color: #F7F8FA;
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #1D2129;
    line-height: 48px;
    margin: 0 auto 30px;
    text-align: center;
  }
  .forgot-password-form{
    width: 100%;
    padding: 0 10%;

    .el-input {
      height: 38px;

      input {
        height: 38px;
      }
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
  .forgot-password-code{
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .el-forgot-password-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
