var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-input" },
    [
      _vm.isRecording
        ? _c(
            "el-tooltip",
            { attrs: { content: "结束录制" } },
            [
              _c("el-button", {
                attrs: {
                  type: "text",
                  circle: "",
                  icon: "el-icon-turn-off-microphone",
                  disabled: _vm.disabled,
                },
                on: { click: _vm.toggleRecording },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isRecording
        ? _c("span", { staticClass: "ml5 mr5" }, [
            _vm._v(" " + _vm._s(_vm.formattedRecordingTime) + " "),
          ])
        : _c(
            "el-tooltip",
            { attrs: { content: "开始录制" } },
            [
              _c("el-button", {
                attrs: {
                  type: "text",
                  circle: "",
                  icon: "el-icon-microphone",
                  loading: _vm.isRecording,
                  disabled: _vm.disabled,
                },
                on: { click: _vm.toggleRecording },
              }),
            ],
            1
          ),
      _vm.isRecording
        ? _c(
            "el-tooltip",
            { attrs: { content: "取消录制" } },
            [
              _c("el-button", {
                attrs: { type: "text", icon: "el-icon-circle-close" },
                on: { click: _vm.cancelRecording },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-input", {
        staticStyle: { width: "95%" },
        attrs: {
          placeholder: _vm.disabled
            ? _vm.$t("ai.workHard")
            : _vm.$t("ai.tipInstruct"),
          disabled: _vm.disabled || _vm.isRecording,
        },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.sendMessage($event)
          },
        },
        model: {
          value: _vm.message,
          callback: function ($$v) {
            _vm.message = $$v
          },
          expression: "message",
        },
      }),
      _c(
        "div",
        {
          staticClass: "sendMessage",
          style: {
            cursor: _vm.disabled || _vm.isRecording ? "not-allowed" : "pointer",
          },
          on: { click: _vm.sendMessage },
        },
        [_c("i", { staticClass: "iconfont icon-telegram-normal" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }