var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "publicDialog",
      attrs: {
        visible: _vm.visible,
        width: "50%",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleBeforeClose,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("div", { staticClass: "headerFlex" }, [
          _c("div", { staticClass: "headerTitle" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
        ]),
      ]),
      _c(
        "el-form",
        {
          ref: "uploadForm",
          attrs: { model: _vm.form, "label-width": "100px", rules: _vm.rules },
        },
        [
          _vm.title == _vm.$t("material.upload")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("file.file"), prop: "fileList" } },
                [
                  _c("videoUploadMultifile", {
                    attrs: { limit: 10 },
                    model: {
                      value: _vm.form.fileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fileList", $$v)
                      },
                      expression: "form.fileList",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        "line-height": "18px",
                        "margin-top": "5px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("material.tipRemoveSubtitle")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.edit")
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("file.name"), prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("tip.input"),
                      maxlength: "20",
                      "show-word-limit": "",
                      disabled: _vm.isSystem,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.upload")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.isStripped"),
                    prop: "isSplitVideoParts",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.isSplitVideoParts,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isSplitVideoParts", $$v)
                        },
                        expression: "form.isSplitVideoParts",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(_vm._s(_vm.$t("yes"))),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(_vm._s(_vm.$t("no"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.needMaterialTypeId
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.position"),
                    prop: "materialTypeId",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flexTreeselect" },
                    [
                      _c("treeselect", {
                        attrs: {
                          options: _vm.treeFileList,
                          "show-count": false,
                          normalizer: _vm.normalizer,
                          clearable: false,
                          "load-options": _vm.loadOptions,
                          placeholder: _vm.$t("file.tipSelectFolder"),
                          disabled: _vm.isSystem,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "value-label",
                              fn: function (ref) {
                                var node = ref.node
                                return [
                                  _vm._v(" " + _vm._s(node.raw.name) + " "),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1894111289
                        ),
                        model: {
                          value: _vm.form.materialTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "materialTypeId", $$v)
                          },
                          expression: "form.materialTypeId",
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "新建文件夹",
                            placement: "top",
                          },
                        },
                        [
                          _c("el-button", {
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: { click: _vm.openAddFile },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("file.description"), prop: "description" },
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.$t("tip.input"),
                  maxlength: "500",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("label.label") } },
            [
              _c(
                "el-select",
                {
                  ref: "searchSelect",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "allow-create": "",
                    filterable: "",
                    multiple: "",
                    "default-first-option": "",
                    placeholder: _vm.$t("label.tipSelect"),
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.form.labels,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "labels", $$v)
                    },
                    expression: "form.labels",
                  },
                },
                _vm._l(_vm.labelList, function (dict) {
                  return _c("el-option", {
                    key: dict.id,
                    attrs: { label: dict.label, value: dict.label },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.title == _vm.$t("material.upload")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.removeSubtitle"),
                    prop: "isEraseVideoSubtitles",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.isEraseVideoSubtitles,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isEraseVideoSubtitles", $$v)
                      },
                      expression: "form.isEraseVideoSubtitles",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.title == _vm.$t("material.upload")
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.aiFaceSwap"),
                    prop: "isMergeVideoFace",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 1, "inactive-value": 0 },
                    model: {
                      value: _vm.form.isMergeVideoFace,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isMergeVideoFace", $$v)
                      },
                      expression: "form.isMergeVideoFace",
                    },
                  }),
                  _vm.form.isMergeVideoFace == 1
                    ? _c(
                        "el-form-item",
                        { attrs: { prop: "mergeVideoFaceImageUrl" } },
                        [
                          !_vm.form.mergeVideoFaceImageUrl
                            ? _c(
                                "div",
                                {
                                  staticClass: "aspect-ratio-box flex-column",
                                  on: { click: _vm.chooseMaterial },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-upload" }),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "5px" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("basicInfo.materialImport")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "material-container ml80",
                                  on: {
                                    mouseover: function ($event) {
                                      _vm.form.hover = true
                                    },
                                    mouseleave: function ($event) {
                                      _vm.form.hover = false
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    staticClass: "material-thumbnail",
                                    attrs: {
                                      src: _vm.form.mergeVideoFaceImageUrl,
                                      alt: "material",
                                    },
                                  }),
                                  _vm.form.hover
                                    ? _c("div", { staticClass: "overlay" }, [
                                        _c(
                                          "div",
                                          { on: { click: _vm.removeMaterial } },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("basicInfo.remove"))
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.form.isEraseVideoSubtitles == 1 || _vm.form.isMergeVideoFace == 1
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.retainOriginalVideo"),
                    prop: "isReserve",
                  },
                },
                [
                  _c("el-switch", {
                    attrs: { "active-value": 0, "inactive-value": 1 },
                    model: {
                      value: _vm.form.isReserve,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isReserve", $$v)
                      },
                      expression: "form.isReserve",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "cancelBtn", on: { click: _vm.handleCancel } },
            [_vm._v(_vm._s(_vm.$t("cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "submitBtn",
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.submitUpload },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          staticStyle: { "z-index": "9999" },
          attrs: {
            "append-to-body": "",
            title: _vm.$t("mixing.materia"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("Material", {
            ref: "materialLibrary",
            attrs: { name: "Material", skipInit: true },
            on: { selectionConfirmed: _vm.selectionConfirmed },
          }),
        ],
        1
      ),
      _vm.folderDialogVisible
        ? _c("addFolderDialog", {
            attrs: {
              folderDialogVisible: _vm.folderDialogVisible,
              labelList: _vm.labelList,
            },
            on: {
              close: _vm.closeFolderDialog,
              submitAddFile: _vm.submitAddFile,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }