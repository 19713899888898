<template>
  <!-- 粗剪 -->
  <div>
    <video ref="video" :src="videoUrl" controls @timeupdate="updateCurrentTime"></video>
    <div class="progress-container">
      <input type="range" ref="progressBar" @click="seekVideo" :max="videoDuration" step="0.001" v-model="currentTime" />
      <div class="markers">
        <div v-for="(marker, index) in markers" :key="index" :style="{ left: marker.out/videoDuration*100 + '%' }" class="marker"></div>
      </div>
    </div>
    <el-tooltip :content="$t('basicInfo.mark')">
      <svg-icon icon-class="splitAudio"
        style="width: 45px;height: 35px; border: 1px solid #ccc; border-radius: 5px;
        padding: 5px 10px;margin: 10px 20px 5px; cursor: pointer" @click="setMarker"/>
    </el-tooltip>
    <div class="ml10">
      <span style="font-weight: bold;font-size: 18px;">{{$t('basicInfo.segment')}}</span>
      <span style="font-size: 14px;margin-left: 5px;">{{$t('basicInfo.selectSegments')}}</span></div>
    <ul>
      <li v-for="(marker, index) in markers" :key="index">
        <input type="checkbox" v-model="marker.checked" />
        {{ formatTime(marker.in) }} - {{ formatTime(marker.out) }}
        <i class="el-icon-delete"
          v-if="index > 0"
          style="cursor: pointer;"
          @click="removeMarker(index)"></i>
      </li>
    </ul>
    <div style="float: right; margin: 10px 20px">
      <el-button @click="cancel" class="mb20">{{$t('cancel')}}</el-button>
      <el-button @click="confirm" type="primary" class="mb20">{{$t('confirm')}}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pruning',
  data() {
    return {
      videoUrl:'',
      oldMarkers:[],
      markers: [],
      videoDuration: 0,
      currentTime: 0,
    };
  },
  methods: {
    init(item){
      console.log('init',item)
      this.videoUrl = item.mediaUrl
      if(item.inOutList){
        this.markers = item.inOutList
      }else{
        this.markers = [{ in: 0, out:item.out||item.videoOut, checked: true}]
      }
      this.oldMarkers = JSON.parse(JSON.stringify(this.markers)); // 深拷贝
    },
    updateCurrentTime() {
      this.currentTime = this.$refs.video.currentTime;
      // console.log('this.currentTime',this.currentTime)
    },
    setMarker() {
      if(this.currentTime == 0){
        this.$modal.msgError(this.$t('basicInfo.startPoint'))
        return
      }
      if(this.currentTime == this.videoDuration){
        this.$modal.msgError(this.$t('basicInfo.endPoint'))
        return
      }
      for(let i=0;i<this.markers.length;i++){
        if(this.currentTime == this.markers[i].in || this.currentTime == this.markers[i].out){
          this.$modal.msgError(this.$t('basicInfo.existingMark'))
          return
        }
        if(this.currentTime > this.markers[i].in && this.currentTime < this.markers[i].out){
          this.markers.splice(i+1,0,{in: this.currentTime, out: this.markers[i].out, checked: false})
          this.markers[i].out = this.currentTime;
          return
        }
      }
    },
    removeMarker(index) {
      if (index > 0) {
        this.markers.splice(index, 1);
        if (index === this.markers.length) {
          this.markers[this.markers.length - 1].out = this.videoDuration;
        }
      }
    },
    cancel(){
      this.$refs.video.pause(); // 停止视频播放
      this.$emit('cancel', this.oldMarkers);
    },
    confirm() {
      const selectedSegments = this.markers.filter(marker => marker.checked);
      if(selectedSegments.length == 0){
        this.$modal.msgError(this.$t('basicInfo.minSelect'))
        return
      }
      this.$refs.video.pause(); // 停止视频播放
      this.$emit('confirm', this.markers);
    },
    formatTime(seconds) {
      const ms = (seconds % 1).toFixed(3).substring(2);
      const s = Math.floor(seconds) % 60;
      const m = Math.floor(seconds / 60) % 60;
      const h = Math.floor(seconds / 3600);
      return `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}:${String(s).padStart(2, '0')}.${ms}`;
    },
    seekVideo(event) {
      const rect = event.target.getBoundingClientRect();
      const pos = (event.clientX - rect.left) / rect.width;
      this.$refs.video.currentTime = pos * this.videoDuration;
    }
  },
  mounted() {
    this.$refs.video.addEventListener('loadedmetadata', () => {
      this.videoDuration = this.$refs.video.duration;
      this.markers[this.markers.length - 1].out = this.videoDuration;
    });
  }
};
</script>

<style scoped>
video {
  width: 100%;
  max-height: 400px;
}
input[type="range"] {
  width: 100%;
}
.progress-container {
  position: relative;
  width: 100%;
}
.markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.marker {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: red;
  pointer-events: none;
}
</style>
