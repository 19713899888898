<template>
  <div :class="['chat-message-container', { 'align-right': isUser, 'align-left': !isUser }]">
    <div :class="[ isUser && 'fromMessageBox',  !isUser && 'toMessageBox']" v-if="message.type === 'text'">
      <div class="fromMessage">
        {{ message.content }}
      </div>
    </div>
    <div class="chat-message" v-else-if="message.type === 'audio'">
      <audio controls :src="message.url" class="f-grow" ref="audioPlayer"></audio>
      <el-button type="text" v-if="message.content && !isShowText" @click="isShowText = true">转文字</el-button>
      <div v-if="isShowText">{{ message.content }}</div>
    </div>
    <div v-else-if="message.type === 'script'||message.type === 'post'">
      <EditableTextList :message="message"
        @submitScript="$emit('submitScript', message)"
        @submitPost="$emit('submitPost', message)"
        @submit="$emit('submitMultiText', message)"/>
    </div>
    <div v-else-if="message.type === 'video'">
      <VideoPlayerMessage
        :message="message"
        @again="$emit('againVideo', message)"
        @submit="$emit('submitVideo', message)"
      />
    </div>
    <div v-else-if="message.type === 'listVideo'">
      <ListVideo :message="message" @submit="chooseVideo"/>
    </div>
    <div v-else-if="message.type === 'listSelector'">
      <ListSelector :message="message" @submit="submitAccount"/>
    </div>
    <div :class="['message-time', { 'align-right-time': isUser, 'align-left': !isUser }]">{{ message.time }}</div>
  </div>
</template>

<script>
import EditableTextList from './EditableTextList.vue';
import VideoPlayerMessage from './VideoPlayerMessage.vue';
import ListSelector from './ListSelector.vue';
import ListVideo from './ListVideo.vue';

export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  components: {
    EditableTextList,
    VideoPlayerMessage,
    ListSelector,
    ListVideo
  },
  data() {
    return {
      isShowText: false,//是否显示语音转文字
    }
  },
  computed: {
    isUser() {
      return this.message.user === 'user';
    }
  },
  methods: {
    chooseVideo(url){
      this.$emit('chooseVideo', this.url)
    },
    submitAccount(selected){
      this.$emit('submitAccount', selected)
    },
  }
};
</script>

<style scoped>
.chat-message-container {
  width: 90%;
  margin-bottom: 20px;
  position: relative;
}
.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
  margin-left: auto;
}

.align-right-time {
  display: flex;
  justify-content: flex-end;
}

.message-time {
  margin-top: 10px;
  margin-left: 5px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 400;
  font-size: 12px;
  color: #99AECA;
  line-height: 16px;
  text-align: left;
  font-style: normal;
}

.chat-message {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.fromMessageBox {
  max-width: 100%;
  display: flex;
  justify-content: end;
}

.toMessageBox {
  max-width: 100%;
  display: flex;
  justify-content: start;
}

.fromMessage {
  max-width: 90%;
  background: #2787F5;
  border-radius: 10px;
  padding: 10px 8px;
  color: #fff;
}
</style>
