var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materialDialog" },
    [
      _c("div", { staticClass: "right-panel" }, [
        _c(
          "div",
          { staticClass: "tabs-container" },
          [
            !_vm.skipInit
              ? _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.tabClick },
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _vm.isSelectionMode == 0
                      ? _c("el-tab-pane", {
                          attrs: { label: _vm.$t("material.all"), name: "all" },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 ||
                    _vm.isSelectionMode == 1 ||
                    _vm.isSelectionMode == 5
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.video"),
                            name: "video",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 ||
                    _vm.isSelectionMode == 1 ||
                    _vm.isSelectionMode == 3
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.picture"),
                            name: "image",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 || _vm.isSelectionMode == 2
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.audio"),
                            name: "audio",
                          },
                        })
                      : _vm._e(),
                    _vm.isSelectionMode == 0 || _vm.isSelectionMode == 4
                      ? _c("el-tab-pane", {
                          attrs: {
                            label: _vm.$t("material.copywriting"),
                            name: "text",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.quota.fileSize
              ? _c(
                  "div",
                  { staticClass: "flex-row-center quota" },
                  [
                    _c("span", { staticClass: "mr5" }, [
                      _vm._v(_vm._s(_vm.$t("quota")) + "："),
                    ]),
                    _c("Slider", {
                      attrs: {
                        value: _vm.formatQuota(_vm.quota.useFileSize) || 0,
                        maxValue: _vm.formatQuota(_vm.quota.fileSize) || "10G",
                      },
                    }),
                    _c("div", { staticClass: "ml5" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.quota.useFileSize || 0) +
                          "/" +
                          _vm._s(_vm.quota.fileSize || "10G") +
                          " "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "flex-row-center f-between w-100" }, [
            _c(
              "div",
              { staticClass: "f-shrink" },
              [
                _vm.isSelectionMode == 0 && _vm.activeTab == "text"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button",
                        attrs: { icon: "el-icon-upload2" },
                        on: { click: _vm.addText },
                      },
                      [_vm._v(_vm._s(_vm.$t("copywriting.new")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 &&
                (_vm.activeTab == "all" ||
                  _vm.activeTab == "video" ||
                  _vm.activeTab == "image" ||
                  _vm.activeTab == "audio")
                  ? _c(
                      "el-button",
                      {
                        staticClass: "gradient-button",
                        attrs: { icon: "el-icon-upload2" },
                        on: { click: _vm.addMaterial },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.upload")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode == 0 && _vm.currentLevel < 2
                  ? _c(
                      "el-button",
                      {
                        staticClass: "normal-button",
                        attrs: { icon: "el-icon-folder-add" },
                        on: { click: _vm.openAddDialog },
                      },
                      [_vm._v(_vm._s(_vm.$t("file.createFolder")))]
                    )
                  : _vm._e(),
                _vm.isSelectionMode != 0
                  ? _c(
                      "div",
                      { staticClass: "mr10" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.confirmSelection },
                          },
                          [_vm._v(_vm._s(_vm.$t("confirmSelection")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex-row-center" },
              [
                _vm.isSelectionMode == 0
                  ? _c(
                      "el-radio-group",
                      {
                        staticClass: "custom-radio-group",
                        model: {
                          value: _vm.styleType,
                          callback: function ($$v) {
                            _vm.styleType = $$v
                          },
                          expression: "styleType",
                        },
                      },
                      [
                        _c("el-radio", {
                          attrs: {
                            label: "grid",
                            border: _vm.styleType === "grid",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-grid",
                                      class:
                                        _vm.styleType === "grid"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2790080755
                          ),
                        }),
                        _c("el-radio", {
                          attrs: {
                            label: "list",
                            border: _vm.styleType === "list",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("i", {
                                      staticClass: "iconfont font20 icon-list",
                                      class:
                                        _vm.styleType === "list"
                                          ? "gradient-purple"
                                          : "unbind",
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3722370355
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    style: {
                      margin: "0 10px",
                      width: _vm.isTextToVideo ? "200px" : "400px",
                    },
                  },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "account-input",
                        attrs: {
                          placeholder:
                            _vm.activeTab == "text"
                              ? _vm.$t("copywriting.tipName")
                              : _vm.$t("material.tipName"),
                          clearable: "",
                        },
                        on: { change: _vm.handleQuery },
                        model: {
                          value: _vm.queryParams.searchValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "searchValue", $$v)
                          },
                          expression: "queryParams.searchValue",
                        },
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-input__icon iconfont icon-sousuotubiao",
                          attrs: { slot: "prefix" },
                          on: { click: _vm.handleQuery },
                          slot: "prefix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.$t("refresh"),
                      placement: "top",
                    },
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "mini",
                        circle: "",
                        icon: "el-icon-refresh",
                      },
                      on: { click: _vm.refresh },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingOther,
                  expression: "loadingOther",
                },
              ],
            },
            [
              _c("div", { staticClass: "file-system" }, [
                _vm.currentPath.length > 0
                  ? _c("div", { staticClass: "flex-row-center fileSpace" }, [
                      _c(
                        "div",
                        {
                          staticClass: "breadcrumb",
                          on: { click: _vm.goBack },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("file.goBackMaterial")) + " /"
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "breadcrumb",
                          staticStyle: { "margin-left": "5px" },
                        },
                        _vm._l(
                          _vm.currentPathFolders,
                          function (folder, index) {
                            return _c(
                              "span",
                              {
                                key: folder.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.navigateTo(index)
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(folder.name) + " "),
                                index < _vm.currentPath.length - 1
                                  ? _c("span", [_vm._v(" / ")])
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.styleType === "grid"
                  ? _c(
                      "div",
                      { staticClass: "flex-row f-wrap folders" },
                      _vm._l(_vm.filterFile, function (folder) {
                        return _c("folder", {
                          key: folder.id,
                          attrs: { folder: folder },
                          on: {
                            "open-folder": _vm.openFolder,
                            "edit-folder": _vm.openEditDialog,
                            "delete-folder": _vm.deleteMaterialType,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.styleType === "grid"
                ? _c(
                    "div",
                    { staticClass: "flex-row f-wrap" },
                    _vm._l(_vm.materialList, function (video, index) {
                      return _c("video-grid-item", {
                        key: index.id,
                        attrs: {
                          video: video,
                          isSelectionMode: _vm.isSelectionMode != 0,
                        },
                        on: {
                          "delete-video": _vm.deleteVideo,
                          "edit-video": _vm.editVideo,
                          "view-text": _vm.viewText,
                          "edit-text": _vm.editText,
                          "toggle-selection": _vm.handleToggleSelection,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.styleType === "list"
                ? _c(
                    "div",
                    { staticClass: "w-100 mt10" },
                    [
                      _c("video-list-table", {
                        attrs: {
                          videoList: _vm.mergedList,
                          isSelectionMode: _vm.isSelectionMode != 0,
                        },
                        on: {
                          "open-folder": _vm.openFolder,
                          "edit-folder": _vm.openEditDialog,
                          "delete-folder": _vm.deleteMaterialType,
                          "delete-video": _vm.deleteVideo,
                          "view-text": _vm.viewText,
                          "edit-video": _vm.editVideo,
                          "edit-text": _vm.editText,
                          "toggle-selection": _vm.handleToggleSelection,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.materialList.length == 0
                ? _c("el-empty", { attrs: { "image-size": 200 } })
                : _vm._e(),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.queryParams.pageNum,
                  limit: _vm.queryParams.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryParams, "pageSize", $event)
                  },
                  pagination: _vm.getMaterialList,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("EditDialog", {
        attrs: {
          visible: _vm.editDialogVisible,
          title: _vm.editDialogTitle,
          initialValue: _vm.initialInput,
          initialLabel: _vm.initialLabel,
          labelList: _vm.labelList,
          isSystem: _vm.isSystem,
        },
        on: {
          "update:visible": function ($event) {
            _vm.editDialogVisible = $event
          },
          confirm: _vm.handleConfirm,
        },
      }),
      _c("MaterialUploadDialog", {
        ref: "materialUploadDialog",
        attrs: {
          visible: _vm.uploadDialogVisible,
          title: _vm.uploadTitle,
          list: _vm.list,
          labelList: _vm.labelList,
          materialTypeId: _vm.currentTypeId,
          isSystem: _vm.isSystemFile,
        },
        on: {
          "update:visible": function ($event) {
            _vm.uploadDialogVisible = $event
          },
          closeUpload: _vm.closeUpload,
          addMaterialSuccess: _vm.addMaterialSuccess,
          editMaterialSuccess: _vm.editMaterialSuccess,
          updateFile: _vm.updateFile,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.copywritingVisible,
            title: _vm.copywritingTitle,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.copywritingVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "copywritingForm",
              attrs: {
                model: _vm.copywritingForm,
                "label-width": "80px",
                rules: _vm.rulesCopywriting,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("copywriting.title"), prop: "title" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("tip.input"),
                      maxlength: "50",
                      "show-word-limit": "",
                      disabled: _vm.isSystemFile,
                    },
                    model: {
                      value: _vm.copywritingForm.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.copywritingForm, "title", $$v)
                      },
                      expression: "copywritingForm.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("copywriting.content"),
                    prop: "content",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      maxlength: "500",
                      "show-word-limit": "",
                      placeholder: _vm.$t("tip.input"),
                      autosize: { minRows: 2, maxRows: 4 },
                      disabled: _vm.isSystemFile,
                    },
                    model: {
                      value: _vm.copywritingForm.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.copywritingForm, "content", $$v)
                      },
                      expression: "copywritingForm.content",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("material.position"),
                    prop: "materialTypeId",
                  },
                },
                [
                  _c("treeselect", {
                    attrs: {
                      options: _vm.list,
                      normalizer: _vm.normalizer,
                      clearable: false,
                      placeholder: _vm.$t("file.tipSelectFolder"),
                      disabled: _vm.isSystemFile,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "value-label",
                        fn: function (ref) {
                          var node = ref.node
                          return [_vm._v(" " + _vm._s(node.raw.name) + " ")]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.copywritingForm.materialTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.copywritingForm, "materialTypeId", $$v)
                      },
                      expression: "copywritingForm.materialTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("label.label") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "allow-create": "",
                        filterable: "",
                        multiple: "",
                        placeholder: _vm.$t("label.tipSelect"),
                      },
                      on: { input: _vm.handleInput },
                      model: {
                        value: _vm.copywritingForm.labels,
                        callback: function ($$v) {
                          _vm.$set(_vm.copywritingForm, "labels", $$v)
                        },
                        expression: "copywritingForm.labels",
                      },
                    },
                    _vm._l(_vm.labelList, function (dict) {
                      return _c("el-option", {
                        key: dict.id,
                        attrs: { label: dict.label, value: dict.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.copywritingVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.buttonLoading, type: "primary" },
                  on: { click: _vm.submitCopywriting },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }