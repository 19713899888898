// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/loginBackground.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.bk {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  /* 覆盖整个元素，可能裁剪部分图片 */\n  background-position: center;\n  /* 图片在元素中央显示 */\n  width: 60%;\n  height: 100vh;\n  /* 100% 屏幕高度 */\n}\n.register {\n  height: 100%;\n  background-color: #F7F8FA;\n}\n.register-main {\n  width: 100%;\n  background-color: #F7F8FA;\n  padding-top: 30px;\n}\n.title {\n  cursor: pointer;\n  font-size: 22px;\n  font-weight: 600;\n  color: #1D2129;\n  line-height: 48px;\n}\n.register-form {\n  width: 100%;\n  padding: 0 20% 30px;\n}\n.register-tip {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.register-code {\n  width: 33%;\n  height: 38px;\n  float: right;\n}\n.register-code img {\n  cursor: pointer;\n  vertical-align: middle;\n}\n.el-register-footer {\n  height: 40px;\n  line-height: 40px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n  color: black;\n  font-family: Arial;\n  font-size: 12px;\n  letter-spacing: 1px;\n}", ""]);
// Exports
module.exports = exports;
