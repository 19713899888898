var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("video", {
        ref: "video",
        attrs: { src: _vm.videoUrl, controls: "" },
        on: { timeupdate: _vm.updateCurrentTime },
      }),
      _c("div", { staticClass: "progress-container" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.currentTime,
              expression: "currentTime",
            },
          ],
          ref: "progressBar",
          attrs: { type: "range", max: _vm.videoDuration, step: "0.001" },
          domProps: { value: _vm.currentTime },
          on: {
            click: _vm.seekVideo,
            __r: function ($event) {
              _vm.currentTime = $event.target.value
            },
          },
        }),
        _c(
          "div",
          { staticClass: "markers" },
          _vm._l(_vm.markers, function (marker, index) {
            return _c("div", {
              key: index,
              staticClass: "marker",
              style: { left: (marker.out / _vm.videoDuration) * 100 + "%" },
            })
          }),
          0
        ),
      ]),
      _c(
        "el-tooltip",
        { attrs: { content: _vm.$t("basicInfo.mark") } },
        [
          _c("svg-icon", {
            staticStyle: {
              width: "45px",
              height: "35px",
              border: "1px solid #ccc",
              "border-radius": "5px",
              padding: "5px 10px",
              margin: "10px 20px 5px",
              cursor: "pointer",
            },
            attrs: { "icon-class": "splitAudio" },
            on: { click: _vm.setMarker },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "ml10" }, [
        _c(
          "span",
          { staticStyle: { "font-weight": "bold", "font-size": "18px" } },
          [_vm._v(_vm._s(_vm.$t("basicInfo.segment")))]
        ),
        _c(
          "span",
          { staticStyle: { "font-size": "14px", "margin-left": "5px" } },
          [_vm._v(_vm._s(_vm.$t("basicInfo.selectSegments")))]
        ),
      ]),
      _c(
        "ul",
        _vm._l(_vm.markers, function (marker, index) {
          return _c("li", { key: index }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: marker.checked,
                  expression: "marker.checked",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(marker.checked)
                  ? _vm._i(marker.checked, null) > -1
                  : marker.checked,
              },
              on: {
                change: function ($event) {
                  var $$a = marker.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(marker, "checked", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          marker,
                          "checked",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(marker, "checked", $$c)
                  }
                },
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.formatTime(marker.in)) +
                " - " +
                _vm._s(_vm.formatTime(marker.out)) +
                " "
            ),
            index > 0
              ? _c("i", {
                  staticClass: "el-icon-delete",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.removeMarker(index)
                    },
                  },
                })
              : _vm._e(),
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticStyle: { float: "right", margin: "10px 20px" } },
        [
          _c("el-button", { staticClass: "mb20", on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.$t("cancel"))),
          ]),
          _c(
            "el-button",
            {
              staticClass: "mb20",
              attrs: { type: "primary" },
              on: { click: _vm.confirm },
            },
            [_vm._v(_vm._s(_vm.$t("confirm")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }