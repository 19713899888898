var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { background: "#F2F6F8", padding: "0" },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-row-center mb10",
          staticStyle: { "justify-content": "flex-end" },
        },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("训练记录")]),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("删除")]),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("保存")]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.buttonLoading, type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("发布")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "80px" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "model-bg" },
                  [
                    _c("span", { staticClass: "model-title" }, [
                      _vm._v("文件"),
                    ]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "模型名称", prop: "modelName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入模型名称" },
                          model: {
                            value: _vm.form.modelName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "modelName", $$v)
                            },
                            expression: "form.modelName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "模型描述", prop: "description" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入模型描述" },
                          model: {
                            value: _vm.form.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "description", $$v)
                            },
                            expression: "form.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "训练模块", prop: "modelType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.form.modelType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "modelType", $$v)
                              },
                              expression: "form.modelType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.training_module,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(" " + _vm._s(dict.label) + " ")]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "fileList" } },
                      [
                        _c("videoUpload", {
                          attrs: {
                            limit: 10,
                            fileSize: 50,
                            fileType: _vm.fileType,
                            buttonText: "添加知识库",
                          },
                          model: {
                            value: _vm.form.fileList,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fileList", $$v)
                            },
                            expression: "form.fileList",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("div", { staticClass: "model-bg flex-column" }, [
                  _c("span", { staticClass: "model-title" }, [_vm._v("问答")]),
                  _c(
                    "div",
                    {
                      staticClass: "flex-row-center mb10",
                      staticStyle: { "justify-content": "flex-end" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { loading: _vm.quesLoading, type: "primary" },
                          on: { click: _vm.addQestion },
                        },
                        [_vm._v("添加问答")]
                      ),
                    ],
                    1
                  ),
                  _vm.isShowQuestion
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "问题", prop: "problem" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入问题" },
                                model: {
                                  value: _vm.questionDetail.problem,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionDetail, "problem", $$v)
                                  },
                                  expression: "questionDetail.problem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "回答", prop: "answer" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入答案" },
                                model: {
                                  value: _vm.questionDetail.answer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionDetail, "answer", $$v)
                                  },
                                  expression: "questionDetail.answer",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "flex-row-center mb10",
                      staticStyle: { "justify-content": "flex-end" },
                    },
                    [
                      _c("el-button", { on: { click: _vm.cancelQestion } }, [
                        _vm._v("取消"),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmQestion },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  { staticClass: "model-bg flex-column" },
                  [
                    _c("span", { staticClass: "model-title" }, [
                      _vm._v("预览"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "messages" },
                      _vm._l(_vm.messages, function (message, index) {
                        return _c("ChatMessage", {
                          key: index,
                          attrs: { message: message },
                          on: {
                            submitScript: _vm.submitScript,
                            againVideo: _vm.againVideo,
                            submitVideo: _vm.submitVideo,
                            chooseVideo: _vm.chooseVideo,
                            submitAccount: _vm.submitAccount,
                            submitPost: _vm.submitPost,
                          },
                        })
                      }),
                      1
                    ),
                    _c("ChatInput", {
                      attrs: { disabled: _vm.disabled },
                      on: { send: _vm.handleSend, sendUrl: _vm.handleSendUrl },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }