<template>
  <div class="materialDialog">
    <div class="right-panel">
      <div class="tabs-container">
        <el-tabs v-model="activeTab" @tab-click="tabClick" v-if="!skipInit">
          <el-tab-pane v-if="isSelectionMode==0" :label="$t('material.all')"
                       name="all"></el-tab-pane>
          <el-tab-pane v-if="isSelectionMode==0||isSelectionMode==1||isSelectionMode==5" :label="$t('material.video')"
                       name="video"></el-tab-pane>
          <el-tab-pane v-if="isSelectionMode==0||isSelectionMode==1||isSelectionMode==3" :label="$t('material.picture')"
                       name="image"></el-tab-pane>
          <el-tab-pane v-if="isSelectionMode==0||isSelectionMode==2" :label="$t('material.audio')"
                       name="audio"></el-tab-pane>
          <el-tab-pane v-if="isSelectionMode==0||isSelectionMode==4" :label="$t('material.copywriting')"
                       name="text"></el-tab-pane>
        </el-tabs>
        <div v-if="quota.fileSize" class="flex-row-center quota">
          <span class="mr5">{{$t('quota')}}：</span>
          <Slider :value="formatQuota(quota.useFileSize) || 0" :maxValue="formatQuota(quota.fileSize) ||'10G' "/>
          <div class="ml5">
           {{ quota.useFileSize || 0}}/{{ quota.fileSize || '10G'}}
          </div>
        </div>
      </div>
      <div>
        <div class="flex-row-center f-between w-100">
          <div class="f-shrink">
            <!-- 创建文件夹按钮（第一层和第二层） -->
            <el-button class="gradient-button" icon="el-icon-upload2" @click="addText" v-if="isSelectionMode==0 && activeTab=='text'">{{ $t('copywriting.new') }}</el-button>
            <el-button class="gradient-button" icon="el-icon-upload2" @click="addMaterial" v-if="isSelectionMode==0 && (activeTab=='all'||activeTab=='video'||activeTab=='image'||activeTab=='audio')">{{ $t('file.upload') }}</el-button>
            <el-button class="normal-button" icon="el-icon-folder-add" @click="openAddDialog" v-if="isSelectionMode==0 && currentLevel < 2">{{$t('file.createFolder')}}</el-button>
            <div v-if="isSelectionMode!=0" class="mr10">
              <el-button type="primary" @click="confirmSelection">{{ $t('confirmSelection') }}</el-button>
            </div>
          </div>
          <div class="flex-row-center">
            <el-radio-group v-model="styleType" class="custom-radio-group" v-if="isSelectionMode==0">
              <el-radio label="grid" :border="styleType === 'grid'">
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-grid"
                    :class="styleType === 'grid' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
              <el-radio label="list" :border="styleType === 'list'">
                <template v-slot:default>
                  <i
                    class="iconfont font20 icon-list"
                    :class="styleType === 'list' ? 'gradient-purple' : 'unbind'"
                  ></i>
                </template>
              </el-radio>
            </el-radio-group>
            <div :style="{'margin': '0 10px','width': isTextToVideo ? '200px' : '400px'}">
              <el-input
                class="account-input"
                v-model="queryParams.searchValue"
                :placeholder="activeTab == 'text' ? $t('copywriting.tipName') : $t('material.tipName')"
                @change="handleQuery"
                clearable
              >
                <i slot="prefix" class="el-input__icon iconfont icon-sousuotubiao" @click="handleQuery"></i>
              </el-input>
            </div>
            <el-tooltip class="item" effect="dark" :content="$t('refresh')" placement="top">
              <el-button size="mini" circle icon="el-icon-refresh" @click="refresh" />
            </el-tooltip>
          </div>
          <!-- <el-form class="searchForm flex-row-center f-wrap" :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch">
            <el-form-item label="">
              <el-select v-model="queryParams.labels" @change="handleQuery" clearable
                         :placeholder="$t('label.label')" style="width: 200px" allow-create filterable multiple>
                <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
              </el-select>
            </el-form-item>
          </el-form> -->
        </div>

        <div v-loading="loadingOther">
          <div class="file-system">
            <!-- 显示当前路径 -->
            <div class="flex-row-center fileSpace" v-if="currentPath.length > 0">
              <!-- <el-button type="text" @click="goBack">{{$t('file.goBackMaterial')}}/</el-button> -->
              <!-- <div class="ml10 mr10">/</div> -->
              <div class="breadcrumb" @click="goBack">
                <span>{{$t('file.goBackMaterial')}}&nbsp;/</span>
              </div>
              <div class="breadcrumb" style="margin-left: 5px;">
                <span v-for="(folder, index) in currentPathFolders" :key="folder.id" @click="navigateTo(index)">
                  {{ folder.name }} <span v-if="index < currentPath.length - 1">&nbsp;/&nbsp;</span>
                </span>
              </div>
            </div>
            <!-- 显示当前文件夹 -->
            <div class="flex-row f-wrap folders" v-if="styleType === 'grid'">
              <!-- (currentFolders) -->
              <!-- v-if="filterViewFile(folder)" -->
              <folder v-for="folder in filterFile" :key="folder.id" :folder="folder"
                @open-folder="openFolder"
                @edit-folder="openEditDialog"
                @delete-folder="deleteMaterialType"
              />
            </div>
          </div>
          <div class="flex-row f-wrap" v-if="styleType === 'grid'">
            <video-grid-item
              v-for="(video, index) in materialList"
              :key="index.id"
              :video="video"
              :isSelectionMode="isSelectionMode!=0"
              @delete-video="deleteVideo"
              @edit-video="editVideo"
              @view-text="viewText"
              @edit-text="editText"
              @toggle-selection="handleToggleSelection"/>
          </div>
          <div class="w-100 mt10" v-if="styleType === 'list'">
            <video-list-table
              :videoList="mergedList"
              :isSelectionMode="isSelectionMode!=0"
              @open-folder="openFolder"
              @edit-folder="openEditDialog"
              @delete-folder="deleteMaterialType"
              @delete-video="deleteVideo"
              @view-text="viewText"
              @edit-video="editVideo"
              @edit-text="editText"
              @toggle-selection="handleToggleSelection"/>
          </div>
          <!-- <video-item v-if="activeTab=='video'" v-for="(video, index) in materialList"
                      :key="index" :video="video" :isSelectionMode="isSelectionMode!=0"
                      @delete-video="deleteVideo" @edit-video="editVideo"
                      @toggle-selection="handleToggleSelection"></video-item>
          <image-item v-if="activeTab=='image'" v-for="(image, index) in materialList"
                      :key="index" :image="image" :isSelectionMode="isSelectionMode!=0"
                      @delete-image="deleteVideo" @edit-image="editVideo"
                      @toggle-selection="handleToggleSelection"></image-item>
          <audio-item v-if="activeTab=='audio'" v-for="(audio, index) in materialList"
                      :key="index" :audio="audio" :isSelectionMode="isSelectionMode!=0"
                      @delete-audio="deleteVideo" @edit-audio="editVideo"
                      @toggle-selection="toggleSelectionSingle"></audio-item> -->
          <el-empty :image-size="200" v-if="materialList.length==0"></el-empty>
          <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                      :limit.sync="queryParams.pageSize"
                      @pagination="getMaterialList"/>
        </div>
      </div>
      <!-- <div v-if="activeTab=='text'">
        <el-table v-loading="loading" :data="copywritingList" @selection-change="handleSelectionChange">
          <el-empty :image-size="200" slot="empty"></el-empty>
          <el-table-column :label="$t('copywriting.title')" align="center" prop="title"/>
          <el-table-column :label="$t('copywriting.content')" align="center" prop="content">
            <template slot-scope="scope">
              <el-tooltip effect="dark" :content="scope.row.content" placement="top">
                <span class="content-cell">{{ scope.row.content }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column :label="$t('label.label')" align="center" prop="labels">
            <template slot-scope="scope">
              <el-tag v-for="(tag, index) in scope.row.labels" :key="index"
                      style="margin-right: 5px;margin-bottom: 5px;">
                {{ tag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column :label="$t('time.createTime')" align="center" prop="createTime"/>
          <el-table-column :label="$t('operate')" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button v-if="isSelectionMode==0" size="mini" type="text" icon="el-icon-edit"
                         @click="editText(scope.row)">{{ $t('edit') }}
              </el-button>
              <el-button v-if="isSelectionMode==0" size="mini" type="text" icon="el-icon-delete"
                         @click="deleteVideo(scope.row)">{{ $t('remove') }}
              </el-button>
              <el-button v-if="isSelectionMode==4" type="primary" @click="confirmText(scope.row)">
                {{ $t('confirmSelection') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div> -->
    </div>
    <EditDialog
      :visible.sync="editDialogVisible"
      :title="editDialogTitle"
      :initialValue="initialInput"
      :initialLabel="initialLabel"
      :labelList="labelList"
      :isSystem="isSystem"
      @confirm="handleConfirm"
    />
    <MaterialUploadDialog
      ref="materialUploadDialog"
      :visible.sync="uploadDialogVisible"
      :title="uploadTitle"
      :list="list"
      :labelList="labelList"
      :materialTypeId="currentTypeId"
      :isSystem="isSystemFile"
      @closeUpload="closeUpload"
      @addMaterialSuccess="addMaterialSuccess"
      @editMaterialSuccess="editMaterialSuccess"
      @updateFile="updateFile"
    />
    <el-dialog
      :visible.sync="copywritingVisible"
      :title="copywritingTitle"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form :model="copywritingForm" ref="copywritingForm" label-width="80px" :rules="rulesCopywriting"
               label-position="left">
        <el-form-item :label="$t('copywriting.title')" prop="title">
          <el-input
            v-model="copywritingForm.title"
            :placeholder="$t('tip.input')"
            maxlength="50"
            show-word-limit
            :disabled="isSystemFile"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('copywriting.content')" prop="content">
          <el-input
            type="textarea"
            maxlength="500" show-word-limit
            v-model="copywritingForm.content"
            :placeholder="$t('tip.input')"
            :autosize="{ minRows: 2, maxRows: 4}"
            :disabled="isSystemFile">
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('material.position')" prop="materialTypeId">
          <!-- <el-select v-model="copywritingForm.materialTypeId" :placeholder="$t('file.tipSelectFolder')">
            <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select> -->
          <treeselect
            v-model="copywritingForm.materialTypeId"
            :options="list"
            :normalizer="normalizer"
            :clearable="false"
            :placeholder="$t('file.tipSelectFolder')"
            :disabled="isSystemFile">
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
        </el-form-item>
        <el-form-item :label="$t('label.label')">
          <el-select v-model="copywritingForm.labels" allow-create filterable multiple
                     :placeholder="$t('label.tipSelect')" @input="handleInput">
            <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copywritingVisible = false">{{ $t('cancel') }}</el-button>
        <el-button :loading="buttonLoading" type="primary" @click="submitCopywriting">{{ $t('confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VideoGridItem from '../../components/Material/VideoGridItem.vue';
import VideoListTable from '../../components/Material/VideoListTable.vue';
import VideoItem from '../../components/VideoItem/VideoItem.vue';
import ImageItem from '../../components/ImageItem/ImageItem.vue';
import AudioItem from "../../components/AudioItem/AudioItem.vue";
import EditDialog from "../../components/dialog/editDialog.vue";
import Folder from '../../components/video/Folder.vue';
import Slider from '@/views/account/slider.vue';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {addMaterialType, delMaterialType, editMaterialType, listMaterialType} from '../../api/ai/materialType';
import {addMaterial, delMaterial, editMaterial, listMaterial} from '../../api/ai/material';
import {listLabel} from '../../api/system/label';
import {getQuota} from '../../api/system/merchant';
import MaterialUploadDialog from '../../components/video/MaterialUploadDialog.vue';
import EventBus from "../../utils/EventBus";

export default {
  name: "materialDialog",
  components: {
    VideoGridItem,
    VideoListTable,
    VideoItem,
    ImageItem,
    AudioItem,
    EditDialog,
    Folder,
    Slider,
    MaterialUploadDialog,
    Treeselect
  },
  props: {
    skipInit: {
      type: Boolean,
      default: false
    },
    timeLength: {
      type: Number,
      default: 0
    },
    isTextToVideo: {//文生视频中音乐进来需要修改布局
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 按钮loading
      buttonLoading: false,
      // 遮罩层
      loading: false,//文案列表的loading
      loadingOther: false,//其他列表的loading
      activeTab: 'all',
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 选中数组
      ids: [],
      usernames: [],
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        labels: [],
        label: '',
        searchValue: '',
        fileType: '',//image、video、audio
        materialTypeId: 0,
      },
      // 显示搜索条件
      showSearch: true,
      list: [],
      folders:[],//文件夹列表，与list相同，没有‘全部’
      // 总条数
      total: 0,
      totalCopywriting: 0,
      copywritingList: [],
      editDialogVisible: false,
      editDialogTitle: this.$t('file.editFolder'),
      editMaterialId: null,
      initialInput: '',//默认分类名称
      initialLabel: '',//默认分类标签
      isSystem: false,//是否为系统文件夹
      isSystemFile: false,//是否为系统文件
      initialParentId: '',//默认父文件夹id
      uploadDialogVisible: false,//素材弹窗
      copywritingVisible: false,//文案弹窗
      copywritingForm: {
        title: '',
        content: '',
        label: '',
        labels: [],
        materialTypeId: 0,
        fileType: 'text',
      },
      rulesCopywriting: {
        title: [
          {required: true, message: this.$t('copywriting.tipTitle'), trigger: ['blur', 'change']}
        ],
        content: [
          {required: true, message: this.$t('copywriting.tipContent'), trigger: ['blur', 'change']}
        ],
        // materialTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
      },
      isSelectionMode: 0, // 0-默认值,1-图片、视频多选，2-音频单选，3-图片单选、4-文案单选、5-视频可选(多选)、6-视频单选
      materialList: [],
      uploadTitle: this.$t('material.upload'),//弹窗标题：上传素材or编辑素材
      copywritingTitle: this.$t('copywriting.new'),//新增文案or编辑文案
      labelList: [],//标签
      drawer: false,//素材选择的抽屉
      quota: {},//配额
      currentPath: [], // 用于追踪当前路径
      currentTypeId: 0,  //当前打开的文件夹/素材id
      styleType: 'grid', //grid or list
    };
  },
  computed: {
    filterFile(list) {
      if (this.activeTab === 'all') {
        return this.currentFolders
      }
      if(this.activeTab === 'audio') {
        // return this.currentFolders
        return this.currentFolders.filter(item => item.dataType === 'audio')
      }
      if(this.activeTab !== 'audio' && this.activeTab !== 'all') {
        return this.currentFolders.filter(item => item.dataType !== 'audio')
      }
    },
    currentLevel() {
      return this.currentPath.length;
    },
    currentFolders() {
      let level = this.folders;
      let validPath = true;

      this.currentPath.forEach(id => {
        if (validPath) {
          const folder = level.find(folder => folder.id === id);
          if (folder && folder.children) {
            level = folder.children;
          } else {
            validPath = false; // 如果路径无效，停止继续寻找
            level = [];
          }
        }
      });
      return level;
    },
    //list显示模式下的table数据
    mergedList() {
      // 使用 map 为 currentFolders 添加 type: 'folder'
      let foldersWithType = this.currentFolders.map(folder => {
        // 处理 label 字段
        const labels = folder.label ? folder.label.split(',') : [];
        return {
          ...folder,
          documentType: 'folder',
          labels // 添加 labels 数组
        };
      });
      if(this.activeTab === 'audio') {
        foldersWithType = foldersWithType.filter(item => item.dataType === 'audio')
      }
      if(this.activeTab !== 'audio' && this.activeTab !== 'all') {
        foldersWithType =  foldersWithType.filter(item => item.dataType !== 'audio')
      }
      // 使用 map 为 materialList 添加 type: 'file'
      const materialsWithType = this.materialList.map(material => ({
        ...material,
        documentType: 'file'
      }));

      // 合并文件夹和文件，文件夹优先展示
      const newList = [...foldersWithType, ...materialsWithType];
      return newList
    },
    currentPathFolders() {
      let pathFolders = [];
      let level = this.folders;
      this.currentPath.forEach(id => {
        const folder = level.find(folder => folder.id === id);
        if (folder) {
          pathFolders.push(folder);
          level = folder.children ? folder.children : [];
        }
      });
      return pathFolders;
    }
  },
  created() {},
  mounted() {
    // 初始化数据
    if (!this.skipInit) {
      this.init(0)
      this.getQuota()
    }
    this.getLabels()
    this.getMaterialTypeList();
    EventBus.$on('file-success', this.getMaterialList)
  },
  beforeDestroy() {
    // 在组件销毁前取消事件监听
    EventBus.$off('file-success', this.getMaterialList);
  },
  methods: {
    // filterViewFile(item) {
    //   if ( this.activeTab === 'all') {
    //     return true
    //     // activeTab === 'audio' ? folder.dataType === 'audio' : activeTab === 'all' ? true : false
    //   }
    //   if (this.activeTab === 'audio' && item.dataType === 'audio') {
    //     return true
    //   }
    //   if (this.activeTab !== 'all' || activeTab !== 'audio' && item.dataType !== 'audio') {
    //     return true
    //   }

    //   // this.activeTab = 'audio'
    //   //   this.queryParams.fileType = 'audio'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 3) {
    //   //   this.activeTab = 'image'
    //   //   this.queryParams.fileType = 'image'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 4) {
    //   //   this.activeTab = 'text'
    //   //   this.queryParams.fileType = 'text'
    //   //   this.getMaterialList()
    //   // } else if (isSelectionMode == 5) {
    //   //   this.activeTab = 'video'
    //   //   this.queryParams.fileType = 'video'
    // },
    updateFile() {
      this.getMaterialTypeList()
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel = node.materialCount > 0 ? `  (${node.materialCount})` : '';
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children
      };
    },
    openFolder(folder) {
      this.currentTypeId = folder.id
      this.currentPath.push(folder.id);
      console.log(this.currentPath)
      this.handleItemClick(folder.id);
    },
    goBack() {
      if (this.currentPath.length > 0) {
        this.currentPath = []
        // let id = this.currentPath.length > 0 ? this.currentPath[this.currentPath.length - 1] : 0
        // this.currentTypeId = id
        this.currentTypeId = 0
        this.handleItemClick(0);
      }
    },
    navigateTo(index) {
      this.currentPath = this.currentPath.slice(0, index + 1);
    },
    handleInput(value) {
      if (value != null) {
       var label = value[value.length - 1]
        if(label != null && label.length > 20){
           value[value.length - 1] = label.substring(0, 20)
        }
      }
    },
    //获取配额
    getQuota() {
      getQuota().then(response => {
        this.quota = response.data
      });
    },

    getLabels() {
      listLabel().then(response => {
        this.labelList = response.rows;
      });
    },
    refresh(){
      if(!this.labelList || this.labelList.length == 0){
        this.getLabels()
      }
      if(!this.folders || this.folders.length == 0){
        this.getMaterialTypeList();
      }
      this.getMaterialList()
    },
    init(isSelectionMode) {
      this.isSelectionMode = isSelectionMode
      if (isSelectionMode == 0) {
        this.activeTab = 'all'
        this.queryParams.fileType = ''
        this.getMaterialList()
      } else if (isSelectionMode == 2) {
        this.activeTab = 'audio'
        this.queryParams.fileType = 'audio'
        this.getMaterialList()
      } else if (isSelectionMode == 3) {
        this.activeTab = 'image'
        this.queryParams.fileType = 'image'
        this.getMaterialList()
      } else if (isSelectionMode == 4) {
        this.activeTab = 'text'
        this.queryParams.fileType = 'text'
        this.getMaterialList()
      } else if (isSelectionMode == 5) {
        this.activeTab = 'video'
        this.queryParams.fileType = 'video'
        this.getMaterialList()
      } else {
        this.getMaterialList()
      }
    },
    //素材列表（视频、图片）
    getMaterialList() {
      this.loadingOther = true
      if(this.queryParams.materialTypeId == 0){
        this.queryParams.materialTypeId = null
      }
      listMaterial(this.queryParams).then(response => {
        this.materialList = response.rows;
        this.total = response.total
        this.materialList.forEach(item => {
          item.selected = false
          item.hover = false
          if (item.label) {
            item.labels = item.label.split(',');
          } else {
            item.labels = [];
          }
        })
      }).finally(() => {
        this.loadingOther = false
      });
    },
    closeUpload() {
      this.uploadDialogVisible = false;
    },
    //新增素材（视频、图片）
    addMaterial() {
      if(this.isExceedQuota(this.quota.useFileSize, this.quota.fileSize)){
        this.$modal.msgError(this.$t('material.exceedQuota'))
        return
      }
      this.isSystemFile = false
      this.uploadTitle = this.$t('material.upload')
      this.$nextTick().then((rs) => {
        this.$refs.materialUploadDialog.resetForm();
        this.uploadDialogVisible = true;
      });
    },
    //编辑素材（视频、图片）
    editVideo(item) {
      this.isSystemFile = item.type == 'system'
      this.uploadTitle = this.$t('material.edit')
      this.$nextTick().then((rs) => {
        this.uploadDialogVisible = true;
        this.$refs.materialUploadDialog.init(item);
      });
    },
    //新增素材成功
    addMaterialSuccess(form){
      if (this.activeTab != form.fileType) {
        this.activeTab = form.fileType
        this.queryParams.fileType = form.fileType
        this.currentPath = []
        this.materialList = []
      }
      this.queryParams.materialTypeId = form.materialTypeId || 0
      this.openFolderById(this.queryParams.materialTypeId)
      this.getMaterialList();
      this.getLabels()
    },
    //编辑素材成功
    editMaterialSuccess({fileAddressName}){
      if (fileAddressName) {
        this.$modal.msgSuccess(`已添加至${fileAddressName}文件夹`);
      }
      this.getLabels()//新增或编辑时可能对标签产生修改，需要刷新
      this.getMaterialTypeList()//新增或编辑时会对文件数量产生修改，需要刷新
      this.getMaterialList();
    },
    openFolderById(targetId) {
      // 辅助函数，用于递归查找目标文件夹，并返回路径
      const findFolderPath = (folders, id, path = []) => {
        for (const folder of folders) {
          const newPath = [...path, folder.id]; // 生成新的路径
          if (folder.id === id) {
            return newPath; // 找到目标文件夹，返回路径
          }
          if (folder.children) {
            const result = findFolderPath(folder.children, id, newPath);
            if (result) {
              return result; // 递归查找子文件夹
            }
          }
        }
        return null; // 未找到返回 null
      };

      // 从根文件夹开始查找目标文件夹路径
      const path = findFolderPath(this.folders, targetId);
      if (path) {
        this.currentPath = path; // 更新 currentPath
      } else {
        console.error(`Folder with ID ${targetId} not found.`);
      }
    },
    //删除素材（视频、图片）
    deleteVideo(video) {
      this.$confirm(this.$t('material.tipDelete'), this.$t('tip.tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        // 处理删除逻辑
        delMaterial(video.id).then(response => {
          this.getMaterialList();
          this.getMaterialTypeList()//新增或编辑时会对文件数量产生修改，需要刷新
        });
      })
    },
    //选中素材
    toggleSelection(selected, video) {
      console.log('toggleSelection', selected);
      const index = this.materialList.findIndex(v => v.id === video.id);
      if (index !== -1) {
        this.$set(this.materialList, index, {...this.materialList[index], selected});
        // this.materialList[index].selected = selected;
      }
      console.log('this.materialList', this.materialList);
    },
    //单选
    toggleSelectionSingle(selected, video) {
      console.log('toggleSelectionSingle', selected);
      // 取消所有其他项目的选择
      this.materialList = this.materialList.map(v => ({...v, selected: false}));

      // 选择当前点击的项目
      const index = this.materialList.findIndex(v => v.id === video.id);
      if (index !== -1) {
        this.$set(this.materialList, index, {...this.materialList[index], selected});
      }
      console.log('this.materialList', this.materialList);
    },
    //多选、单选都存在的情况，需要用方法重新处理下逻辑，直接在模板中使用三元运算符会导致事件绑定的问题，致使方法没有响应
    handleToggleSelection(selected, video) {
      if (this.isSelectionMode === 2 || this.isSelectionMode === 3 || this.isSelectionMode === 4 || this.isSelectionMode === 6) {
        this.toggleSelectionSingle(selected, video);
      } else {
        this.toggleSelection(selected, video);
      }
    },
    //新增文案
    addText() {
      this.isSystemFile = false
      this.copywritingTitle = this.$t('copywriting.new')
      this.resetCopywritingForm()
      this.copywritingVisible = true;
    },
    resetCopywritingForm() {
      this.copywritingForm = {
        id: null,
        title: null,
        content: null,
        label: null,
        labels: [],
        materialTypeId: this.currentTypeId,
        fileType: 'text',
      };
    },
    //编辑文案
    editText(row) {
      this.isSystemFile = row.type == 'system'
      this.copywritingTitle = this.$t('copywriting.edit')
      this.copywritingForm = {...row};  // 使用浅拷贝确保数据绑定正确
      if (this.copywritingForm.label) {
        this.copywritingForm.labels = this.copywritingForm.label.split(',')
      }
      this.copywritingVisible = true;
    },
    // 查看文案
    viewText(row) {
      this.$router.push({ path: `/materialDetail/${row.id}`,query: { id: row.id } });
    },
    //增改提交
    submitCopywriting() {
      this.$refs.copywritingForm.validate(valid => {
        if (valid) {
          if (this.copywritingForm.labels.length > 0) {
            this.copywritingForm.label = this.copywritingForm.labels.join(',')
          }
          this.buttonLoading = true;
          if (this.copywritingTitle == this.$t('copywriting.new')) {
            addMaterial(this.copywritingForm).then(res => {
              this.copywritingVisible = false;
              this.queryParams.materialTypeId = this.copywritingForm.materialTypeId || 0
              this.getMaterialList();
            }).finally(() => {
              this.buttonLoading = false;
            });
          } else {
            editMaterial(this.copywritingForm).then(res => {
              this.copywritingVisible = false;
              this.getMaterialList();
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        }
      });
    },


    //素材分类列表
    getMaterialTypeList() {
      listMaterialType().then(response => {
        this.folders = response.data
        this.list = JSON.parse(JSON.stringify(response.data));
        this.list.unshift({
          id: 0,
          name: this.$t('basicInfo.materia'),
          type: 'system',
        });
      });
    },
    tabClick() {
      if (this.activeTab === 'all') {
        this.queryParams.fileType = ''
      } else {
        if (this.activeTab === 'audio') {
          this.queryParams.dataType = 'audio'
        } else {
          this.queryParams.dataType = ''
        }
        this.queryParams.fileType = this.activeTab
      }
      this.tabResetQuery()
    },
    tabResetQuery(){
      this.materialList = []
      // this.currentPath = []
      this.resetQuery()
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getMaterialList();
    },
    //素材重置查询
    resetQuery() {
      this.queryParams.labels = []
      this.queryParams.label = ''
      this.queryParams.name = ''
      this.queryParams.title = ''
      this.queryParams.materialTypeId = this.currentTypeId

      this.getMaterialList()
      if(this.folders.length == 0){
        this.getMaterialTypeList()
      }
    },
    handleItemClick(index) {
      // 处理菜单项点击的逻辑
      console.log('Clicked item with index:', index);
      this.materialList = []
      this.queryParams.materialTypeId = index
      this.getMaterialList()
    },
    openEditDialog(item) {
      this.initialInput = item.name
      this.initialLabel = item.label
      this.isSystem = item.type == 'system'
      this.initialParentId = item.parentId || 0
      this.editMaterialId = item.id
      this.editDialogTitle = this.$t('file.editFolder');
      this.editDialogVisible = true;
    },
    openAddDialog() {
      this.initialInput = ''
      this.initialLabel = ''
      this.isSystem = false
      this.initialParentId = this.currentPath && this.currentPath.length > 0 && this.currentPath[this.currentPath.length - 1] || 0
      this.editMaterialId = null
      this.editDialogTitle = this.$t('file.createFolder');
      this.editDialogVisible = true;
    },
    handleConfirm(form) {
      if (this.editDialogTitle == this.$t('file.editFolder')) {
        editMaterialType({
          id: this.editMaterialId,
          name: form.input,
          parentId: this.initialParentId || 0,
          label:form.labels.join(',')
        }).then(response => {
          this.$modal.msgSuccess(this.$t('tip.update'));
          this.getMaterialTypeList();
        });
      } else {
        // 处理输入内容，例如发送请求或更新数据
        addMaterialType({
          name: form.input,
          parentId: this.initialParentId || 0,
          label:form.labels.join(',')
        }).then(response => {
          this.$modal.msgSuccess(this.$t('tip.add'));
          this.getMaterialTypeList();
        });
      }
    },
    deleteMaterialType(item) {
      this.$confirm(this.$t('file.tipDeleteFolder'), this.$t('tip.tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        // 处理删除逻辑
        delMaterialType(item.id).then(response => {
          this.getMaterialTypeList();
        });
      })
    },
    addMaterialType() {
      addMaterialType({name: input}).then(response => {
        this.getMaterialTypeList();
      });
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.usernames = selection.map(item => item.username)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    // 确认选择操作
    confirmSelection() {
      let selectedItems = this.materialList.filter(item => item.selected);
      if(selectedItems.length == 0){
        this.$modal.msgError(this.$t('material.tipVideo'))
        return
      }
      if(this.timeLength > 0 && selectedItems[0].videoLength < this.timeLength){
        this.$message.error(this.$t('material.timeLimit'));
        return;
      }
      console.log("selectedItems", selectedItems);
      this.$emit('selectionConfirmed', selectedItems);
    },
    confirmText(row) {
      this.$emit('selectionConfirmed', row);
    }
  }
};
</script>

<style scoped>
.materialDialog {
  display: flex;
  background-color: #f5f5f5;
  padding: 10px;
}

.left-panel,
.right-panel {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}

.tabs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quota {
  margin-left: 20px;
}

.left-panel {
  flex: 1;
  max-width: 20%;
  margin-right: 10px;
}

.right-panel {
  flex: 4;
}

.menu-item {
  justify-content: space-between;
}

.menu-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menu-item-icon {
  cursor: pointer;
  margin-left: 5px;
}

.content-cell {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-clamp: 3;
}

.aspect-ratio-box {
  width: 140px;
  height: 140px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
}

.material-container {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.material-thumbnail {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
.file-system {
  padding: 0;
}
.fileSpace {
  padding: 15px 0px 10px 5px;
}
.breadcrumb {
  margin-bottom: 0px;
}
.breadcrumb span {
  cursor: pointer;
  color: #00AAFF;
}
.breadcrumb span:hover {
  text-decoration: underline;
}
.folders, .files {
  margin-top: 10px;
}
.folders div, .files div {
  cursor: pointer;
  /* margin-bottom: 5px; */
}
.folders div:hover, .files div:hover {
  text-decoration: underline;
}
/* button {
  margin-top: 20px;
} */
/* .searchForm .el-form-item{
  margin: 5px;
} */
.custom-radio-group {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
}
.custom-radio-group ::v-deep.el-radio {
  margin-right: 5px;
}
.custom-radio-group ::v-deep.el-radio.is-bordered.is-checked {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-color: rgba(0,0,0,0.05);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
  border-radius: 12px;
}
.custom-radio-group ::v-deep.el-radio__input {
    display: none; /* 隐藏原点 */
}
</style>
