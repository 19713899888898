<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>

    <div class="right-menu flex-row-center">
      <template v-if="device!=='mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <el-tooltip content="源码地址" effect="dark" placement="bottom">
          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- <el-tooltip :content="$t('settings.layoutSize')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->

      </template>

      <!-- 上传进度展示按钮 -->
      <el-badge :value="currentUploadingFiles.length" :max="99" class="item" :hidden="currentUploadingFiles.length === 0">
        <el-tooltip content="传输列表">
          <div class="circle-button flex-center" @click="showUploadProgress">
            <i class="el-icon-sort" style="color: #0156FF;"></i>
          </div>
        </el-tooltip>
      </el-badge>
      <el-dropdown class="avatar-container hover-effect" trigger="click">
        <div class="avatar-wrapper ml10">
          <img :src="avatar" class="user-avatar">
          <span class="ml5 mr5 font12">{{ nickName }}</span>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>{{$t('settings.personalCenter')}}</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>{{$t('settings.layoutSetting')}}</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>{{$t('settings.logout')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 上传进度弹窗 -->
    <el-dialog :visible.sync="progressDialogVisible" :append-to-body="true" >
      <p>上传完成（{{ uploadedCount }}/{{ totalFiles }}）</p>
      <p class="color-gray">-仅展示本次上传进度-</p>
      <div style="max-height: 300px;overflow-y: auto;">
        <div v-for="(file, index) in fileList" :key="file.uid">
          <div>{{ file.name }}</div>
          <div class="flexIcon">
            <el-progress
              :status="progressList[index].status ? progressList[index].status : undefined"
              :stroke-width="10"
              :percentage="progressList[index].percentage || 0">
            </el-progress>
            <i v-if="progressList[index].status === 'exception'" style="color: red;" class="el-icon-warning-outline"></i>
            <i  v-if="progressList[index].status === 'success'" class="el-icon-circle-check" style="color: green;"></i>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="progressDialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import EventBus from "../../utils/EventBus";
import { addMaterial, addMultiMaterial } from "../../api/ai/material";
import { addMultProduct, addProduct } from "../../api/ai/product";
import { getOssToken } from "../../api/ai/ali";
import OSS from "ali-oss";

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc
  },
  data() {
    return {
      progressDialogVisible: false,  // 控制上传进度弹窗
      uploading: false,              // 是否正在上传
      uploadedCount: 0,              // 已上传文件数量
      totalFiles: 0,                 // 文件总数
      fileList:[],                  // 文件列表
      progressList: [],             // 文件上传进度
      credentials: null,            // 阿里云OSS临时访问凭证
      // isUploading: false,
      // queue: []
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'nickName'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    },
    // 计算当前正在上传或待上传的文件数
    currentUploadingFiles() {
      return this.progressList.filter((file, index) => {
        const status = file.status;
        return status!=='exception' && status !== 'success';  // 只计算正在上传或待上传的文件
      });
    },
  },
  mounted() {
    EventBus.$on('post-file', (data, type) => {
      console.log(data, '传输来的文件');
      this.showUploadProgress()
      // this.addToQueue(data, type); // 添加到队列中
      this.handleUpload(data, type)
    })
  },
  methods: {
    // addToQueue(data, type) {
    //   this.queue.push({ data, type })
    //   if (!this.isUploading) {
    //     this.processQueue()
    //   }
    // },
    // addToQueue(data, type) {
    //   let newFiles = [];
    //   newFiles = data.fileList.filter(file =>
    //     !this.fileList.some(existingFile => existingFile.uid === file.uid)
    //   );
    //   if (newFiles.length === 0) {
    //     return;
    //   }
    //   this.fileList = [...this.fileList, ...newFiles];
    //   this.progressList = [
    //     ...this.progressList,
    //     ...Array(newFiles.length).fill().map(() => ({ percentage: 0, status: '' }))
    //   ];
    //   this.queue.push({ data: { fileList: newFiles }, type });
    //   if (!this.isUploading) {
    //     this.processQueue();
    //   }
    // },
    // async processQueue() {
    //   if (this.queue.length === 0) {
    //     this.isUploading = false
    //     return
    //   }

    //   this.isUploading = true
    //   const { data, type } = this.queue.shift()
    //   this.showUploadProgress()
    //   await this.handleUpload(data, type)
    //   this.processQueue()
    // },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$confirm(this.$t('settings.tipLogout'), this.$t('tip.tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/index';
        })
      }).catch(() => {});
    },
    // 显示上传进度弹窗
    showUploadProgress() {
      this.progressDialogVisible = true;
    },
    // 开始异步上传
    async handleUpload(data, type) {
      this.uploading = true;
      this.failedFiles = []; // 用于记录上传失败的文件

      // 累加文件
      this.totalFiles += data.fileList.length;
      this.fileList = this.fileList.concat(data.fileList);

      // 初始化进度列表，默认每个文件的进度为 0
      this.progressList = [
        ...this.progressList,
        ...Array(data.fileList.length).fill().map(() => ({ percentage: 0, status: '' }))
      ];

      for (let i = 0; i < data.fileList.length; i++) {
        // 计算文件的全局索引
        const globalIndex = this.fileList.length - data.fileList.length + i;

        // 尝试上传文件，如果失败将文件存入失败列表
        try {
          await this.uploadFile(data.fileList[i], data, globalIndex, type);
          this.uploadedCount++; // 上传成功后才增加计数
        } catch (error) {
          this.failedFiles.push({ file: data.fileList[i], data, globalIndex, type });
        }
      }

      // // 所有文件上传完毕后，处理失败的文件
      // if (this.failedFiles.length > 0) {
      //   await this.retryFailedFiles();
      // }

      this.uploading = false;
      const allFilesUploaded = this.uploadedCount === this.fileList.length;
      if (allFilesUploaded) {
        this.$message.success('全部文件上传完成');
      } else {
        if(this.uploadedCount) {
          this.$message.success(`${this.uploadedCount}个文件上传完成`);
        }
      }
      // this.$message.success('文件上传完成');
      this.progressDialogVisible = false;
      EventBus.$emit('file-success');
    },

    // 处理失败文件的重试逻辑
    async retryFailedFiles(retryCount = 3) {
      for (let attempt = 1; attempt <= retryCount; attempt++) {
        // 复制当前失败文件列表用于处理
        const failedFilesCopy = [...this.failedFiles];
        this.failedFiles = []; // 清空失败文件列表，只有重试失败的文件加入

        for (const { file, data, globalIndex, type } of failedFilesCopy) {
          try {
            setTimeout(() => {
              this.$set(this.progressList[globalIndex], 'status', '');
            }, 500)
            await this.uploadFile(file, data, globalIndex, type);
            this.uploadedCount++; // 重试成功后增加计数
          } catch (error) {
            console.warn(`文件 ${file.name} 上传失败，正在第 ${attempt} 次重试...`);
            // 重试失败，重新加入失败列表
            this.failedFiles.push({ file, data, globalIndex, type });
            if (!navigator.onLine) {
              this.$message.error('请检查网络连接')
              continue
            }
          }
        }

        if (this.failedFiles.length === 0) {
          console.log('所有失败的文件已重试成功');
          break; // 所有文件成功，退出重试循环
        }

        if (attempt === retryCount) {
          console.error('部分文件上传失败，已重试多次但仍未成功');
        }
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    generateRandomCode(length = 30) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set()
      let code
      do {
        code = this.generateRandomCode(length)
      } while (generatedCodes.has(code))
      generatedCodes.add(code)
      return code
    },
    getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以加1
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}/${month}/${day}`;
    },
    // 上传文件
    async uploadFile(file, data, index, type) {
      return new Promise(async (resolve, reject) => {
        let interval;
        try {
          const client = await this.getOssClient();
          let fakeProgress = 0;
          const maxFakeProgress = Math.floor(Math.random() * (99 - 50 + 1)) + 50; // 随机生成一个 50 到 99 之间的整数
          // 模拟假的进度条
          interval = setInterval(() => {
            if (fakeProgress < maxFakeProgress) {
              fakeProgress += 1; // 每次增加1%
              this.$set(this.progressList[index], "percentage", fakeProgress);
            }
          }, 800); // 每100ms更新一次进度
          const result = await client.put(`file/${this.getCurrentDate()}/${this.generateUniqueCode(30)}${file.name.replace(/^[^.]+/, '')}`, file.raw, {
              progress: (p, checkpoint) => {
                const percentCompleted = Math.round(p * 100);
                console.log(`上传进度 (${file.name}): ${percentCompleted}%`);

                // // 更新进度到 UI
                // this.$set(
                //   this.progressList[index],
                //   "percentage",
                //   percentCompleted
                // );

                // 当上传完成时设置状态为 success
                // if (percentCompleted === 100) {
                //   this.$set(this.progressList[index], "status", "success");
                // }
              },
            });
            console.log(result, '上传至oss的文件');


          // 根据 type 决定调用哪个 API
          const apiCall = type === "material" ? addMaterial : addProduct;
          data.url = result.url;
          data.fileSize = file.size;
          data.name = file.name.replace(/\.[^/.]+$/, '');
          data.fileType = this.getTypeByUrl(file.name);
          apiCall(data, (progressEvent) => {
            // 监听上传进度
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`上传进度 (${file.name}): ${percentCompleted}%`);

            // 更新对应文件的上传进度和状态
            // this.$set(this.progressList[index], 'percentage', percentCompleted);
            // if (percentCompleted === 100) {
            //   this.$set(this.progressList[index], 'status', 'success');
            // } else {
            //   this.$set(this.progressList[index], 'status', '');
            // }
          })
          .then(response => {
            clearInterval(interval);
            this.$set(this.progressList[index], 'percentage', 100);
            this.$set(this.progressList[index], 'status', 'success');
            resolve(response); // 上传成功
          })
          .catch(error => {
            clearInterval(interval);
            console.error(`上传失败 (${file.name}):`, error);
            this.$set(this.progressList[index], 'status', 'exception');
            reject(error); // 上传失败，抛出错误以便重试
          });
        } catch (error) {
          clearInterval(interval);
          console.error(`上传失败 (${file.name}):`, error);
          this.$set(this.progressList[index], 'status', 'exception');
          if (!navigator.onLine) {
            this.$message.error('请检查网络连接')
            return
          }
          reject(error); // 上传失败，抛出错误以便重试
        }
      });
    }
  }
}
</script>

<style lang="scss">
.flexIcon {
    position: relative;
    .el-icon-warning-outline {
      position: absolute;
      right: 16px;
      top: 0;
      font-size: 14px;
    }
    .el-icon-circle-check {
      position: absolute;
      right: 16px;
      top: 0;
      font-size: 14px;
    }
    .el-progress__text i {
      display: none !important;
    }
  }
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  // background: #fff;
  background: #F9FBFC;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        // margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 20px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}

.circle-button {
  background: #F3F3F3;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
}
</style>
