var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container shopContainer",
      staticStyle: { padding: "24px 50px" },
    },
    [
      _vm.detail.sellNickName ? _c("div") : _vm._e(),
      _c(
        "div",
        { staticClass: "flexShopPic" },
        [
          _vm.coverImages.length > 1
            ? _c(
                "el-carousel",
                {
                  staticClass: "carousel-video",
                  attrs: { "indicator-position": "outside" },
                },
                _vm._l(_vm.coverImages, function (url, index) {
                  return _c(
                    "el-carousel-item",
                    {
                      key: index,
                      staticStyle: { width: "448px", height: "448px" },
                    },
                    [
                      _c("img", {
                        staticClass: "image-cover",
                        attrs: { src: url, alt: "image" },
                      }),
                    ]
                  )
                }),
                1
              )
            : _c("img", {
                staticClass: "video-cover",
                attrs: {
                  src: _vm.coverImages && _vm.coverImages[0],
                  alt: "image",
                },
              }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.topLoading,
                  expression: "topLoading",
                },
              ],
              staticClass: "shopDetail",
            },
            [
              _c("div", { staticClass: "video-name" }, [
                _vm._v(_vm._s(_vm.detail.title)),
              ]),
              _c("div", { staticClass: "shopContent" }, [
                _vm._v(" " + _vm._s(_vm.detail.description) + " "),
              ]),
              _c(
                "div",
                { staticClass: "flex-row-center" },
                [
                  _c("i", {
                    staticClass: "iconfont mr5 font20",
                    class: [
                      "icon-" + _vm.detail.platform + "-normal",
                      _vm.detail.platform,
                    ],
                  }),
                  _c("dict-tag", {
                    attrs: {
                      options: _vm.dict.type.social_platform,
                      value: _vm.detail.platform,
                    },
                  }),
                ],
                1
              ),
              _c("ShopDetailTag", {
                attrs: { list: _vm.dict.type.goods_year },
                on: { selectTag: _vm.selectTag },
              }),
              _c("ShopDetailTag", {
                attrs: { list: _vm.dict.type.goods_fans },
                on: { selectTag: _vm.selectTag },
              }),
              _c("ShopDetailTag", {
                attrs: { list: _vm.dict.type.goods_country },
                on: { selectTag: _vm.selectTag },
              }),
              _c("div", { staticClass: "shopCount" }, [
                _vm._v(" 库存: " + _vm._s(_vm.count) + " "),
              ]),
              _vm.account.money
                ? _c("div", { staticClass: "priceBox" }, [
                    _c("span", { staticClass: "nowPriceTitle" }, [
                      _vm._v(_vm._s(_vm.$t("mall.discountPrice")) + "："),
                    ]),
                    _c("span", { staticClass: "nowPrice" }, [
                      _c("span", { staticClass: "priceLeftText" }, [
                        _vm._v("¥"),
                      ]),
                      _vm._v(_vm._s(_vm.account.money)),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex-row-center mt5" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "shopBtn",
                      attrs: {
                        loading: _vm.loadingBuy,
                        type: "primary",
                        disabled: !_vm.count,
                      },
                      on: { click: _vm.addEmail },
                    },
                    [_vm._v(_vm._s(_vm.$t("mall.buyNow")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "shopCarBtn",
                      attrs: { loading: _vm.loadingCart, disabled: !_vm.count },
                      on: { click: _vm.addCart },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-gongzuotaibeifen",
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.detail.inCart
                            ? _vm.$t("mall.addedToCart")
                            : _vm.$t("mall.addToCart")
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "video-name mt20" }, [
        _vm._v(_vm._s(_vm.$t("mall.productDetails"))),
      ]),
      _c(
        "div",
        { staticClass: "flex-row-center" },
        [
          _vm._v(" " + _vm._s(_vm.$t("mall.accountType")) + "： "),
          _c("i", {
            staticClass: "iconfont mr5 font20",
            class: [
              "icon-" + _vm.detail.platform + "-normal",
              _vm.detail.platform,
            ],
          }),
          _c("dict-tag", {
            attrs: {
              options: _vm.dict.type.social_platform,
              value: _vm.detail.platform,
            },
          }),
        ],
        1
      ),
      _vm.detail.followerCount
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.followers")) +
                "：" +
                _vm._s(_vm.detail.followerCount)
            ),
          ])
        : _vm._e(),
      _vm.detail.followingCount
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.following")) +
                "：" +
                _vm._s(_vm.detail.followingCount)
            ),
          ])
        : _vm._e(),
      _vm.detail.linksCount
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.likes")) +
                "：" +
                _vm._s(_vm.detail.linksCount)
            ),
          ])
        : _vm._e(),
      _vm.detail.videoCount
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.videos")) +
                "：" +
                _vm._s(_vm.detail.videoCount)
            ),
          ])
        : _vm._e(),
      _vm.detail.bioDescription
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.details")) +
                "：" +
                _vm._s(_vm.detail.bioDescription)
            ),
          ])
        : _vm._e(),
      _vm.detail.businessType
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.businessDirection")) +
                "：" +
                _vm._s(_vm.detail.businessType)
            ),
          ])
        : _vm._e(),
      _vm.detail.country
        ? _c("div", { staticClass: "mt5" }, [
            _vm._v(
              _vm._s(_vm.$t("mall.accountCountry")) +
                "：" +
                _vm._s(_vm.detail.country)
            ),
          ])
        : _vm._e(),
      _vm.detail.detail
        ? _c("div", {
            staticClass: "mt20",
            domProps: { innerHTML: _vm._s(_vm.detail.detail) },
          })
        : _vm._e(),
      _c("EmailDialog", {
        attrs: { visible: _vm.emailVisible },
        on: {
          "update:visible": function ($event) {
            _vm.emailVisible = $event
          },
          confirm: _vm.createOrder,
        },
      }),
      _c("PayDialog", {
        ref: "payDialog",
        attrs: { visible: _vm.payVisible, orderInfo: _vm.orderInfo },
        on: {
          "update:visible": function ($event) {
            _vm.payVisible = $event
          },
          paySuccess: _vm.paySuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }