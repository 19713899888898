<template>
  <div class="voice-container">
    <el-alert
      v-if="type == 0"
      title="当前的音色选择只对中文生效，其他语种已自动适配"
      type="warning"
      show-icon
      :closable="false"
    ></el-alert>
    <div class="flex-row-center" style="justify-content: flex-end;">
      <!-- <el-tooltip :content="$t('editVideo.preview')">
        <el-button icon="el-icon-video-play" circle @click="play"></el-button>
      </el-tooltip> -->
      <!-- <el-button v-if="type !== 0" type="primary" @click="confirm">{{ $t('confirmSelection') }}</el-button> -->
    </div>
    <el-radio-group
      v-model="item"
      @input="selectItem"
      class="custom-radio-group">
      <div v-for="(voices, category) in voiceList" :key="category" class="voice-category">
        <div class="category w-100">{{ category }}</div>
        <div class="voice-items">
          <el-radio
            v-for="voice in voices"
            :key="voice.id"
            :label="voice.voice"
            class="voice-item"
            :style="{ width: computedWidth }"
          >
            {{ voice.name }}
            <el-tooltip :content="$t('editVideo.preview')">
              <i class="el-icon-video-play" @click.stop="play" style="font-size: 16px;"></i>
              <!-- <el-button size="small" icon="el-icon-video-play" circle @click="play"></el-button> -->
            </el-tooltip>
          </el-radio>
        </div>
      </div>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  name: 'Voice',
  components: {  },
  props: {
    voiceList: {
      type: Object,
      default: () => ({})
    },
    // 0-文生视频，1-视频编辑、AI设置
    type: {
      type: Number,
      default: 0
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      item: this.value,
      voice: this.getVoice(this.value),
      currentAudio: null, // 新增：当前播放的音频实例
    }
  },
  computed: {
    computedWidth() {
      return this.type === 0 ? 'calc(33% - 10px)' : 'calc(20% - 10px)';
    },
  },
  beforeDestroy(){
    this.closeAudio()
  },
  methods: {
    getVoice(voice) {
      // 通过遍历 voiceList 找到当前选中的 voice 对象
      for (const category in this.voiceList) {
        const selectedVoice = this.voiceList[category].find(it => it.voice === voice);
        if (selectedVoice) {
          return selectedVoice
        }
      }
     return null
    },
    // 外面获取到的值
    getSelect(){
      return this.voice
    },
    confirm(){
      if(!this.voice){
        this.$modal.msgError(this.$t('editVideo.selectAudio'))
        return
      }
      this.$emit('confirm', this.voice)
    },
    selectItem() {
      this.closeAudio()
      // 通过遍历 voiceList 找到当前选中的 voice 对象
      let selectedVoice = null;
      for (const category in this.voiceList) {
        selectedVoice = this.voiceList[category].find(it => it.voice === this.item);
        if (selectedVoice) break; // 找到后直接跳出循环
      }
      this.voice = selectedVoice
      if(this.type == 0){
        this.$emit('item-selected', this.item); // 传递当前选中的 item
      }
    },
    play(){
      if(!this.voice.voiceUrl){
        this.$modal.msgError(this.$t('editVideo.selectAudio'))
        return
      }
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null;
      }else{
        this.currentAudio = new Audio(this.voice.voiceUrl); // 创建新的音频实例
        this.currentAudio.play(); // 播放音频
      }
    },
    closeAudio(){
      if (this.currentAudio) {
        this.currentAudio.pause(); // 停止当前播放的音频
        this.currentAudio.currentTime = 0; // 重置播放时间
        this.currentAudio = null; // 清空当前音频实例
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.voice-container{
  width: 100%;
}
.voice-category {
  margin-bottom: 20px;
}
.category{
  margin-bottom: 20px;
  font-size: 20px;
  color: blue;
}

.voice-items {
  display: flex;
  flex-wrap: wrap;
}

.voice-item {
  // width: calc(20% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
