<!-- 素材上传 -->
<template>
  <el-dialog
    class="publicDialog"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleBeforeClose"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ title }}</div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <el-form :model="form" ref="uploadForm" label-width="100px" :rules="rules">
      <el-form-item :label="$t('file.file')" prop="fileList" v-if="title==$t('material.upload')">
        <!-- <videoUpload v-model="form.fileList" :limit="1"/> -->
        <videoUploadMultifile v-model="form.fileList" :limit="10"/>
        <div style="font-size: 14px;line-height: 18px;margin-top: 5px;">{{ $t('material.tipRemoveSubtitle') }}</div>
      </el-form-item>
      <el-form-item :label="$t('file.name')" prop="name" v-if="title==$t('material.edit')">
        <el-input
          v-model="form.name"
          :placeholder="$t('tip.input')"
          maxlength="20"
          show-word-limit
          :disabled="isSystem"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('material.isStripped')" prop="isSplitVideoParts" v-if="title==$t('material.upload')">
        <!-- v-if="form.suffix=='mp4'||form.suffix=='avi'||form.suffix=='mkv'||form.suffix=='mov'" -->
        <el-radio-group v-model="form.isSplitVideoParts">
          <el-radio :label="true">{{$t('yes')}}</el-radio>
          <el-radio :label="false">{{$t('no')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="needMaterialTypeId" :label="$t('material.position')" prop="materialTypeId">
        <!-- <el-select v-model="form.materialTypeId" :placeholder="$t('file.tipSelectFolder')">
          <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select> -->
        <div class="flexTreeselect">
          <treeselect
            v-model="form.materialTypeId"
            :options="treeFileList"
            :show-count="false"
            :normalizer="normalizer"
            :clearable="false"
            :load-options="loadOptions"
            :placeholder="$t('file.tipSelectFolder')"
            :disabled="isSystem"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
          <el-tooltip class="item" effect="dark" content="新建文件夹" placement="top">
            <el-button type="primary" icon="el-icon-plus" @click="openAddFile"></el-button>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item :label="$t('file.description')" prop="description">
        <el-input v-model="form.description" :placeholder="$t('tip.input')" maxlength="500"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label="$t('label.label')">
        <el-select
          ref="searchSelect"
          style="width: 100%;"
          v-model="form.labels"
          allow-create
          filterable
          multiple
          default-first-option
          :placeholder="$t('label.tipSelect')"
          @input="handleInput">
          <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('material.removeSubtitle')" prop="isEraseVideoSubtitles" v-if="title==$t('material.upload')">
        <!-- v-if="form.suffix=='mp4'" -->
        <el-switch
          v-model="form.isEraseVideoSubtitles"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('material.aiFaceSwap')" prop="isMergeVideoFace" v-if="title==$t('material.upload')">
      <!-- v-if="form.suffix=='mp4'||form.suffix=='avi'||form.suffix=='mkv'||form.suffix=='mov'" -->
        <el-switch
          v-model="form.isMergeVideoFace"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
        <el-form-item v-if="form.isMergeVideoFace==1" prop="mergeVideoFaceImageUrl">
          <div v-if="!form.mergeVideoFaceImageUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial">
            <i class="el-icon-upload"></i>
            <div style="margin-top: 5px;">
              {{ $t('basicInfo.materialImport') }}
            </div>
          </div>
          <div v-else
               class="material-container ml80"
               @mouseover="form.hover = true"
               @mouseleave="form.hover = false"
          >
            <img :src="form.mergeVideoFaceImageUrl" alt="material" class="material-thumbnail"/>
            <div v-if="form.hover" class="overlay">
              <div @click="removeMaterial">{{ $t('basicInfo.remove') }}</div>
            </div>
          </div>
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('material.retainOriginalVideo')" prop="isReserve"
                    v-if="form.isEraseVideoSubtitles==1||form.isMergeVideoFace==1">
        <el-switch
          v-model="form.isReserve"
          :active-value="0"
          :inactive-value="1">
        </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancelBtn" @click="handleCancel">{{ $t('cancel') }}</el-button>
      <el-button class="submitBtn" :loading="buttonLoading" type="primary" @click="submitUpload">{{ $t('confirm') }}</el-button>
    </div>
    <el-drawer
      append-to-body
      style="z-index: 9999;"
      :title="$t('mixing.materia')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false">
      <Material ref="materialLibrary" name="Material" :skipInit="true" @selectionConfirmed="selectionConfirmed"></Material>
    </el-drawer>
    <addFolderDialog
      v-if="folderDialogVisible"
      :folderDialogVisible="folderDialogVisible"
      :labelList="labelList"
      @close="closeFolderDialog"
      @submitAddFile="submitAddFile"
    />
  </el-dialog>
</template>
<script>
import VideoUpload from "../VideoUpload/index.vue";
import VideoUploadMultifile from "../VideoUpload/multifile.vue";
import Material from "@/components/materialDialog/index.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { addMaterial, editMaterial } from "../../api/ai/material";
import {listMaterialType} from '../../api/ai/materialType';
import EventBus from '../../utils/EventBus'
import addFolderDialog from "../dialog/addFolderDialog.vue";
export default {
  name: 'MaterialUploadDialog',
  components: {
    VideoUpload,
    VideoUploadMultifile,
    Material,
    Treeselect,
    addFolderDialog
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    },
    labelList: {
      type: Array,
      default: () => []
    },
    // 是否需要选择：切片里子数据不需要
    needMaterialTypeId: {
      type: Boolean,
      default: true
    },
    //默认选择的素材分类
    materialTypeId: {
      type: Number,
      default: 0
    },
    isSystem: {//系统文件不允许编辑名称、文件夹位置
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      folderDialogVisible: false,
      treeFileList: [],
      form: {
        fileList: [],
        ossId: '',
        url: '',
        name: '',
        description: '',
        materialTypeId: this.materialTypeId,
        labels: [],
        label: '',
        fileType: '',
        isReserve: 0,//0保留原素材，1不保留（如果下边这俩都不选，写死0，）
        isEraseVideoSubtitles: 0,//0默认，1去字幕（默认传0）
        isMergeVideoFace: 0,//0默认，1 ai换脸融合（默认传0）
        mergeVideoFaceImageUrl: '',//换脸图片地址
        hover: false,
        suffix: '',//后缀
        isSplitVideoParts: false,//是否切片
        oldFileAddressId: null
      },
      rules: {
        fileList: [
          {required: true, message: this.$t('material.tipSelect'), trigger: ['blur', 'change']}
        ],
        description: [
          {max: 500, message: this.$t('material.formRulesNameLength'), trigger: "blur"}
        ],
        name: [
          {required: true, message: this.$t('file.tipName'), trigger: ['blur', 'change']},
          {
            max: 20,
            message: this.$t('material.formRulesNameLength'),
            trigger: "blur",
          },
        ],
        // materialTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
        mergeVideoFaceImageUrl: [
          {
            validator: (rule, value, callback) => {
              if (this.form.isMergeVideoFace === 1 && !value) {
                callback(new Error(this.$t('material.provideFaceSwapMaterial')));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      },
      drawer: false,//素材选择的抽屉
      buttonLoading: false,//提交的loading
      maxFileCount: 10,//上传文件的最大数量
      fileSize: 500,//上传文件的最大大小
      fileType: ["png", "jpg", "jpeg", "webp",
      "mp4", "avi", "mkv", "mov",
      "mp3", "aac", "wav","m4a"],//上传文件类型
    };
  },
  watch: {
    'form.fileList': {
      handler(newFileList, oldFileList) {
        console.log('文件列表已变化:', newFileList);
        // 在这里添加你需要的逻辑，例如：
        // this.changeName(newFileList);
      },
      deep: true,
      immediate: true,
    },
    list: {
      handler(newVal) {
        this.treeFileList = newVal
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submitAddFile() {
      listMaterialType().then(response => {
        this.treeFileList = JSON.parse(JSON.stringify(response.data));
        this.treeFileList.unshift({
          id: 0,
          name: this.$t('basicInfo.materia'),
          type: 'system',
        });
      });
    },
    closeFolderDialog() {
      this.folderDialogVisible = false
    },
    openAddFile() {
      this.folderDialogVisible = true
    },
    //编辑时赋值
    init(item){
      this.form = {...item};  // 使用浅拷贝确保数据绑定正确
      // this.oldFileAddressId = this.form.materialTypeId
      if (this.form.label) {
        this.form.labels = this.form.label.split(',')
      }
      this.materialTypeId = this.form.materialTypeId
    },
    //重置form
    resetForm() {
      this.form = {
        id: '',
        fileList: [],
        ossId: '',
        url: '',
        name: '',
        description: '',
        materialTypeId: this.materialTypeId,
        labels: [],
        label: '',
        fileType: '',
        isReserve: 0,
        isEraseVideoSubtitles: 0,
        isMergeVideoFace: 0,
        mergeVideoFaceImageUrl: '',
        hover: false,
        suffix: '',
        isSplitVideoParts: false
      }
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([])
      // // 调用 API 获取子节点
      // fetchChildren(node.id)
      //   .then(response => {
      //     const formattedOptions = response.data.map(item => ({
      //       id: item.id,
      //       label: item.name,
      //       hasChildren: item.hasChildren,
      //     }));
      //     callback(formattedOptions);
      //   })
      //   .catch(error => {
      //     console.error('加载选项失败:', error);
      //     callback([]);
      //   });
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel = node.materialCount > 0 ? `  (${node.materialCount})` : '';
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children
      };
    },
    //控制标签输入的文本不超过20位
    handleInput(value) {
      if (value != null) {
       var label = value[value.length - 1]
        if(label != null && label.length > 20){
           value[value.length - 1] = label.substring(0, 20)
        }
      }
    },
    changeName(newFileList) {
      if (newFileList && newFileList.length > 0) {
        this.form.name = newFileList[0].name
        this.form.suffix = newFileList[0].name.split('.').pop()
      } else {
        this.form.suffix = ''
      }
    },
    //人脸选择
    chooseMaterial() {
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(3);
      });
    },
    //换脸图片选择成功
    selectionConfirmed(items) {
      if (items.length == 1) {
        this.form.mergeVideoFaceImageUrl = items[0].url
      }
      this.drawer = false
    },
    removeMaterial() {
      this.form.mergeVideoFaceImageUrl = ''
    },
    handleBeforeClose(done) {
      this.close()
    },
    handleCancel() {
      this.close()
    },
    close(){
      this.buttonLoading = false;
      this.$emit('update:visible', false);
      this.$emit('closeUpload')
    },
    flattenTree(data) {
        const result = [];
        function recurse(node) {
            result.push({
                id: node.id,
                name: node.name,
                type: node.type,
                materialCount: node.materialCount,
                parentId: node.parentId,
                merchantId: node.merchantId,
                dataType: node.dataType,
                label: node.label,
            });
            if (node.children) {
                node.children.forEach(child => recurse(child));
            }
        }
        data.forEach(item => recurse(item));
        return result;
    },
    //增改提交
    submitUpload() {
      this.$refs.uploadForm.validate(valid => {
        if (valid) {
          // if (this.title == this.$t('material.upload')) {
          //   this.form.ossId = this.form.fileList[0].ossId
          //   this.form.url = this.form.fileList[0].url
          //   this.form.fileType = this.getTypeByUrl(this.form.url)
          // }
          if (this.form.labels.length > 0)
            this.form.label = this.form.labels.join(',')
          this.buttonLoading = true;
          if (this.title == this.$t('material.upload')) {
            this.buttonLoading = false;
            this.close()
            this.$emit('updateFile')
            EventBus.$emit('post-file', this.form, 'material');
            // addMaterial(this.form).then(res => {
            //   this.$emit('addMaterialSuccess', this.form);
            //   this.close()
            // }).finally(() => {
            //  this.buttonLoading = false;
            // });
          } else {
            let name = ''
            if (this.form.materialTypeId !== this.materialTypeId) {
              const newList = this.flattenTree(this.list)
              const select = newList.find(item => item.id === this.form.materialTypeId)||{}
              name = select.name
            }
            editMaterial(this.form).then(res => {
              this.close()
              this.$nextTick(() => {
                this.$emit('editMaterialSuccess', { ...this.form, fileAddressName: name });
              });
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        }
      });
    },
  }
};
</script>
<style lang="scss">
.dialog-footer {
  text-align: right;
}
.flexTreeselect {
  display: flex;
  .el-button {
    margin-left: 8px;
    width: 35px;
    display: flex;
    justify-content: center;
  }
}
.material-thumbnail {
  width: 100px;
  height: 100px;
}
</style>
