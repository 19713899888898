<template>
  <div>
    <!-- <div class="videoTitle">{{$t('mixing.mixSet')}}</div>
    <div class="set-des">{{$t('videoSetting.visualExperience')}}</div> -->
    <el-collapse accordion>
      <!-- Logo上传 -->
      <el-collapse-item>
        <template slot="title" >
          <span style="width: 60px;">Logo</span>
          <div class="image-thumbnail-multiple">
            <img v-if="settings.logoTrackClipsBo && settings.logoTrackClipsBo.mediaUrl" :src="settings.logoTrackClipsBo.mediaUrl" class="image-thumbnail"/>
          </div>
        </template>
        <el-form :model="settings" label-position="top" label-width="80px">
          <el-form-item label="" style="width: 100%;">
            <!-- <imageUpload v-model="settings.logoTrackClipsBo.mediaUrl" :limit="1" @input="handleItemUpdated"/> -->
            <div class="material-container"
              v-if="settings.logoTrackClipsBo && settings.logoTrackClipsBo.mediaUrl"
              @mouseover="settings.logoTrackClipsBo.hover = true"
              @mouseleave="settings.logoTrackClipsBo.hover = false"
            >
              <img :src="settings.logoTrackClipsBo.mediaUrl" alt="material" class="material-thumbnail"/>
              <div v-if="settings.logoTrackClipsBo.hover" class="overlay">
                <div  @click="removeMaterial(3)">{{$t('basicInfo.remove')}}</div>
              </div>
            </div>
            <!-- <div v-else  class="aspect-ratio-box flex-column" @click="chooseMaterial(3)">
              <i class="el-icon-upload"></i>
              <div style="margin-top: 5px;">
                {{$t('basicInfo.materialImport')}}
              </div>
            </div> -->
            <picUpload
              v-else
              @picSelectMaterial="picSelectMaterial"
              @showLocaPic="showLocaPic"
            />

          </el-form-item>
          <el-form-item :label="$t('mixing.position')">
            <el-radio-group v-model="settings.logoTrackClipsBo.position" @input="logoChange">
                <el-radio label="left-top" style="margin-bottom: 10px;">{{$t('mixing.leftTop')}}</el-radio>
                <el-radio label="center-top" style="margin-bottom: 10px;">{{$t('mixing.centerTop')}}</el-radio>
                <el-radio label="right-top" style="margin-bottom: 10px;">{{$t('mixing.rightTop')}}</el-radio>
                <el-radio label="left-bottom" style="margin-bottom: 10px;">{{$t('mixing.leftBottom')}}</el-radio>
                <el-radio label="center-bottom" style="margin-bottom: 10px;">{{$t('mixing.centerBottom')}}</el-radio>
                <el-radio label="right-bottom" style="margin-bottom: 10px;">{{$t('mixing.rightBottom')}}</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <!-- 封面上传 -->
      <el-collapse-item>
        <template slot="title">
         <span style="width: 60px;">{{$t('mixing.cover')}}</span>
         <div class="image-thumbnail-multiple">
           <img v-if="settings.coverUrl" :src="settings.coverUrl" class="image-thumbnail"/>
         </div>
        </template>
        <!-- <imageUpload v-model="settings.coverUrl" :limit="1" style="margin-left: 80px;" @input="handleItemUpdated"/> -->
        <!-- <div v-if="!settings.coverUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial(4)">
          <i class="el-icon-upload"></i>
          <div style="margin-top: 5px;">
            {{$t('basicInfo.materialImport')}}
          </div>
        </div> -->
        <picUpload
          v-if="!settings.coverUrl"
          @picSelectMaterial="chooseMaterial(4)"
          @showLocaPic="showLocaPic4"
        />
        <div v-else
          class="material-container"
          @mouseover="settings.coverUrlHover = true"
          @mouseleave="settings.coverUrlHover = false"
        >
          <img :src="settings.coverUrl" alt="material" class="material-thumbnail"/>
          <div v-if="settings.coverUrlHover" class="overlay">
            <div  @click="removeMaterial(4)">{{$t('basicInfo.remove')}}</div>
          </div>
        </div>
      </el-collapse-item>
      <!-- 封底上传 -->
      <el-collapse-item>
        <template slot="title">
         <span style="width: 60px;">{{$t('mixing.backCover')}}</span>
         <div class="image-thumbnail-multiple">
           <img v-if="settings.backCoverUrl" :src="settings.backCoverUrl" class="image-thumbnail"/>
         </div>
        </template>
        <!-- <imageUpload v-model="settings.backCoverUrl" :limit="1" style="margin-left: 80px;" @input="handleItemUpdated"/> -->
        <!-- <div v-if="!settings.backCoverUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial(5)">
          <i class="el-icon-upload"></i>
          <div style="margin-top: 5px;">
            {{$t('basicInfo.materialImport')}}
          </div>
        </div> -->
        <picUpload
          v-if="!settings.backCoverUrl"
          @picSelectMaterial="chooseMaterial(5)"
          @showLocaPic="showLocaPic5"
        />
        <div v-else
          class="material-container"
          @mouseover="settings.backCoverUrlHover = true"
          @mouseleave="settings.backCoverUrlHover = false"
        >
          <img :src="settings.backCoverUrl" alt="material" class="material-thumbnail"/>
          <div v-if="settings.backCoverUrlHover" class="overlay">
            <div  @click="removeMaterial(5)">{{$t('basicInfo.remove')}}</div>
          </div>
        </div>
      </el-collapse-item>

    </el-collapse>
    <el-drawer
      :title="$t('mixing.materia')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
    >
      <Material_library ref="materialLibrary" :skipInit="true" @selectionConfirmed="selectionConfirmed"></Material_library>
    </el-drawer>
  </div>
</template>

<script>
import Material_library from '../../views/aivideo/material.vue';
import picUpload from '../../components/picUpload/index.vue';

export default {
  components: { Material_library,picUpload },
  props:{
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    }
  },
  watch: {
    value(val) {
      console.log('-----mix----', val);
      this.settings = {
        coverUrl: '', // 封面
        backCoverUrl: '', // 封底
        coverUrlHover: false, // 封面 hover
        backCoverUrlHover: false, // 封底 hover
        logoTrackClipsBo: {
          type: 'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
          height: 200,
          mediaUrl: '',
          position: 'left-top',
          hover: false
        },
        ...this.value
      };
      if(!this.settings.logoTrackClipsBo){
        this.settings.logoTrackClipsBo = {
          type: 'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
          height: 200,
          mediaUrl: '',
          position: 'left-top',
          hover: false
        }
      }
      console.log('-----mix111----', this.settings);
    }
  },
  data() {
    return {
      settings: {
        coverUrl:'',//封面
        backCoverUrl:'',//封底
        coverUrlHover:false,//封面hover
        backCoverUrlHover:false,//封底hover
        logoTrackClipsBo:{
          type:'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
		      height: 200,
          mediaUrl:'',
          position: 'left-top',
          hover:false
        },
        ...this.value
      },
      drawer:false,//素材选择的抽屉
      drawerMaterialType:0,//2-音频（背景音乐）、3-logo、4-封面、5-封底
    };
  },
  created(){

  },
  methods: {
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit('item-updated', this.settings);
    },
    init(data) {
      this.settings = {...data}
      console.log('mixingSetting', this.settings);
    },

    picSelectMaterial() {
      this.chooseMaterial(3)
    },
    //选择素材：2-音频（背景音乐）、3-logo、4-封面、5-封底
    chooseMaterial(num){
      this.drawerMaterialType = num
      this.drawer = true
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(type);
      });
    },
    showLocaPic(items) {
      this.drawerMaterialType = 3
      this.selectionConfirmed(items)
    },
    showLocaPic4(items) {
      this.drawerMaterialType = 4
      this.selectionConfirmed(items)
    },
    showLocaPic5(items) {
      this.drawerMaterialType = 5
      this.selectionConfirmed(items)
    },
    selectionConfirmed(items) {
      if(items.length==1){
        if(this.drawerMaterialType==3){
          this.settings.logoTrackClipsBo.mediaUrl = items[0].url
        }else if(this.drawerMaterialType==4){
          this.settings.coverUrl = items[0].url
        }else if(this.drawerMaterialType==5){
          this.settings.backCoverUrl = items[0].url
        }
      }
      console.log('选中的素材:', items)
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
      this.handleItemUpdated()
    },
    removeMaterial(type){
      if(type == 3){
        this.settings.logoTrackClipsBo.mediaUrl = ''
      }else if(type == 4){
        this.settings.coverUrl = ''
      }else if(type == 5){
        this.settings.backCoverUrl = ''
      }
      this.handleItemUpdated()
    },
    logoChange(){
      const w = 253
      const h = 450
      switch(this.settings.logoTrackClipsBo.position){
        case 'left-top':
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'center-top':
          this.settings.logoTrackClipsBo.x = 0.5;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'right-top':
          this.settings.logoTrackClipsBo.x = 0.9;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'left-bottom':
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0.9;
          break;
        case 'center-bottom':
          this.settings.logoTrackClipsBo.x = 0.5;
          this.settings.logoTrackClipsBo.y = 0.9;
          break;
        case 'right-bottom':
          this.settings.logoTrackClipsBo.x = 0.9;
          this.settings.logoTrackClipsBo.y = 0.0;
          break;
        default:
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0;
          break;
      }
      this.handleItemUpdated()
    },
  }
};
</script>

<style scoped>
.filter-preview, .transition-preview {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.audio-player {
  width: 100%;
  margin-top: 5px;
}
.checkbox-group,
.custom-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-group .el-checkbox,
.custom-radio-group .el-radio {
  width: calc(50% - 10px);/* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
}
.checkbox-group .el-checkbox:nth-child(2n),
.custom-radio-group .el-radio:nth-child(2n){
  margin-right: 0;
}

/* 只对 custom-radio-group 内的第一个和第二个 el-radio 元素应用特定样式 */
.custom-radio-group .el-radio:nth-child(1),
.custom-radio-group .el-radio:nth-child(2) {
  width: calc(50% - 10px); /* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
}

.custom-radio-group .el-radio:nth-child(2) {
  margin-right: 0;
}

.checkbox-placeholder {
  width: calc(50% - 10px);/* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
  visibility: hidden; /* 确保占位符不可见 */
}
.checkbox-placeholder:nth-child(2n) {
  margin-right: 0;
}
.category-label{
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.aspect-ratio-box {
  width: 140px;
  height: 140px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
  margin: auto;
}

.material-container {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.material-thumbnail {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
  margin: auto;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.image-thumbnail-multiple{
  display: flex;
  flex-wrap: nowrap;
  width: 70px;
  overflow-x: auto;
  flex-grow: 1;
  padding-right: 5px;
  align-items: center;
  justify-content: flex-end;
}
.image-thumbnail{
  width: 20px;
  height: 20px;
}
</style>
