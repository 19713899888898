// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/loginBackground.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n.bk[data-v-d93ab710] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  /* 覆盖整个元素，可能裁剪部分图片 */\n  background-position: center;\n  /* 图片在元素中央显示 */\n  width: 60%;\n  height: 100vh;\n  /* 100% 屏幕高度 */\n}\n.forgot-password[data-v-d93ab710] {\n  height: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background-color: #F7F8FA;\n}\n.forgot-password-main[data-v-d93ab710] {\n  width: 40%;\n  background-color: #F7F8FA;\n}\n.title[data-v-d93ab710] {\n  font-size: 22px;\n  font-weight: 600;\n  color: #1D2129;\n  line-height: 48px;\n  margin: 0 auto 30px;\n  text-align: center;\n}\n.forgot-password-form[data-v-d93ab710] {\n  width: 100%;\n  padding: 0 10%;\n}\n.forgot-password-form .el-input[data-v-d93ab710] {\n  height: 38px;\n}\n.forgot-password-form .el-input input[data-v-d93ab710] {\n  height: 38px;\n}\n.forgot-password-form .input-icon[data-v-d93ab710] {\n  height: 39px;\n  width: 14px;\n  margin-left: 2px;\n}\n.forgot-password-code[data-v-d93ab710] {\n  width: 33%;\n  height: 38px;\n  float: right;\n}\n.forgot-password-code img[data-v-d93ab710] {\n  cursor: pointer;\n  vertical-align: middle;\n}\n.el-forgot-password-footer[data-v-d93ab710] {\n  height: 40px;\n  line-height: 40px;\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  text-align: center;\n  color: black;\n  font-family: Arial;\n  font-size: 12px;\n  letter-spacing: 1px;\n}", ""]);
// Exports
module.exports = exports;
