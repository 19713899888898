<template>
  <div class="app-container shopContainer" v-loading="loading" style="padding: 24px 50px;">
    <div v-if="detail.sellNickName">
      <!-- <span class=""> {{ $t('mall.sellerInformation') }}</span> -->
      <!-- <span class="ml10"> {{ detail.sellNickName }}</span> -->
    </div>
    <div class="flexShopPic">
      <el-carousel v-if="coverImages.length > 1" indicator-position="outside" class="carousel-video">
        <el-carousel-item style="width: 448px; height: 448px;" v-for="(url, index) in coverImages" :key="index">
          <img :src="url" alt="image" class="image-cover" />
        </el-carousel-item>
      </el-carousel>
      <!-- <svg-icon icon-class="mall-tiktok" style="width: 130px;height: 130px;"/> -->
      <img v-else :src="coverImages && coverImages[0]" alt="image" class="video-cover" />
      <div class="shopDetail" v-loading="topLoading">
        <div class="video-name">{{ detail.title }}</div>
        <!-- <div class="shopTags">
          <el-tag type="success" v-for="item in filterShopTag()">{{ detail.description }}</el-tag>
        </div> -->
        <div class="shopContent">
          {{ detail.description }}
        </div>
        <div class="flex-row-center">
          <i
            class="iconfont mr5 font20"
            :class="['icon-' + detail.platform  + '-normal', detail.platform]"
          ></i>
          <dict-tag :options="dict.type.social_platform" :value="detail.platform" />
        </div>
        <ShopDetailTag
          :list="dict.type.goods_year"
          @selectTag="selectTag"
        />
        <ShopDetailTag
          :list="dict.type.goods_fans"
          @selectTag="selectTag"
        />
        <ShopDetailTag
          :list="dict.type.goods_country"
          @selectTag="selectTag"
        />
        <div class="shopCount">
          库存: {{ count }}
        </div>
        <div class="priceBox" v-if="account.money">
          <span class="nowPriceTitle">{{ $t('mall.discountPrice') }}：</span>
          <span class="nowPrice"><span class="priceLeftText">¥</span>{{account.money}}</span>
          <!-- <div class="strikethrough" v-if="detail.originalPrice">{{ $t('mall.originalPrice') }}：¥{{detail.originalPrice}}</div> -->
        </div>
        <div class="flex-row-center mt5">
          <!-- <el-button :loading="loadingCart" @click="addCart" :disabled="detail.inCart">{{ detail.inCart ? $t('mall.addedToCart') : $t('mall.addToCart') }}</el-button> -->
          <el-button class="shopBtn" :loading="loadingBuy" type="primary" @click="addEmail" :disabled="!count">{{ $t('mall.buyNow') }}</el-button>
          <el-button class="shopCarBtn" :loading="loadingCart" @click="addCart" :disabled="!count"><i class="iconfont icon-gongzuotaibeifen"></i>{{ detail.inCart ? $t('mall.addedToCart') : $t('mall.addToCart') }}</el-button>
        </div>
      </div>
      <!-- <div class="ml20 flex-column" style="justify-content: space-between;min-height: 300px;">
        <div class="flex-row-center">
          <i
            class="iconfont mr5 font20"
            :class="['icon-' + detail.platform  + '-normal', detail.platform]"
          ></i>
          <dict-tag :options="dict.type.social_platform" :value="detail.platform" />
        </div>
        <div class="video-name">{{ detail.title }}</div>
        <div class="video-description mt5" v-if="detail.description">{{ detail.description }}</div>
        <div class="mt5">{{ $t('mall.discountPrice') }}：
          <span style="font-size: 20px;color: red;">¥{{detail.price}}</span>
        </div>
        <div class="strikethrough mt5" v-if="detail.originalPrice">{{ $t('mall.originalPrice') }}：¥{{detail.originalPrice}}</div>
        <div class="flex-row-center mt5">
          <el-button :loading="loadingCart" @click="addCart" :disabled="detail.inCart">{{ detail.inCart ? $t('mall.addedToCart') : $t('mall.addToCart') }}</el-button>
          <el-button :loading="loadingBuy" type="primary" @click="addEmail">{{ $t('mall.buyNow') }}</el-button>
        </div>
      </div> -->
    </div>
    <div class="video-name mt20">{{ $t('mall.productDetails') }}</div>
    <div class="flex-row-center">
      {{ $t('mall.accountType') }}：
      <i
        class="iconfont mr5 font20"
        :class="['icon-' + detail.platform  + '-normal', detail.platform]"
      ></i>
      <dict-tag :options="dict.type.social_platform" :value="detail.platform" />
    </div>
    <!-- <div class="mt5">{{ $t('mall.account') }}：{{detail.username || detail.email}}</div> -->
    <div class="mt5" v-if="detail.followerCount">{{ $t('mall.followers') }}：{{detail.followerCount}}</div>
    <div class="mt5" v-if="detail.followingCount">{{ $t('mall.following') }}：{{detail.followingCount}}</div>
    <div class="mt5" v-if="detail.linksCount">{{ $t('mall.likes') }}：{{detail.linksCount}}</div>
    <div class="mt5" v-if="detail.videoCount">{{ $t('mall.videos') }}：{{detail.videoCount}}</div>
    <div class="mt5" v-if="detail.bioDescription">{{ $t('mall.details') }}：{{detail.bioDescription}}</div>
    <div class="mt5" v-if="detail.businessType">{{ $t('mall.businessDirection') }}：{{detail.businessType}}</div>
    <div class="mt5" v-if="detail.country">{{ $t('mall.accountCountry') }}：{{detail.country}}</div>
    <div v-if="detail.detail" class="mt20" v-html="detail.detail"></div>
    <EmailDialog
      :visible.sync="emailVisible"
      @confirm="createOrder"
    />
    <PayDialog
      ref="payDialog"
      :visible.sync="payVisible"
      :orderInfo="orderInfo"
      @paySuccess="paySuccess"
    />
  </div>
</template>

<script>
import { addMallCart } from '../../api/mall/mallCartItems';
import { createMallOrder } from '../../api/mall/mallOrder';
import { getMallTiktok, getQueryAccountInventory } from '../../api/mall/mallTiktok';
import EmailDialog from './EmailDialog.vue';
import PayDialog from "./PayDialog.vue";
import ShopDetailTag from '@/components/ShopDetailTag/index.vue'
export default {
  name: 'TiktokDetail',
  dicts: [
    'social_platform',
    'goods_year',
    'goods_fans',
    'goods_country'
  ],
  components: {
    EmailDialog,
    PayDialog,
    ShopDetailTag
  },
  data() {
    return {
      // selected: false,  // 添加选中状态
      loading: false,
      id: '',
      detail: {},
      coverImages: [],//可能存在的轮播图——封面
      loadingCart: false,
      loadingBuy: false,
      payVisible: false,//支付弹窗
      orderInfo: null,//支付订单数据
      emailVisible: false,//邮箱弹窗
      params: {
        country: '',
        year: '',
        fans: ''
      },
      count: 0,
      account:{},
      topLoading: false
    };
  },
  created(){
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    selectTag(item) {
      if (item.raw.dictType === 'goods_country') {
        this.params.country = item.raw.dictValue
      } else if(item.raw.dictType === 'goods_fans') {
        this.params.fans = item.raw.dictValue
      } else if(item.raw.dictType === 'goods_year') {
        this.params.year = item.raw.dictValue
      }
      this.getQueryAccount()
    },
    getQueryAccount() {
      this.topLoading = true
      getQueryAccountInventory({mallGoodsId: this.id, ...this.params}).then(res => {
        this.account = res.data
        this.count = res.data.count
        console.log(res);
      }).finally(() => {
        this.topLoading = false
      });
    },
    filterShopTag() {

    },
    getDetail() {
      this.loading = true
      getMallTiktok(this.id).then(res => {
        this.detail = res.data
        if(this.detail.coverUrl){
          this.coverImages = this.detail.coverUrl.split(',')
        }
      }).finally(() => {
        this.loading = false
      });
      ;
    },
    //加入购物车
    addCart(){
      this.loadingCart = true
      addMallCart({
        mallGoodsId:this.detail.id,
        mallAccountId: this.account.mallAccountId,
        count:1
      }).then(res=>{
        this.$modal.msgSuccess(this.$t('mall.addToCartSuccess'));
      }).finally(()=>{
        this.loadingCart = false
      })
    },
    addEmail(){
      this.emailVisible = true
    },
    createOrder(value){
      this.loadingBuy = true
      createMallOrder({
        receiveEmail:value.email,
        createOrderGoodsList:[{
          // id:this.detail.id,
          mallGoodsId:this.detail.id,
          mallAccountId: this.account.mallAccountId,
          count:1
        }]
      }).then(res=>{
        // this.$modal.msgSuccess(this.$t('mall.addToCartSuccess'));
        this.orderInfo = res.data
        this.payVisible = true
        this.$nextTick().then((rs) => {
          this.$refs.payDialog.init();
        });
      }).finally(()=>{
        this.loadingBuy = false
      })
    },
    paySuccess(){
      this.$tab.closeOpenPage({path: "/mall/orderCenter"});
    },
  }
};
</script>

<style lang="scss">
.shopContainer {
  .video-cover {
    /* max-width: 300px;
    max-height: 300px;
    width: auto;
    height: auto; */
    width: 450px;
    height: 450px;
    // width: 724px;
    // height: 723px;
  }
  .video-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .video-description{
    font-size: 14px;
    margin-bottom: 5px;
    color: #666;
  }



  .carousel-video{
    /* max-width: 50%;
    min-width: 200px; */
    width: 450px;
    height: 450px;
    min-width: 450px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .image-cover {
    width: 100%;
    height: 100%;
  }

  .flexShopPic {
    display: flex;
    width: 100%;
    .shopDetail {
      width: 700px;
      margin-left: 40px;
      .shopCount {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.4);
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
      .shopContent {
        margin-bottom: 20px;
      }
      .priceBox {
        display: flex;
        align-items: end;
        .nowPriceTitle {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0,0,0,0.4);
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .nowPrice {
          font-family: DINAlternate, DINAlternate;
          font-weight: bold;
          font-size: 30px;
          color: #F65656;
          line-height: 24px;
          text-align: left;
          font-style: normal;
          .priceLeftText {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #F65656;
            line-height: 24px;
            text-align: left;
            font-style: normal;
            margin-right: 5px;
          }
        }
        .strikethrough{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0,0,0,0.4);
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-decoration-line: line-through;
          margin-left: 16px;
        }
      }
    }

    .shopBtn {
      width: 250px;
      height: 40px;
      background: #016FEE;
      border-radius: 12px;
    }
    .shopCarBtn {
      width: 272px;
      height: 40px;
      background: #FFFFFF;
      border-radius: 12px;
      border: 1px solid rgba(0,0,0,0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-gongzuotaibeifen {
        margin-right: 5px;
      }
    }
  }


}
</style>
