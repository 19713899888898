var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-detail-container" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "material-content",
      },
      [
        _c("div", { staticClass: "mb20" }, [
          _vm.detail.fileType === "image"
            ? _c("img", {
                staticClass: "image-viewer",
                attrs: { src: _vm.detail.url, alt: "image" },
              })
            : _vm.detail.fileType == "audio"
            ? _c("audio", {
                ref: "audioPlayer",
                staticClass: "audio-player",
                attrs: { controls: "", src: _vm.detail.url },
              })
            : _vm.detail.fileType == "video"
            ? _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.detail.url },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "material-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.fileType == "text"
                  ? _vm.detail.title
                  : _vm.detail.name
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "material-content-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.fileType == "text"
                  ? _vm.detail.content
                  : _vm.detail.description
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "mt20" },
          _vm._l(_vm.detail.labels, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticStyle: { "margin-right": "5px", "margin-bottom": "5px" },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          1
        ),
        _c(
          "el-descriptions",
          {
            staticClass: "f-grow",
            attrs: {
              title: "",
              "label-class-name": "my-label",
              column: 2,
              border: "",
            },
          },
          [
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 上传时间 ")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.detail.createTime) + " ")]),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 素材格式 ")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.getText()) + " ")]),
              ],
              2
            ),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 素材编号 ")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.detail.id) + " ")]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-header" }, [
      _c("div", { staticClass: "title-header-text" }, [_vm._v("素材详情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }