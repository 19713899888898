<!-- 新增or编辑商品 -->
<template>
  <div class="app-container">
    <el-form v-loading="loading" ref="form" :model="form" :rules="rules" label-position="top" class="form-top" >
      <div class="goods-title">{{$t('mall.baseInfo')}}</div>
      <el-form-item :label="$t('mall.productCategory')" prop="goodsType">
        <el-radio-group v-model="form.goodsType">
          <el-radio label="material">{{$t('mall.material')}}</el-radio>
          <el-radio label="account">{{$t('mall.account')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="form.goodsType === 'account'">
        <el-form-item :label="$t('mall.accountType')" prop="goodsTypeV2">
          <el-radio-group v-model="form.goodsTypeV2">
            <el-radio v-for="dict in dict.type.social_platform" :key="dict.value" :label="dict.value">
              {{ dict.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item :label="$t('mall.productName')" prop="title">
            <el-input v-model="form.title" :placeholder="$t('tip.input')"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('mall.businessDirection')" prop="businessType">
            <el-cascader
              style="width: 100%;"
              :show-all-levels="false"
              :props="{value:'name',label:'name',emitPath: false}"
              v-model="form.businessType"
              :options="industryCategoryOptions"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('sort')" prop="goodsNo">
            <template #label>
              <div class="flex-row-center f-between w-100">
                <span>{{ $t('sort') }}</span>
                <span class="goods-tip ml10">{{ $t('mall.sortOrder') }}</span>
              </div>
            </template>
            <el-input v-model="form.goodsNo" type="number" :placeholder="$t('tip.input')"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="form.goodsType === 'material'">
        <el-col :span="8">
          <el-form-item :label="$t('mall.productPrice')" prop="price">
            <el-input v-model="form.price" type="number" :placeholder="$t('tip.input')"/>
            <span class="goods-tip">{{ $t('mall.salePrice') }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('mall.strikethroughPrice')" prop="originalPrice">
            <el-input v-model="form.originalPrice" type="number" :placeholder="$t('tip.input')"/>
            <span class="goods-tip">{{ $t('mall.strikethroughPriceTip') }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('mall.stock')" prop="quantity">
            <el-input v-model="form.quantity" type="number" :max="999" @input="quantityInput" :placeholder="$t('tip.input')" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="description">
        <template #label>
          <div class="flex-row-center f-between w-100">
            <span>{{ $t('mall.productDescription') }}</span>
            <span class="goods-tip ml10">{{ $t('mall.pointInfo') }}</span>
          </div>
        </template>
        <el-input type="textarea" :rows="3" v-model="form.description" :placeholder="$t('tip.input')" />
      </el-form-item>
      <el-form-item :label="$t('mall.productCover')" prop="coverUrl">
        <imageUpload v-model="form.coverUrl" :limit="10" />
      </el-form-item>
      <div class="goods-title">{{$t('mall.productInfo')}}</div>
      <div v-if="form.goodsType === 'account'">
        <el-form-item :label="$t('mall.account')" prop="accountList">
          <div class="flex-row-center mb5">
            <el-button type="text" icon="iconfont icon-wenanmoban" size="mini" @click="downTemplate">下载模板</el-button>
            <el-upload
              v-if="form.goodsType === 'account'"
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/"
              accept=".xlsx"
              :http-request="(params) => uploadFile(params)"
              style="width: 80px;margin-left: 10px"
            >
              <el-button type="text" icon="iconfont icon-a-dagoubeifen21" size="mini">导入Excel</el-button>
            </el-upload>
          </div>
          <div class="flex-row-center mb5" v-for="(item, index) in form.accountList" :key="index" >
            <el-descriptions :column="4" border>
              <el-descriptions-item label="账号">
                <el-input v-model="item.email" :disabled="isDisabled(item)"/>
              </el-descriptions-item>
              <el-descriptions-item label="密码">
                <el-input v-model="item.password" type="password" :disabled="isDisabled(item)"/>
              </el-descriptions-item>
              <el-descriptions-item label="年限">
                <el-select
                  v-model="item.year"
                  filterable
                  :disabled="isDisabled(item)"
                >
                  <el-option
                    v-for="dict in dict.type.goods_year"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="粉丝">
                <el-select
                  v-model="item.fans"
                  filterable
                  :disabled="isDisabled(item)"
                >
                  <el-option
                    v-for="dict in dict.type.goods_fans"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="地域">
                <el-select
                  v-model="item.country"
                  filterable
                  :disabled="isDisabled(item)"
                >
                  <el-option
                    v-for="dict in dict.type.goods_country"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="语言">
                <el-select
                  v-model="item.language"
                  filterable
                  :disabled="isDisabled(item)"
                >
                  <el-option
                    v-for="dict in dict.type.language"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="￥价格">
                <el-input v-model="item.money"  type="number" :disabled="isDisabled(item)"/>
              </el-descriptions-item>
              <el-descriptions-item label="是否卖出">
                <dict-tag :options="dict.type.goods_sell_status" :value="item.sellStatus || 0" />
              </el-descriptions-item>
            </el-descriptions>
            <i 
              v-if="!isDisabled(item)"
              class="iconfont icon-delete color-red f-shrink ml10"
              @click="deleteAccount(index, item)"></i>
          </div>
        </el-form-item>
      </div>
      <div v-if="form.goodsType === 'material'">
        <el-form-item :label="$t('mall.material')" prop="fileList">
          <videoUpload v-model="form.fileList" :limit="1"/>
        </el-form-item>
      </div>
      <el-form-item :label="$t('mall.productDetails')" prop="detail">
        <editor v-model="form.detail" :min-height="192"/>
        <span class="goods-tip">{{ $t('mall.productDescriptionTip') }}</span>
      </el-form-item>

      <el-form-item :label="$t('mall.onSale')" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">{{$t('mall.saleNow')}}</el-radio>
          <el-radio :label="0">{{$t('mall.saleLater')}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="flex-center">
      <el-button @click="cancel">{{ $t('back') }}</el-button>
      <el-button :loading="buttonLoading" type="primary" @click="submitForm">{{ $t('confirm') }}</el-button>
    </div>
  </div>
</template>
<script>
import { listLanguage } from '../../api/ai/language';
import { industryCategoryTreeSelect } from '../../api/base/resource';
import { addMyGoods, getMyGoods, updateMyGoods } from '../../api/mall/mallGoods';
import VideoUpload from "../../components/VideoUpload/index.vue";
import XLSX from "xlsx";
export default {
  name: 'AddGoods',
  components: {
    VideoUpload
  },
  dicts: [
    'social_platform',
    'goods_year',
    'goods_fans',
    'goods_country',
    'language',
    'goods_sell_status'
  ],
  data() {
    return {
      // 表单参数
      form: {
        id: undefined,
        goodsNo: 0,
        title: undefined,
        description: undefined,
        detail: undefined,
        coverUrl: undefined,
        price: undefined,
        originalPrice: undefined,
        quantity: undefined,
        goodsType: 'material',
        goodsTypeV2: '',//(新字段，二级分类，只有goodsType=account时传，传具体平台)，
        status: 1,
        detailsUrls: undefined,
        fileList:[],
        businessType: undefined,
        mallMaterial:{
          originalUrl: undefined,
          fileType: undefined,
        },
        accountList: [],
        accountIds:[],
      },
      // 表单校验
      rules: {
        // goodsNo: [
        //   { required: true, message: this.$t('mall.codeRequired'), trigger: ['blur', 'change'] }
        // ],
        title: [
          { required: true, message: this.$t('mall.nameRequired'), trigger: ['blur', 'change'] }
        ],
        // businessType: [
        //   { required: true, message: this.$t('mall.businessDirectionRequired'), trigger: ['blur', 'change'] }
        // ],
        // description: [
        //   { required: true, message: this.$t('mall.descriptionRequired'), trigger: ['blur', 'change'] }
        // ],
        detail: [
          { required: true, message: this.$t('mall.detailRequired'), trigger: ['blur', 'change'] }
        ],
        coverUrl: [
          { required: true, message: this.$t('mall.coverRequired'), trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: this.$t('mall.priceRequired'), trigger: ['blur', 'change'] }
        ],
        originalPrice: [
          { required: true, message: this.$t('mall.strikethroughPriceRequired'), trigger: ['blur', 'change'] }
        ],
        quantity: [
          { required: true, message: this.$t('mall.stockRequired'), trigger: ['blur', 'change'] }
        ],
        goodsType: [
          { required: true, message: this.$t('mall.selectCategory'), trigger: ['blur', 'change'] }
        ],
        goodsTypeV2: [
          { required: true, message: this.$t('mall.accountTypeRequired'), trigger: ['blur', 'change'] }
        ],
        accountList: [
          { required: true, message: '请导入账号数据', trigger: ['blur', 'change'] }
        ],
        fileList: [
          { required: true, message: this.$t('mall.selectMaterial'), trigger: ['blur', 'change'] }
        ],
        status: [
          { required: true, message: this.$t('mall.selectSaleStatus'), trigger: ['blur', 'change'] }
        ],
      },
      id:null,
      loading:false,
      buttonLoading:false,
      languageOptions:[],//国家
      industryCategoryOptions: undefined, // 业务方向、行业分类
    }
  },
  created() {
    this.getLanguage()
    this.getIndustryCategoryOptionsTree()
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.getDetail()
    }
  },
  mounted() {},
  methods: {
    isDisabled(item){
      if(item.sellStatus && (item.sellStatus == 2 || item.sellStatus == 3)){
        return true
      }
      return false
    },
    // 下载模板
    downTemplate(){
      // 创建一个链接元素
      const link = document.createElement('a');
      link.href = '/账号模板.xlsx'; // 使用 require 导入文件
      link.download = '账号模板.xlsx'; // 设置下载的文件名
      // 触发点击事件
      link.click();
    },
    deleteAccount(index, item){
      if (item && item.id) {
        this.form.accountIds.push(item.id); // 将 id 推入到 removedIds 数组
      }
      this.form.accountList.splice(index, 1);
    },
    uploadFile(params) {
      const _file = params.file;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        if (!ev.target) {
          this.$modal.msgError('文件读取失败！');
          return;
        }
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: 'array',
            cellDates: true
          });
          let totalRows = 0;
          let updatedCount = 0; // 记录覆盖的数量
          for (let sheet in workbook.Sheets) {
            const sheetArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            if (sheetArray.length === 0) {
              continue;
            }
            for (let item of sheetArray) {
              totalRows++;
              let rowTable = {
                email: item['账号'],
                password: item['密码'],
                year: item['年限'],
                fans: item['粉丝'],
                country: item['地域'],
                language: item['语言'],
                money: item['￥价格']
              };
              // 根据 email 字段检查是否已有相同的记录
              let existingIndex = this.form.accountList.findIndex(data => data.email === rowTable.email);

              if (existingIndex !== -1) {
                // 如果存在相同的 email，覆盖时仅替换非空字段
                let existingData = this.form.accountList[existingIndex];

                // 遍历 rowTable 中的每个字段，非空则更新
                for (let key in rowTable) {
                  if (rowTable[key] !== undefined && rowTable[key] !== null && rowTable[key] !== '') {
                    existingData[key] = rowTable[key]; // 仅更新非空字段
                  }
                }
                updatedCount++; // 记录覆盖的数量
              } else {
                // 如果不存在，直接插入
                this.form.accountList.push(rowTable);
              }
              console.log('form.accountList', this.form.accountList);
            }
          }
          // 计算导入成功的数量
          let successCount = totalRows;
          // 根据不同情况显示提示信息
          // 根据不同情况显示提示信息
          if (updatedCount > 0) {
            this.$message.success(`成功导入${successCount}条数据，其中覆盖了${updatedCount}条重复数据。`);
          } else {
            this.$message.success(`成功导入${successCount}条数据。`);
          }
        } catch (e) {
          console.log(e);
          this.$message.warning('文件类型不正确，请上传xlsx格式！');
        }
      };
      // 使用 readAsBinaryString 读取文件——已弃用了
      fileReader.readAsArrayBuffer(_file);
    },
    quantityInput(value){
      // 检查输入值是否超过999
      if (value > 999) {
        this.form.quantity = 999;
      } else if (value < 0) {
        // 如果需要限制最小值为0，可以加这个判断
        this.form.quantity = 0;
      } else {
        this.form.quantity = value;
      }
    },
    /** 所属行业下拉树结构 */
    getIndustryCategoryOptionsTree() {
      industryCategoryTreeSelect().then((response) => {
        this.industryCategoryOptions = response.data;
      });
    },
    getDetail(){
      this.loading = true
      getMyGoods(this.id).then(response => {
        this.form = {
          ...this.form,
          ...response.data,
        }
        if(this.form.originalUrl){
          this.form.mallMaterial.originalUrl = this.form.originalUrl
          this.form.fileList = [{
            url: this.form.mallMaterial.originalUrl,
            name: this.form.mallMaterial.originalUrl.split('/').pop(),
          }]
        }
        if(this.form.mallAccountList){
          this.form.accountList = this.form.mallAccountList
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    getLanguage() {
      listLanguage().then(response => {
        this.languageOptions = response.data;
      });
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        goodsNo: 0,
        title: undefined,
        description: undefined,
        detail: undefined,
        coverUrl: undefined,
        price: undefined,
        originalPrice: undefined,
        quantity: undefined,
        goodsType: 'material',
        status: 1,
        detailsUrls: undefined,
        fileList:[],
        businessType: undefined,
        mallMaterial:{
          originalUrl: undefined,
          fileType: undefined,
        },
      };
      this.resetForm("form");
    },
    cancel(){
      this.$tab.closeOpenPage({path: "/mall/goodsManager"});
    },
     /** 提交按钮 */
     submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.buttonLoading = true;
          if(this.form.fileList && this.form.fileList.length > 0){
            this.form.mallMaterial.originalUrl = this.form.fileList[0].url
            this.form.mallMaterial.fileType = this.getTypeByUrl(this.form.mallMaterial.originalUrl)
          }
          if (this.form.id != null) {
            updateMyGoods(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.update'));
              this.$tab.closeOpenPage({path: "/mall/goodsManager"});
            }).finally(() => {
              this.buttonLoading = false;
            });
          } else {
            addMyGoods(this.form).then(response => {
              this.$modal.msgSuccess(this.$t('tip.add'));
              this.$tab.closeOpenPage({path: "/mall/goodsManager"});
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        } else {
          this.$modal.msgError(this.$t('issueVideo.tipInfoMust'));
        }
      });
    },
  },
}
</script>
 <style lang="scss" scoped>
.goods-title{
  background: rgba(236, 242, 254, 0.3);
  border-radius: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0052D9;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
  padding: 5px 16px;
}
.goods-tip{
  font-size: 11px;
  color: #A1A1A1;
  font-weight: 400;
}
.form-top ::v-deep .el-form-item__label {
  padding: 0;
}
.form-top .el-form-item {
  margin-bottom: 10px;
}
.form-top .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
</style>
